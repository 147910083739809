import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

const deleteAasana = async (id) => {
  const config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl.url}/api/asana/${id}`,
    headers: {
      token: getToken(),
    },
  };

  try {
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default deleteAasana;
