import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "rsuite";
import { ClipLoader } from "react-spinners";
import moment from "moment";

// Component
import Header from "../../common/Header/Header";

// Json
import baseUrl from "../../../constants/constant.json";
import axios from "axios";
import { getToken } from "../../../utils/getToken";

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const linkStyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const MarginReport = () => {
  const [marginReportData, setMarginReportData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);

  const allMarginReport = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/report?sdate=${startDate}&edate=${endDate}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setMarginReportData(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
      });
  };

  useEffect(() => {
    allMarginReport();
  }, [startDate, endDate]);

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      const [start, end] = dateRange.map((date) =>
        moment(date).format("MM/DD/YYYY")
      );
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <Box>
      <Header />
      <Box sx={dashboard}>
        <Box padding={3}>
          <Box marginBottom={2}>
            <Breadcrumbs>
              <Link style={linkStyle} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linkStyle} to="/medicines">
                Medicines
              </Link>
              <Link style={linkStyle} to="/marginreport">
                Margin Report
              </Link>
            </Breadcrumbs>
            <Typography sx={typographyContent}>Margin Report</Typography>
          </Box>
          <Box>
            <Box mt={2}>
              <Grid container justifyContent="space-between">
                <Grid sx={{ width: "30%", mr: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{ paddingTop: "0px" }}
                      components={["DateRangePicker"]}
                    >
                      <DateRangePicker
                        size="lg"
                        placeholder="Select Date Range"
                        onChange={handleDateChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="space-between"
                  sx={{ width: "30%" }}
                ></Grid>
              </Grid>
            </Box>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />
          <Box mt="30px" mb={5}>
            <TableContainer sx={{ maxHeight: 380 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Item Name</TableCell>
                    <TableCell align="center">Operation Stock</TableCell>
                    <TableCell align="center">Purchase</TableCell>
                    <TableCell align="center">Sale</TableCell>
                    <TableCell align="center">Margin</TableCell>
                    <TableCell align="center">P. Rate</TableCell>
                    <TableCell align="center">S. Rate</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    height="330px"
                    top="35%"
                    left="50%"
                  >
                    <ClipLoader color="#2F7539" />
                  </Box>
                ) : marginReportData.length === 0 ? (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Typography variant="body1" align="center">
                            No data available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    {marginReportData?.map((item) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{item.itemName}</TableCell>
                          <TableCell align="center">{item.opStock}</TableCell>
                          <TableCell align="center">{item.purchase}</TableCell>
                          <TableCell align="center">{item.sale}</TableCell>
                          <TableCell align="center">{item.margin}</TableCell>
                          <TableCell align="center">
                            {item.prchaseRate}
                          </TableCell>
                          <TableCell align="center">{item.saleRate}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MarginReport;
