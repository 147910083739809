import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";


export const getAsanaData = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/asana/list`,
      headers: {
        token: getToken(),
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};