import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const vata = ["Vata", "Prana", "Udana", "Vyana", "Samana", "Apana"];

const pita = [
  "Pita",
  "Pachaka",
  "Ranchaka",
  "Bhrachaka",
  "Alochaka",
  "Sadhaka",
];

const kapha = [
  "Kapha",
  "Kledaka",
  "Avalambaka",
  "Sleshaka",
  "Bodhaka",
  "Tarpaka",
];

const aam = ["Aam"];

const niraam = ["Niram"];

const Vikruti = ({
  handleVikritiChange,
  typoStyle,
  diagnosisId,
  prakriti,
  addDiagnosis,
  selectedDiagnosisData,
}) => {
  return (
    <>
      <Box sx={{ marginBottom: "10px" }}>
        <Grid container display={"flex"} alignItems={"center"}>
          <Grid md={2} display={"flex"} justifyContent={"center"}>
            <Typography sx={typoStyle}>Vikruti :</Typography>
          </Grid>
          <Grid md={10} display={"flex"} alignItems={"center"}>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell>
                    {vata.map((title) => (
                      <>
                        <Box key={title} display="flex" alignItems="center">
                          <Checkbox
                            name={title}
                            onChange={() => handleVikritiChange("vata", title)}
                            checked={
                              selectedDiagnosisData.vikriti
                                ? selectedDiagnosisData.vikriti.vata.includes(
                                    title
                                  )
                                : addDiagnosis.vikriti.vata.includes(title)
                            }
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        {title === "Vata" ? (
                          <Divider sx={{ margin: "revert" }} />
                        ) : (
                          <> </>
                        )}
                      </>
                    ))}
                  </TableCell>

                  <TableCell>
                    {pita.map((title) => (
                      <>
                        <Box key={title} display="flex" alignItems="center">
                          <Checkbox
                            name={title}
                            onChange={() => handleVikritiChange("pita", title)}
                            checked={
                              selectedDiagnosisData.vikriti
                                ? selectedDiagnosisData.vikriti?.pita.includes(
                                    title
                                  )
                                : addDiagnosis.vikriti.pita.includes(title)
                            }
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        {title === "Pita" ? (
                          <Divider sx={{ margin: "revert" }} />
                        ) : (
                          <> </>
                        )}
                      </>
                    ))}
                  </TableCell>

                  <TableCell>
                    {kapha.map((title) => (
                      <>
                        <Box key={title} display="flex" alignItems="center">
                          <Checkbox
                            name={title}
                            onChange={() => handleVikritiChange("kapha", title)}
                            checked={
                              selectedDiagnosisData.vikriti
                                ? selectedDiagnosisData.vikriti?.kapha.includes(
                                    title
                                  )
                                : addDiagnosis.vikriti.kapha.includes(title)
                            }
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        {title === "Kapha" ? (
                          <Divider sx={{ margin: "revert" }} />
                        ) : (
                          <> </>
                        )}
                      </>
                    ))}
                  </TableCell>

                  <TableCell sx={{ verticalAlign: "top" }}>
                    {aam.map((title) => (
                      <>
                        <Box key={title} display="flex" alignItems="center">
                          <Checkbox
                            name={title}
                            onChange={() => handleVikritiChange("aam", title)}
                            checked={
                              selectedDiagnosisData.vikriti
                                ? selectedDiagnosisData.vikriti?.aam.includes(
                                    title
                                  )
                                : addDiagnosis.vikriti.aam.includes(title)
                            }
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        {title === "Aam" ? (
                          <Divider sx={{ margin: "revert" }} />
                        ) : (
                          <> </>
                        )}
                      </>
                    ))}
                  </TableCell>

                  <TableCell sx={{ verticalAlign: "top" }}>
                    {niraam.map((title) => (
                      <>
                        <Box key={title} display="flex" alignItems="center">
                          <Checkbox
                            name={title}
                            onChange={() =>
                              handleVikritiChange("niraam", title)
                            }
                            checked={
                              selectedDiagnosisData.vikriti
                                ? selectedDiagnosisData.vikriti?.niraam.includes(
                                    title
                                  )
                                : addDiagnosis.vikriti.niraam.includes(title)
                            }
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        {title === "Niram" ? <Divider sx={{ margin: "revert" }} /> : <> </>}
                      </>
                    ))}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Vikruti;
