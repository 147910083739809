import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { handleCheckBox, isItemChecked } from "../../services/asana/CheckBoxService";
import { Divider, FormControlLabel } from "@material-ui/core";

const Aasana = ({
  setCheckedData,
  checkedData,
  panchakarma,
  panchakarmaValue,
  handleDeleteAsans,
  asanaData,
  pranayama,
  setPanchakarmaValue,
  clearTextFiled,
  handlePanchakarmaValue,
  setAsanaValue,
  asanaValue,
  handleAsanaValue,
  setPranayamaValue,
  pranayamaValue,
  handlePranayamaValue,
  mudra,
  setMudraValue,
  mudraValue,
  upkarma,
  upkarmaValue,
  handleMudraValue,
  meditation,
  setMeditationValue,
  meditationValue,
  handleMeditationValue,
  setUpkarmaValue,
  handleUpkarmaaValue,
  selectedDiagnosisData,
  setSelectedDiagnosisData,
  diagnosisId,
  setPanchakarma,
}) => {
  const editButton = {
    backgroundColor: "green",
    color: "white",
    padding: "2px",
    borderRadius: "5px",
  };

  const deleteButton = {
    backgroundColor: "#D11A2A",
    color: "white",
    padding: "2px",
    borderRadius: "5px",
  };

  const [databasePanchkarma, setDatabasePanchkarma] = useState([]);
  const [updateCheckedData, setUpdateCheckedData] = useState({
    panchakarma: [],
    asana: [],
    pranayama: [],
    mudra: [],
    upkarma: [],
  });

  useEffect(() => {
    setDatabasePanchkarma(selectedDiagnosisData.panchakarma);
  }, []);

  const handleSelectedCheckBox = (item, type) => {
    const isNamePresent = selectedDiagnosisData[type]
      ? selectedDiagnosisData[type].map((obj) => obj.name).includes(item.name)
      : false;
    return isNamePresent;
  };

  const handleSelectedCheckBoxChange = (item, type) => {
    // Check if the item is already present in the selectedDiagnosisData
    const isNamePresent = selectedDiagnosisData[type]
      ? selectedDiagnosisData[type].map((obj) => obj.name).includes(item.name)
      : false;

    // If the item is present, remove it from the array
    if (isNamePresent) {
      const updatedData = selectedDiagnosisData[type].filter(
        (obj) => obj.name !== item.name
      );

      // Update the selectedDiagnosisData state
      setSelectedDiagnosisData({
        ...selectedDiagnosisData,
        [type]: updatedData,
      });
    } else {
      // If the item is not present, add it to the array
      setSelectedDiagnosisData({
        ...selectedDiagnosisData,
        [type]: [...selectedDiagnosisData[type], item],
      });
    }
  };

  return (
    <>

      <Box mx={2} sx={{ marginBottom: "10px" }}>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Panchakarma */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Panchakarma</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {panchakarma.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "panchakarma") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "panchakarma") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Box>
              <TextField
                size="small"
                value={panchakarmaValue}
                onChange={(ele) => setPanchakarmaValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handlePanchakarmaValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Asana */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Asana</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {asanaData.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "asana") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "asana") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Box >
              <TextField
                size="small"
                value={asanaValue}
                onChange={(ele) => setAsanaValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handleAsanaValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Divider></Divider>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Pranayama */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Pranayama</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {pranayama.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "pranayama") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "pranayama") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Box >
              <TextField
                size="small"
                value={pranayamaValue}
                onChange={(ele) => setPranayamaValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handlePranayamaValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "2px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Divider></Divider>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Mudra */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Mudra</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {mudra.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "mudra") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "mudra") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              </Box>
            ))}
            <Box >
              <TextField
                size="small"
                value={mudraValue}
                onChange={(ele) => setMudraValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handleMudraValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "2px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Divider></Divider>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Meditation */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Meditation</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap" }}>
            {meditation.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "meditation") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "meditation") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                
                )}
              </Box>
            ))}
            <Box mx={2}>
              <TextField
                size="small"
                value={meditationValue}
                onChange={(ele) => setMeditationValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handleMeditationValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "2px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>


        <Divider></Divider>
        <Grid container spacing={2} alignItems="flex-start" padding={1}>
          {/* Suggestion for Upkarma */}
          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>Suggestion for Upkarma</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {upkarma.map((item) => (
              <Box key={item._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={diagnosisId ? handleSelectedCheckBox(item, "upkarma") : isItemChecked(item, checkedData[item.type])}
                      onChange={() => diagnosisId ? handleSelectedCheckBoxChange(item, "upkarma") : handleCheckBox(item, checkedData, setCheckedData)}
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" onClick={() => handleDeleteAsans(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Box >
              <TextField
                size="small"
                value={upkarmaValue}
                onChange={(ele) => setUpkarmaValue(ele.target.value)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} onClick={handleUpkarmaaValue} />
              </IconButton>
              <IconButton sx={{ marginLeft: "2px" }} size="small">
                <CloseIcon sx={deleteButton} onClick={clearTextFiled} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

      </Box>
    </>
  );
};

export default Aasana;
