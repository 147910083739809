import AvoidMore from "./AvoidMore";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../../App.css";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MuiAlert from "@mui/material/Alert";

// json
import {getPrakrutiData} from "../../utils/utils";
import baseUrl from "../../constants/constant.json";
import administrationTime from "../Json/AdministrationTime.json";
import Anupan from "../Json/Anupan.json";
// import medicine from "../Json/medicines.json";
import symptomsName from "../Json/Symptoms.json";

// Component
import Header from "../common/Header/Header";
import createAasana from "../../services/asana/CreateAasanaService";
import deleteAasana from "../../services/asana/DeleteAasanaService";
import { medicineNameList } from "../../services/medicine/medicineListService";
import Vikruti from "./Vikruti";
import Aasana from "./Aasana";
import {
  saveDiagnosis,
  updateDiagnosis,
} from "../../services/diagnosis/diagnosisServices";
import { fetchDiagnosisDetails } from "../../services/diagnosis/diagnosisServices";
import { getAsanaData } from "../../services/asana/asanaService";
import { getToken } from "../../utils/getToken";
moment.tz.setDefault("Asia/Kolkata");

// css
const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  width: "81%",
  borderRadius: "3px",
  height: "auto",
  padding: "20px",
};

const linkStyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const mainHeading = {
  color: "#424242",
  fontSize: "30px",
  fontWeight: "700",
  marginBottom: "10px",
};

const typoStyle = {
  fontWeight: "500",
  fontSize: "16px",
};

const listStyle = {
  fontWeight: "400",
  fontSize: "18px",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
  padding: "5px",
};

const clearButton = {
  backgroundColor: "#202300",
  color: "#ebebeb",
  borderRadius: "5px",
  fontSize: "24px",
};

const dialogHeading = {
  padding: "5px",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: "600",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  margin: "10px",
  width: "150px",
  marginLeft: "0px",
  backgroundColor: "#2F7539",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  margin: "10px",
  width: "150px",
  border: "1px solid #2F7539",
  color: "#2F7539",
};

const tooltipStyle = {
  ".MuiTooltip-touch": {
    backgroundColor: "lightgray",
    color: "black",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

function Diagnosis() {
  const [medicine, setMedicine] = useState([]);
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [selectedDiagnosisData, setSelectedDiagnosisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [patientId, setPatientId] = useState(queryParams.get("patientId"));
  const diagnosisId = queryParams.get("diagnosisId");
  const navigate = useNavigate();
  const currentDate = new Date();

  const [finalMoreDietData, setFinalMoreDietData] = useState([]);
  const [finalLessDietData, setFinalLessDietData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await medicineNameList();
        setMedicine(data.data);
        console.log("medicineList", data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (patientId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/patient/${patientId}`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setPatientData(response.data.data);
        })
        .catch((error) => {
          if (error.response.data === "Invalid Token") {
            localStorage.clear();
            window.location.href = "/";
          } else {
            console.error("Error:", error);
          }
        });
    }
  }, [patientId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (diagnosisId) {
          const data = await fetchDiagnosisDetails(diagnosisId);
          setSelectedDiagnosisData(data);
          setPatientId(data.patientId);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [diagnosisId]);

  useEffect(() => {
    const { prakriti, vikriti } = selectedDiagnosisData;
    setPrakriti({
      prakruti: prakriti || [],
      vikriti: vikriti || [],
    });
  }, [selectedDiagnosisData]);

  const [newPlusItem, setNewPlusItem] = useState([
    { property: "", eProperty: "", values: [{ name: "", eName: "", default: false}]}]
  );
  const [newMinusItem, setNewMinusItem] = useState({ property: "", eProperty: "", values: [{ name: "", eName: "", default: false}]});

  const handleAddMedicine = () => {
    setOpen(true);
    setMedicines({
      medicineName: "",
      anupan: "",
      administrationTime: "",
      dosage: "",
    });
  };

  const [plusData, setPlusData] = useState([]);
  const [minusData, setMinusData] = useState([]);

  const [addDiagnosis, setAddDiagnosis] = useState({
    patientId: patientId,
    mainComplaint: "",
    pastMedicalHistory: "",
    prakriti: [],
    vikriti: {
      vata: [],
      pita: [],
      kapha: [],
      aam: [],
      niraam: [],
    },
    diagnosis: "",
    medicine: [],
    panchakarma: [],
    asana: [],
    pranayama: [],
    mudra: [],
    meditation: [],
    upkarma: [],
    avoidEat: [],
    lessEat: [null],
    moreEat: [],
    mealTime: [],
    symptoms: [],
    followUpDate: "",
    doctorCharge: 300,
    medicineCharge: "",
  });

  useEffect(() => {
    setAddDiagnosis((prevAddDiagnosis) => ({
      ...prevAddDiagnosis,
      avoidEat: plusData,
      moreEat: minusData,
    }));
  }, [plusData, minusData]);

  const [medicines, setMedicines] = useState({
    anupan: "",
    dosage: "",
    administrationTime: "",
    medicineName: "",
  });

  const [medicineList, setMedicineList] = useState([]);
  const [prakriti, setPrakriti] = useState({
    prakruti: [],
    vikriti: [],
  });

  // patient diagnosis add fields
  const patientDiagnosis = (ele) => {
    setAddDiagnosis({ ...addDiagnosis, [ele.target.name]: ele.target.value });
  };

  // edit patient diagnosis edit fields
  const updatePatientDiagnosis = (ele) => {
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      [ele.target.name]: ele.target.value,
    });
  };

  const handlePrakritiChange = (event) => {
    const { name, checked } = event.target;

    if (diagnosisId) {
      setSelectedDiagnosisData((prevData) => {
        const updatedPrakriti = checked
          ? [...prevData.prakriti, name]
          : prevData.prakriti.filter((prakruti) => prakruti !== name);

        handleFetchPrakritiData(updatedPrakriti);

        return {
          ...prevData,
          prakriti: updatedPrakriti,
        };
      });
    } else {
      setAddDiagnosis((prevAddDiagnosis) => {
        const updatedPrakriti = checked
          ? [...prevAddDiagnosis.prakriti, name]
          : prevAddDiagnosis.prakriti.filter((prakruti) => prakruti !== name);

        handleFetchPrakritiData(updatedPrakriti);

        return {
          ...prevAddDiagnosis,
          prakriti: updatedPrakriti,
        };
      });
    }
  };

  const handleFetchPrakritiData = (data) => {
    const filteredData = getPrakrutiData(data);
    const dataByType = {
      pittaPlus: [],
      pittaMinus: [],
      vattaPlus: [],
      vattaMinus: [],
      kaphaPlus: [],
      kaphaMinus: [],
    };

    filteredData.forEach((item) => {
      dataByType[item.type].push(item);
    });

    const {
      pittaPlus,
      pittaMinus,
      vattaPlus,
      vattaMinus,
      kaphaPlus,
      kaphaMinus,
    } = dataByType;

    const newPlusData = [...vattaPlus, ...pittaPlus, ...kaphaPlus];
    const newMinusData = [...vattaMinus, ...pittaMinus, ...kaphaMinus];
    // setPlusData(newPlusData);
    // setMinusData(newMinusData);
  };

  const handleVikritiChange = (name, title) => {
    if (selectedDiagnosisData.vikriti) {
      setSelectedDiagnosisData((prevData) => {
        const updatedVikriti = { ...prevData.vikriti };

        if (!updatedVikriti[name].includes(title)) {
          updatedVikriti[name] = [...updatedVikriti[name], title];
        } else {
          updatedVikriti[name] = updatedVikriti[name].filter(
            (vikriti) => vikriti !== title
          );
        }

        return {
          ...prevData,
          vikriti: {
            ...prevData.vikriti,
            [name]: updatedVikriti[name],
          },
        };
      });
    } else {
      setAddDiagnosis((prevState) => {
        const updatedVikriti = [...prevState.vikriti[name]];

        if (!updatedVikriti.includes(title)) {
          updatedVikriti.push(title);
        } else {
          const index = updatedVikriti.indexOf(title);
          updatedVikriti.splice(index, 1);
        }

        return {
          ...prevState,
          vikriti: {
            ...prevState.vikriti,
            [name]: updatedVikriti,
          },
        };
      });
    }
  };

  // medicines autoComplete fields
  const handleAutoComplete = (key) => (event, newValue) => {
    if (newValue && newValue?.itemName) {
      setMedicines((medicines) => ({
        ...medicines,
        [key]: newValue.itemName,
      }));
    } else {
      setMedicines((medicines) => {
        const updatedMedicines = { ...medicines };
        delete updatedMedicines[key];
        return updatedMedicines;
      });
    }
  };

  // medicines textFields
  const addMedicines = (event) => {
    const { name, value } = event.target;
    setMedicines((prevMedicines) => ({
      ...prevMedicines,
      [name]: value,
    }));
  };

  // Dialog medicines save
  const handledMedicineSave = () => {
    const newMedicine = { ...medicines };

    if (newMedicine.medicineName === "") {
      handleSnackbar("select medicine");
      return;
    }

    if (selectedMedicineIndex !== null) {
      // Editing existing medicine
      const updatedMedicineList = medicineList.map((med, index) =>
        index === selectedMedicineIndex ? newMedicine : med
      );

      setAddDiagnosis((prevMedicineData) => ({
        ...prevMedicineData,
        medicines: updatedMedicineList,
      }));
      setMedicineList(updatedMedicineList);
    } else {
      // Add new medicine
      setAddDiagnosis((prevOrderData) => ({
        ...prevOrderData,
        medicine: [...prevOrderData.medicine, newMedicine],
      }));
      setMedicineList((prevMedicineList) => [...prevMedicineList, newMedicine]);
    }
    setSelectedMedicineIndex(null);
    setOpen(false);
  };

  // patient Diagnosis Information Save
  const handleDiagnosisSave = async () => {
    setLoading(true);
    addMedicineDelete();

    try {
      const response = await saveDiagnosis(addDiagnosis, medicineList);
      console.log(response);

      navigate("/diagnosislist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      moreEat: finalMoreDietData,
    });
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      avoidEat: finalLessDietData,
    });
  }, [finalLessDietData, finalMoreDietData]);

  useEffect(() => {
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      avoidEat: finalLessDietData,
    });
  }, [finalLessDietData]);

  // edit patient Diagnosis Information Update
  const handleDiagnosisUpdate = async () => {
    setLoading(true);
    try {
      const response = await updateDiagnosis(
        diagnosisId,
        selectedDiagnosisData
      );
      console.log(response);

      navigate("/diagnosislist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/diagnosislist");
  };

  const handleClear = () => {
    setAddDiagnosis({
      patientId: patientId,
      mainComplaint: "",
      pastMedicalHistory: "",
      prakriti: [],
      vikriti: {
        vata: [],
        pita: [],
        kapha: [],
        aam: [],
        niraam: [],
      },
      diagnosis: "",
      medicine: [],
      panchkarma: [],
      asana: [],
      pranayama: [],
      mudra: [],
      meditation: [],
      upkarma: [],
      avoidEat: "",
      lessEat: "",
      moreEat: "",
      followUpDate: "",
      // mealTime: "",
      doctorCharge: 300,
      medicineCharge: "",
    });

    setMedicines({
      anupan: "",
      dosage: "",
      administrationTime: "",
      medicineName: "",
    });

    setMedicineList([]);
  };

  const handleDeleteAvoidEat = (index) => {
    const updatedPlusData = plusData.filter((_, i) => i !== index);

    setPlusData(updatedPlusData);
    setAddDiagnosis((prevAddDiagnosis) => ({
      ...prevAddDiagnosis,
      avoidEat: updatedPlusData,
    }));
  };

  const handleDeleteMoreEat = (index) => {
    const updatedMinusData = minusData.filter((_, i) => i !== index);

    setMinusData(updatedMinusData);
    setAddDiagnosis((prevAddDiagnosis) => ({
      ...prevAddDiagnosis,
      moreEat: updatedMinusData,
    }));
  };

  const handleNewPlusItemChange = (event) => {
    const newName = event.target.value;
    setNewPlusItem((prevNewItem) => ({
      ...prevNewItem,
      name: newName,
    }));
  };

  const handleAddNewPlusItem = () => {
    console.log("here")
    if (newPlusItem.name === "") {
      handleSnackbar("Please enter an item name !");
      return;
    }

    setNewPlusItem((prevNewItem) => ({
      ...prevNewItem,
      type: plusData[0]?.type,
    }));
    setPlusData((prevState) => [...prevState, newPlusItem]);
    setNewPlusItem((prevNewItem) => ({
      ...prevNewItem,
      name: "",
      type: "",
    }));
    setSnackbarOpen(true);
    setFinalMoreDietData([...finalMoreDietData, { name: newPlusItem.name, eName: newPlusItem.name}])

  };

  const handleNewMinusItemChange = (event) => {
    const newName = event.target.value;
    setNewMinusItem({...newMinusItem, values: [{ ...newMinusItem.values[0], name: newName, eName: newName }]});
  };

  const handleAddNewMinusItem = () => {
    console.log(newMinusItem);
    if (newMinusItem.name === "") {
      handleSnackbar("Please enter an item name !");
      return;
    }

    setMinusData([...minusData, newMinusItem.values[0].name]);
    // setNewMinusItem([...newMinusItem]);
    // setSnackbarOpen(true);
    setSelectedMoreDietData([...selectedMoreDietData, newMinusItem]);

    setFinalMoreDietData([...finalMoreDietData, { name: newMinusItem.values[0].name, eName: newMinusItem.values[0].name}])
    
  };

  const handleClearTextFiled = () => {
    console.log("Clear called")
    setNewPlusItem((prevNewItem) => ({
      ...prevNewItem,
      name: "",
      type: "",
    }));
    setNewMinusItem((prevNewItem) => ({
      ...prevNewItem,
      name: "",
      type: "",
    }));
  };

  const [followUpDays, setFollowUpDays] = useState(0);
  const [followUpDate, setFollowUpDate] = useState(moment());
  const [justTheDate, setJustTheDate] = useState("");

  const handleFollowUpDate = (event) => {
    const selectedDays = event.target.value;
    const currentDate = moment();
    const newFollowUpDate = moment(currentDate).add(selectedDays, "days");

    setFollowUpDays(selectedDays);
    setFollowUpDate(newFollowUpDate);
    setJustTheDate(newFollowUpDate.format("DD-MM-YYYY"));

    if (diagnosisId) {
      setSelectedDiagnosisData((prev) => ({
        ...prev,
        followUpDate: newFollowUpDate.toISOString(),
      }));
    }
  };

  const [hintDate, setHintDate] = useState("");

  useEffect(() => {
    if (selectedDiagnosisData.followUpDate) {
      setHintDate(
        moment(selectedDiagnosisData.followUpDate).format("DD-MM-YYYY")
      );
    }
  }, [selectedDiagnosisData.followUpDate]);

  const handleDatePicker = (selectedDate) => {
    const currentDate = moment().startOf("day");
    const differenceInDays = moment(selectedDate).diff(currentDate, "days");
    setFollowUpDate(moment(selectedDate));
    setFollowUpDays(differenceInDays.toString());
    setJustTheDate(moment(selectedDate).format("DD-MM-YYYY"));

    if (diagnosisId) {
      setSelectedDiagnosisData((prev) => ({
        ...prev,
        followUpDate: moment(selectedDate).toISOString(),
      }));
    }
  };

  useEffect(() => {
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      [selectedDiagnosisData.followUpDate]: justTheDate,
    });
  }, [followUpDate]);

  useEffect(() => {
    const startDate = moment().format("DD-MM-YYYY");
    const endDate = moment(followUpDate).format("DD-MM-YYYY");

    const daysBetween = getDaysBetweenDates(startDate, endDate);
    const followUpDateObject = moment(justTheDate, "DD-MM-YYYY").toDate();

    setHintDate(moment(followUpDateObject).format("DD-MM-YYYY"));

    setAddDiagnosis((prev) => ({ ...prev, followUpDate: followUpDateObject }));
  }, [followUpDate, justTheDate]);

  function getDaysBetweenDates(startDate, endDate) {
    const start = moment(startDate, "DD-MM-YYYY");
    const end = moment(endDate, "DD-MM-YYYY");
    return end.diff(start, "days");
  }

  const [asana, setAsana] = useState([]);
  const [panchakarma, setPanchakarma] = useState([]);
  const [mudra, setMudra] = useState([]);
  const [asanaData, setAsanaData] = useState([]);
  const [pranayama, setPranayama] = useState([]);
  const [upkarma, setUpkarma] = useState([]);
  const [meditation, setMeditation] = useState([]);

  const fetchAsanaData = async () => {
    try {
      const data = await getAsanaData();
      setAsana(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAsanaData();
  }, []);

  useEffect(() => {
    const panchakarmaData = asana.filter((item) => {
      return item.type === "panchakarma";
    });
    const mudraData = asana.filter((item) => {
      return item.type === "mudra";
    });
    const asanaData = asana.filter((item) => {
      return item.type === "asana";
    });
    const pranayamaData = asana.filter((item) => {
      return item.type === "pranayama";
    });
    const upkarmaData = asana.filter((item) => {
      return item.type === "upkarma";
    });
    const meditationData = asana.filter((item) => {
      return item.type === "meditation";
    });

    setPanchakarma(panchakarmaData);
    setMudra(mudraData);
    setMeditation(meditationData);
    setAsanaData(asanaData);
    setPranayama(pranayamaData);
    setUpkarma(upkarmaData);
  }, [asana]);

  const [panchakarmaValue, setPanchakarmaValue] = useState("");
  const [upkarmaValue, setUpkarmaValue] = useState("");
  const [pranayamaValue, setPranayamaValue] = useState("");
  const [asanaValue, setAsanaValue] = useState("");
  const [mudraValue, setMudraValue] = useState("");
  const [meditationValue, setMeditationValue] = useState("");

  const handlePanchakarmaValue = async () => {
    try {
      if (panchakarmaValue === "") {
        handleSnackbar("Enter valid panchakarma");
        return;
      } else {
        const result = await createAasana("panchakarma", panchakarmaValue);
        setPanchakarma([...panchakarma, result.data]);
        setPanchakarmaValue("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAsanaValue = async () => {
    if (asanaValue === "") {
      handleSnackbar("Enter valid asana");
      return;
    }
    const result = await createAasana("asana", asanaValue);
    setAsanaData([...asanaData, result.data]);
    setAsanaValue("");
  };
  const handlePranayamaValue = async () => {
    if (pranayamaValue === "") {
      handleSnackbar("Enter valid pranayama");
      return;
    }

    const result = await createAasana("pranayama", pranayamaValue);
    setPranayama([...pranayama, result.data]);
    setPranayamaValue("");
  };
  const handleMudraValue = async () => {
    if (mudraValue === "") {
      handleSnackbar("Enter valid Mudra");
      return;
    }

    const result = await createAasana("mudra", mudraValue);
    setMudra([...mudra, result.data]);
    setMudraValue("");
  };
  const handleMeditationValue = async () => {
    if (meditationValue === "") {
      handleSnackbar("Enter valid meditation");
      return;
    }

    const result = await createAasana("meditation", meditationValue);
    setMeditationValue("");
    setMeditation([...meditation, result.data]);
  };
  const handleUpkarmaaValue = async () => {
    if (upkarmaValue === "") {
      handleSnackbar("Enter valid upkarma");
      return;
    }

    const result = await createAasana("upkarma", upkarmaValue);
    setUpkarma([...upkarma, result.data]);
    setUpkarmaValue("");
  };

  const clearTextFiled = () => {
    setPanchakarmaValue("");
    setUpkarmaValue("");
    setPranayamaValue("");
    setMudraValue("");
    setMeditationValue("");
    setAsanaValue("");
  };

  const handleDeleteAsans = async (deleteId, type) => {
    try {
      const deletedData = await deleteAasana(deleteId, type);
      if (deletedData.success == true) {
        getAsanaData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  // Initialize state variables
  const [checkedData, setCheckedData] = useState({
    panchakarma: [],
    asana: [],
    pranayama: [],
    mudra: [],
    upkarma: [],
    meditation: [],
  });

  useEffect(() => {
    setAddDiagnosis((prevAddDiagnosis) => ({
      ...prevAddDiagnosis,
      medicine: medicineList,
    }));
  }, [medicine]);

  useEffect(() => {
    setAddDiagnosis((prevAddDiagnosis) => ({
      ...prevAddDiagnosis,
      panchakarma: checkedData.panchakarma,
      asana: checkedData.asana,
      pranayama: checkedData.pranayama,
      mudra: checkedData.mudra,
      upkarma: checkedData.upkarma,
      meditation: checkedData.meditation,
    }));
  }, [checkedData]);

  // Symptoms
  const [selectedValue, setSelectedValue] = useState(null);

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const filteredItems = symptomsName.filter((currentItem) => {
    const containsSelectedValue =
      currentItem.options &&
      Array.isArray(currentItem.options) &&
      currentItem.options.some(
        (item) =>
          selectedValue &&
          Array.isArray(selectedValue) &&
          selectedValue.some(
            (selectedItem) => item.title === selectedItem.title
          )
      );
    return containsSelectedValue;
  });

  // Symptoms Checkbox item selected
  const handleCheckboxClick = (item) => {
    const isSelected = selectedValue.some(
      (selectedItem) => selectedItem.title === item.title
    );

    if (isSelected) {
      setSelectedValue(
        selectedValue.filter(
          (selectedItem) => selectedItem.title !== item.title
        )
      );
    } else {
      setSelectedValue([...selectedValue, item]);
    }
  };

  const filterOptionsSymptoms = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.eTitle,
  });

  useEffect(() => {
    const updatedSymptoms = filteredItems.map((symptom) => {
      const commonSymptoms = symptom.options
        .filter((item) =>
          selectedValue
            .map((selectedItem) => selectedItem.title)
            .includes(item.title)
        )
        .map((item) => ({ title: item.title }));

      return {
        title: symptom.title,
        options: commonSymptoms,
      };
    });

    setAddDiagnosis({ ...addDiagnosis, symptoms: updatedSymptoms });
  }, [selectedValue]);

  const [uncheckedSymptoms, setUncheckedSymptoms] = useState([]);

  useEffect(() => {
    const newSymptoms = selectedDiagnosisData.symptoms;
    const filteredSymptoms = newSymptoms
      ? symptomsName.filter((item) =>
          newSymptoms.some((newSymptom) => newSymptom.title === item.title)
        )
      : [];

    setUncheckedSymptoms(filteredSymptoms);
  }, [selectedDiagnosisData.symptoms]);

  // Edit patient Diagnosis Symptoms Checked Show
  const checkSymptomsOptions = (value) => {
    return selectedDiagnosisData.symptoms.some((mainTitle) => {
      return mainTitle.options.some(
        (subTitle) => subTitle.title === value.title
      );
    });
  };

  const handleCheckboxClickUpdate = (currentItem, item) => {
    const isSelected = checkSymptomsOptions(item);
    const updatedSymptoms = selectedDiagnosisData.symptoms.map((symptom) => {
      if (symptom.title === currentItem.title) {
        symptom.options = isSelected
          ? symptom.options.filter(
              (selectedItem) => selectedItem.title !== item.title
            )
          : [...symptom.options, { title: item.title }];
      }
      return symptom;
    });

    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      symptoms: updatedSymptoms,
    });
  };

  const updateHandleAutocompleteChange = (event, newValues) => {
    setSelectedValue(newValues);
  };

  // to-do update this logic for prakriti avoid eat and more eat
  useEffect(() => {
    if (patientData && patientData.prakriti) {
      setAddDiagnosis((prevState) => ({
        ...prevState,
        prakriti: [patientData.prakriti],
      }));
      handleFetchPrakritiData([patientData.prakriti]);
    } else {
      setAddDiagnosis((prevState) => ({
        ...prevState,
        prakriti: [],
      }));
    }
  }, [patientData]);

  const [addDeleteOpen, setAddDeleteOpen] = useState(false);
  const [deleteMedicineIndex, setDeleteMedicineIndex] = useState(null);
  const handleMedicineDelete = (index) => {
    console.log("index", index);
    setDeleteMedicineIndex(index);
    setAddDeleteOpen(true);
    return index;
  };

  const addMedicineDelete = (indexToDelete) => {
    if (indexToDelete >= 0 && indexToDelete < medicineList.length) {
      const updatedMedicineList = medicineList.filter(
        (_, index) => index !== indexToDelete
      );

      setMedicineList(updatedMedicineList);
      setDeleteMedicineIndex(null);
    }
  };

  const [selectedMedicineIndex, setSelectedMedicineIndex] = useState(null);

  const addMedicineEdit = (index) => {
    const selectedMedicine = medicineList[index];

    setSelectedMedicineIndex(index);
    console.log("selectedMedicine", selectedMedicine);

    setMedicines({
      medicineName: selectedMedicine.medicineName,
      anupan: selectedMedicine.anupan,
      administrationTime: selectedMedicine.administrationTime,
      dosage: selectedMedicine.dosage,
    });

    setOpen(true);
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteOpen = (index) => {
    setItemToDelete(index);
    setDeleteOpen(true);
  };



  const handleDeleteMedicine = (index) => {
    console.log("Deleting medicine with ID:", index);
    const updatedMedicines = [...selectedDiagnosisData.medicine];
    updatedMedicines.splice(index, 1);
    console.log("updatedMedicines", updatedMedicines);
    setSelectedDiagnosisData({
      ...selectedDiagnosisData,
      medicine: updatedMedicines,
    });
  };

  const handleMedicineEditOpen = (medicineIndex) => {
    console.log("medicineIndex", medicineIndex);
    setOpen(true);
    const editItem = selectedDiagnosisData.medicine[medicineIndex];
    setSelectedMedicineIndex(medicineIndex);
    setMedicines({
      medicineName: editItem?.medicineName || "",
      anupan: editItem?.anupan || "",
      administrationTime: editItem?.administrationTime || "",
      dosage: editItem?.dosage || "",
    });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Dialog Medicine Update
  const handleMedicineUpdate = () => {
    const newMedicine = { ...medicines };

    if (newMedicine.medicineName === "") {
      handleSnackbar("select medicine");
      return;
    }

    if (selectedMedicineIndex !== null) {
      const updatedMedicineList = [...selectedDiagnosisData.medicine];

      updatedMedicineList[selectedMedicineIndex] = {
        ...updatedMedicineList[selectedMedicineIndex],
        ...medicines,
      };

      setSelectedDiagnosisData({
        ...selectedDiagnosisData,
        medicine: updatedMedicineList,
      });
    } else {
      setSelectedDiagnosisData((prevOrderData) => ({
        ...prevOrderData,
        medicine: [...prevOrderData.medicine, newMedicine],
      }));
    }
    setSelectedMedicineIndex(null);
    setOpen(false);
  };
  const [selectedMoreDietData, setSelectedMoreDietData] = useState([]);
  const [selectedLessDietData, setSelectedLessDietData] = useState([]);

  useEffect(()=> {
    console.log({selectedMoreDietData}, {finalMoreDietData})
    setFinalMoreDietData([...selectedMoreDietData.map(property => {
      return property.values;
    }).flat()]);
  }, [selectedMoreDietData])

  useEffect(()=> {
    console.log({selectedLessDietData}, {finalLessDietData})
    setFinalLessDietData([...selectedLessDietData.map(property => {
      return property.values;
    }).flat()]);
  }, [selectedLessDietData])

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box>
            <Breadcrumbs>
              <Link style={linkStyle} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linkStyle} to="/patientlist">
                Patient
              </Link>
              <Link style={linkStyle} underline="hover" to="/diagnosis">
                Diagnosis
              </Link>
            </Breadcrumbs>
            <Typography sx={mainHeading}>
              {diagnosisId ? "Edit Patient Diagnosis" : "Patient Diagnosis"}
            </Typography>
            <Box>
              <Grid container>
                <Grid md={4} display="flex">
                  <Box ml={3}>
                    <Box mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateField"]}>
                          <DateField
                            label="Date"
                            value={moment(currentDate)}
                            readOnly
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <Typography sx={typoStyle}>
                      Name : &nbsp;
                      {patientData && patientData.firstName
                        ? patientData.firstName.charAt(0).toUpperCase() +
                          patientData.firstName.slice(1)
                        : "Not specified"}
                    </Typography>
                    <Typography sx={typoStyle}>
                      Gender : &nbsp;
                      {patientData && patientData.gender
                        ? patientData.gender.charAt(0).toUpperCase() +
                          patientData.gender.slice(1)
                        : "Not specified"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={4} display="flex">
                  <Box mt={1}>
                    <Box mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="Follow up Date :"
                          format="DD-MM-YYYY"
                          value={
                            diagnosisId
                              ? dayjs(
                                  moment(
                                    selectedDiagnosisData?.followUpDate
                                  ).format("YYYY-MM-DD")
                                )
                              : dayjs(moment(followUpDate).format("YYYY-MM-DD"))
                          }
                          onChange={(date) =>
                            handleDatePicker(date.format("YYYY-MM-DD"))
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box display="flex">
                      <Typography sx={typoStyle}>
                        Age Year : {patientData.ageYear}
                      </Typography>
                      <Typography sx={typoStyle} ml={2}>
                        Age Month : {patientData.ageMonth}
                      </Typography>
                    </Box>
                    <Typography sx={typoStyle}>
                      Mobile No : {patientData.mobileNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={4} display="flex">
                  <Box>
                    <Typography sx={typoStyle}>Doctor Stamp :</Typography>
                    <Typography sx={typoStyle}>Doctor Code :</Typography>
                    <Typography sx={typoStyle}>Receipt No:</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider sx={{ height: 1, bgcolor: "black", m: "20px" }} />
          <Box sx={{ marginBottom: "10px" }}>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={2} display={"flex"} justifyContent={"center"}>
                <Typography sx={typoStyle}>Main Complain :</Typography>
              </Grid>
              <Grid md={5}>
                <TextField
                  value={
                    selectedDiagnosisData.mainComplaint !== undefined
                      ? selectedDiagnosisData.mainComplaint
                      : addDiagnosis.mainComplaint
                  }
                  name="mainComplaint"
                  onChange={
                    diagnosisId ? updatePatientDiagnosis : patientDiagnosis
                  }
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={2} display={"flex"} justifyContent={"center"}>
                <Typography sx={typoStyle}>Past Medical History :</Typography>
              </Grid>
              <Grid md={5}>
                <TextField
                  value={
                    selectedDiagnosisData
                      ? selectedDiagnosisData.pastMedicalHistory
                      : addDiagnosis.pastMedicalHistory
                  }
                  name="pastMedicalHistory"
                  onChange={
                    diagnosisId ? updatePatientDiagnosis : patientDiagnosis
                  }
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={2} display={"flex"} justifyContent={"center"}>
                <Typography sx={typoStyle}>Prakruti :</Typography>
              </Grid>
              <Grid md={1} display={"flex"} alignItems={"center"}>
                <Typography paddingTop={"3.5px"}>VATA</Typography>
                <Checkbox
                  name="vatta"
                  onChange={handlePrakritiChange}
                  checked={
                    diagnosisId
                      ? prakriti.prakruti?.includes("vatta")
                      : addDiagnosis.prakriti.includes("vatta")
                  }
                />
              </Grid>
              <Grid md={1} display={"flex"} alignItems={"center"}>
                <Typography paddingTop={"3.5px"}>PITTA</Typography>
                <Checkbox
                  name="pitta"
                  onChange={handlePrakritiChange}
                  checked={
                    diagnosisId
                      ? prakriti.prakruti?.includes("pitta")
                      : addDiagnosis.prakriti.includes("pitta")
                  }
                />
              </Grid>
              <Grid md={1} display={"flex"} alignItems={"center"}>
                <Typography paddingTop={"3.5px"}>KAPHA</Typography>
                <Checkbox
                  name="kapha"
                  onChange={handlePrakritiChange}
                  checked={
                    diagnosisId
                      ? prakriti.prakruti?.includes("kapha")
                      : addDiagnosis.prakriti.includes("kapha")
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box pt={2} mb="10px">
            <Grid container>
              <Grid md={12} display="flex" alignItems="center">
                <Grid md={2} display="flex" justifyContent="center">
                  <Typography sx={typoStyle}>Symptoms :</Typography>
                </Grid>
                <Grid md={10}>
                  <Grid md={12}>
                    <Autocomplete
                      disablePortal
                      multiple
                      id="tags-outlined"
                      options={symptomsName.reduce(
                        (acc, key) => [...acc, ...key.options],
                        []
                      )}
                      filterOptions={filterOptionsSymptoms}
                      getOptionLabel={(sTitle) => sTitle.title}
                      defaultValue={
                        selectedValue
                          ? [
                              symptomsName
                                .reduce(
                                  (acc, key) => acc.concat(key.options),
                                  []
                                )
                                .find(
                                  (option) => option.title === selectedValue
                                ),
                            ]
                          : []
                      }
                      onChange={
                        selectedDiagnosisData.symptoms !== undefined
                          ? updateHandleAutocompleteChange
                          : handleAutocompleteChange
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          label="Symptoms"
                          placeholder="Symptoms"
                        />
                      )}
                    />
                  </Grid>
                  <Grid md={12} mt={2} sx={{display: "flex", flexDirection: "row", justifyContent:"flexStart", alignItems:"center", flexWrap:"wrap", gap:"8px"}}>
                    {selectedDiagnosisData.symptoms !== undefined ? (
                      <>
                        {uncheckedSymptoms.map((currentItem, index) => (
                          <>
                            {currentItem.options.map((item) => (
                              checkSymptomsOptions(item.title) ? <Typography>{currentItem.title}({item.title}),</Typography> : <></>
                            ))}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {filteredItems.map((currentItem, index) => (
                          <>
                            {currentItem.options.map((item) => (
                              selectedValue.some(
                                (selectedItem) =>
                                  selectedItem.title ===
                                  item.title
                              ) ? <Typography>{currentItem.title}({item.title}),</Typography> : <></>
                            ))}
                          </>
                        ))}
                      </>
                    )}
                  </Grid>
                  <Grid md={12} mt={2}>
                    <TableContainer sx={{ maxHeight: 540 }}>
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell size="small" align="center">
                              Title
                            </TableCell>
                            <TableCell size="small" align="center">
                              Symptoms
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedDiagnosisData.symptoms !== undefined ? (
                            <>
                              {uncheckedSymptoms.map((currentItem, index) => (
                                <TableRow key={index}>
                                  <TableCell size="small" align="center" sx={{borderBottom: "1px solid #2F7539"}}>
                                    {currentItem.title}
                                  </TableCell>
                                  <TableCell size="small" align="center" style={{ display: "flex", flexDirection: "row", justifyContent:"flexStart", alignItems:"center", flexWrap:"wrap", gap:"8px", borderBottom: "1px solid #2F7539"}}>
                                    <FormGroup >
                                      {currentItem.options.map((item) => (
                                        <FormControlLabel
                                          key={item}
                                          control={
                                            <Checkbox
                                              // defaultChecked={checkSymptomsOptions(
                                              //   item
                                              // )}
                                              checked={checkSymptomsOptions(
                                                item
                                              )}
                                              onChange={() =>
                                                handleCheckboxClickUpdate(
                                                  currentItem,
                                                  item
                                                )
                                              }
                                            />
                                          }
                                          label={item.title}
                                          style={{ whiteSpace: "nowrap"}}
                                        />
                                      ))}
                                    </FormGroup>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <>
                              {filteredItems.map((currentItem, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell size="small" align="center" sx={{borderBottom: "1px solid #2F7539"}}>
                                      {currentItem.title}
                                    </TableCell>
                                    <TableCell size="small" align="center" style={{ display: "flex", flexDirection: "row", justifyContent:"flexStart", alignItems:"center", flexWrap:"wrap", gap:"8px", borderBottom: "1px solid #2F7539", borderLeft: "1px solid grey"}}>
                                      {currentItem.options.map((item) => (
                                        <React.Fragment key={item.title}>
                                          <FormGroup >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={selectedValue.some(
                                                    (selectedItem) =>
                                                      selectedItem.title ===
                                                      item.title
                                                  )}
                                                  onChange={() =>
                                                    handleCheckboxClick(item)
                                                  }
                                                />
                                              }
                                              label={item.title}
                                              style={{ whiteSpace: "nowrap"}}
                                            />
                                          </FormGroup>
                                        </React.Fragment>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Vikruti
            handleVikritiChange={handleVikritiChange}
            typoStyle={typoStyle}
            diagnosisId={diagnosisId}
            prakriti={prakriti}
            addDiagnosis={addDiagnosis}
            selectedDiagnosisData={selectedDiagnosisData}
          />

          <Box sx={{ marginBottom: "10px" }}>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={2} display={"flex"} justifyContent={"center"}>
                <Typography sx={typoStyle}>Diagnosis :</Typography>
              </Grid>
              <Grid md={5}>
                <TextField
                  value={
                    selectedDiagnosisData
                      ? selectedDiagnosisData.diagnosis
                      : addDiagnosis.diagnosis
                  }
                  name="diagnosis"
                  onChange={
                    diagnosisId ? updatePatientDiagnosis : patientDiagnosis
                  }
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              variant="contained"
              sx={{ mt: "30px" }}
              color="success"
              onClick={handleAddMedicine}
            >
              <AddCircleOutlineIcon sx={{ fontSize: "18px" }} />
              <Typography sx={patientButton}>Add Medicine</Typography>
            </Button>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />
          <TableContainer sx={{ maxHeight: 340 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Medicine Name</TableCell>
                  <TableCell align="center">Dosage</TableCell>
                  <TableCell align="center">Anupan</TableCell>
                  <TableCell align="center">Administration Time</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diagnosisId && selectedDiagnosisData.medicine ? (
                  selectedDiagnosisData.medicine.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{item.medicineName}</TableCell>
                      <TableCell align="center">{item.dosage}</TableCell>
                      <TableCell align="center">{item.anupan}</TableCell>
                      <TableCell align="center">
                        {item.administrationTime}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => handleMedicineEditOpen(index)}
                          disableRipple
                          sx={hoverEffect}
                        >
                          <EditIcon sx={editButton} />
                        </Button>
                        <Button
                          onClick={() => handleDeleteOpen(index)}
                          disableRipple
                          sx={hoverEffect}
                        >
                          <DeleteIcon sx={deleteButton} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : medicineList.length > 0 ? (
                  medicineList.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{item.medicineName}</TableCell>
                      <TableCell align="center">{item.dosage}</TableCell>
                      <TableCell align="center">{item.anupan}</TableCell>
                      <TableCell align="center">
                        {item.administrationTime}
                      </TableCell>
                      {item.medicineName && (
                        <TableCell align="center">
                          <Button
                            onClick={() => addMedicineEdit(index)}
                            disableRipple
                            sx={hoverEffect}
                          >
                            <EditIcon sx={editButton} />
                          </Button>
                          <Button
                            sx={hoverEffect}
                            onClick={() => handleMedicineDelete(index)}
                          >
                            <DeleteIcon sx={deleteButton} />
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Medicine Added
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />

          <Aasana
            panchakarmaValue={panchakarmaValue}
            setCheckedData={setCheckedData}
            panchakarma={panchakarma}
            checkedData={checkedData}
            handleDeleteAsans={handleDeleteAsans}
            asanaData={asanaData}
            deleteButton={deleteButton}
            pranayama={pranayama}
            setPanchakarmaValue={setPanchakarmaValue}
            handlePanchakarmaValue={handlePanchakarmaValue}
            clearTextFiled={clearTextFiled}
            setAsanaValue={setAsanaValue}
            asanaValue={asanaValue}
            handleAsanaValue={handleAsanaValue}
            setPranayamaValue={setPranayamaValue}
            pranayamaValue={pranayamaValue}
            handlePranayamaValue={handlePranayamaValue}
            mudra={mudra}
            setMudraValue={setMudraValue}
            mudraValue={mudraValue}
            upkarma={upkarma}
            upkarmaValue={upkarmaValue}
            handleMudraValue={handleMudraValue}
            handleMeditationValue={handleMeditationValue}
            setUpkarmaValue={setUpkarmaValue}
            handleUpkarmaaValue={handleUpkarmaaValue}
            selectedDiagnosisData={selectedDiagnosisData}
            setSelectedDiagnosisData={setSelectedDiagnosisData}
            diagnosisId={diagnosisId}
            setPanchakarma={setPanchakarma}
            meditation={meditation}
            setMeditationValue={setMeditationValue}
            meditationValue={meditationValue}
          />

          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />
          <AvoidMore
            typoStyle={typoStyle}
            minusData={minusData}
            setMinusData={setMinusData}
            plusData={plusData}
            setPlusData={setPlusData}
            tooltipStyle={tooltipStyle}
            diagnosisId={diagnosisId}
            handleDeleteMoreEat={handleDeleteMoreEat}
            deleteButton={deleteButton}
            handleDeleteAvoidEat={handleDeleteAvoidEat}
            newMinusItem={newMinusItem}
            handleNewMinusItemChange={handleNewMinusItemChange}
            handleAddNewMinusItem={handleAddNewMinusItem}
            editButton={editButton}
            handleClearTextFiled={handleClearTextFiled}
            newPlusItem={newPlusItem}
            handleNewPlusItemChange={handleNewPlusItemChange}
            handleAddNewPlusItem={handleAddNewPlusItem}
            selectedDiagnosisData={selectedDiagnosisData}
            setSelectedDiagnosisData={setSelectedDiagnosisData}
            handleSnackbar={handleSnackbar}
            finalMoreDietData={finalMoreDietData}
            setFinalMoreDietData={setFinalMoreDietData}
            finalLessDietData={finalLessDietData}
            setFinalLessDietData={setFinalLessDietData}
            selectedMoreDietData={selectedMoreDietData}
            setSelectedMoreDietData={setSelectedMoreDietData}
            selectedLessDietData={selectedLessDietData}
            setSelectedLessDietData={setSelectedLessDietData}
          />
          <Box>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={12} display={"flex"} justifyContent={"center"}>
                <Typography sx={typoStyle} marginBottom={"10px"}>
                  Meal Time
                </Typography>
              </Grid>
            </Grid>
            <Grid container display={"flex"} alignItems={"center"}>
              <Grid md={12} display={"flex"} justifyContent={"space-evenly"}>
                <Typography sx={listStyle}>Breakfast: 7 AM and 8 AM</Typography>

                <Typography sx={listStyle}>Lunch: 12 AM to 12:30 PM</Typography>

                <Typography sx={listStyle}>Dinner: 6 PM to 7:30 PM</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container justifyContent="end">
              <Grid md={3}>
                <TextField
                  sx={{ width: "100%", height: "20%" }}
                  label="Follow up days"
                  value={followUpDays}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={handleFollowUpDate}
                />
              </Grid>
              <Grid md={3} ml={2}>
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="Medicine Charge"
                  name="medicineCharge"
                  onChange={
                    diagnosisId ? updatePatientDiagnosis : patientDiagnosis
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  value={
                    selectedDiagnosisData.medicineCharge !== undefined
                      ? selectedDiagnosisData.medicineCharge
                      : addDiagnosis.medicineCharge
                  }
                />
              </Grid>
              <Grid md={3} ml={2}>
                <TextField
                  sx={{ width: "100%" }}
                  defaultValue={"300"}
                  placeholder="Doctor Charge"
                  name="doctorCharge"
                  value={
                    selectedDiagnosisData.doctorCharge !== undefined
                      ? selectedDiagnosisData.doctorCharge
                      : addDiagnosis.doctorCharge
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={
                    diagnosisId ? updatePatientDiagnosis : patientDiagnosis
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <div
            style={{
              marginLeft: "30%",
              fontSize: "14px",
              marginTop: "5px",
              color: "gray",
            }}
          >
            Hint :{" "}
            {diagnosisId
              ? hintDate
              : justTheDate || new Date().toLocaleDateString("en-GB")}
          </div>

          <Box display="flex" pt={5}>
            <Button
              onClick={
                diagnosisId ? handleDiagnosisUpdate : handleDiagnosisSave
              }
              sx={saveButton}
              variant="contained"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : diagnosisId ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={handleCancel} sx={cancelButton} variant="outlined">
              Cancel
            </Button>
            {diagnosisId ? null : (
              <Button
                sx={cancelButton}
                variant="outlined"
                onClick={handleClear}
              >
                Clear
              </Button>
            )}
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="error"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={dialogHeading}>
              {diagnosisId ? "Edit Medicine" : "Add Medicine"}
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider
          sx={{ height: 1, backgroundColor: "Black", marginY: "10px" }}
        />
        <DialogContent sx={{ padding: "0px 20px 20px 20px" }}>
          <Grid container>
            <Grid md={12} mt={2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={medicine}
                onChange={handleAutoComplete("medicineName")}
                value={{ itemName: medicines.medicineName }}
                getOptionLabel={(option) => option.itemName}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="medicineName"
                    label="Medicine Name"
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                    overflowY: "auto",
                  },
                }}
              />
            </Grid>
            <Grid md={12} mt={2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={Anupan}
                onChange={handleAutoComplete("anupan")}
                value={{ itemName: medicines.anupan }}
                getOptionLabel={(option) => option.itemName}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} name="anupan" label="Anupan" />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                    overflowY: "auto",
                  },
                }}
              />
            </Grid>
            <Grid md={12} mt={2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={administrationTime}
                onChange={handleAutoComplete("administrationTime")}
                value={{ itemName: medicines.administrationTime }}
                getOptionLabel={(option) => option.itemName}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="administrationTime"
                    label="Administration Time"
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                    overflowY: "auto",
                  },
                }}
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                value={medicines.dosage}
                name="dosage"
                placeholder="Dosage"
                label="Dosage"
                onChange={addMedicines}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            // onClick={handledMedicine}
            onClick={diagnosisId ? handleMedicineUpdate : handledMedicineSave}
            sx={saveButton}
            variant="contained"
          >
            {diagnosisId ? "Save" : "Save"}
          </Button>
          <Button
            sx={cancelButton}
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addDeleteOpen} onClose={() => setAddDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setAddDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              addMedicineDelete(deleteMedicineIndex);
              setAddDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              handleDeleteMedicine(itemToDelete);
              setDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Diagnosis;
