import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";


const createAasana = async (type, name) => {
  const data = {
    type: type,
    name: name,
    default: false,
  };

  try {
    const response = await axios.post(`${baseUrl.url}/api/asana`, data, {
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    });
    return response.data; // Return the entire 'data' object from the response
  } catch (error) {
    console.log(error);
    return null; // Return null or handle the error as needed
  }
};

export default createAasana;
