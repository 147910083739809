import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { ClipLoader } from "react-spinners";
import "rsuite/dist/rsuite-no-reset.min.css";
import { DateRangePicker } from "rsuite";

// icons
import VaccinesIcon from "@mui/icons-material/Vaccines";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";

// Component
import Header from "../common/Header/Header";
import moment from "moment";
import baseUrl from "../../constants/constant.json";

import {
  deletePatient,
  getPatient,
} from "../../services/Patient/PatientService";
import axios from "axios";
import { getToken } from "../../utils/getToken";

// css
const mainHeading = {
  color: "#424242",
  fontSize: "30px",
  fontWeight: "700",
  marginBottom: "10px",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const addIcon = {
  color: "white",
  fontSize: "22px",
  paddingLeft: "2px",
  paddingRight: "5px",
  textTransform: "none",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
};

const listStyle = {
  textDecoration: "none",
  textTransform: "none",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const dialogHeading = {
  color: "#424242",
  fontSize: "26px",
  fontWeight: "700",
};

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const patientInfo = {
  bgcolor: "#F6F7FA",
  width: "96%",
  border: "none",
  pointerEvents: "none",
  cursor: "default",
};

const patientName = {
  bgcolor: "#F6F7FA",
  width: "100%",
  border: "none",
  pointerEvents: "none",
  cursor: "default",
};

const datePicker = {
  width: "250px",
  marginLeft: "10px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const linkStyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

function Patient() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [patientData, setPatientData] = useState([]);
  const [singlePatientData, setSinglePatientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchByName, setSearchByName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const user = localStorage.getItem("user");

  const handleNameInputChange = (event) => {
    setSearchByName(event.target.value);
  };

  useEffect(() => {
    // const fetchData = () => {
    //   try {
    //     setIsLoading(true);
    //     const getPatientData = getPatient(searchByName, startDate, endDate);
    //     setPatientData(getPatientData);
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsLoading(false);
    //   }
    // };
    // fetchData();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/patient/list?name=${searchByName}&startDate=${startDate}&endDate=${endDate}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setPatientData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
        setIsLoading(false);
      });
  }, [searchByName, startDate, endDate]);

  const viewAllData = (patientId) => {
    const selectedPatient = patientData.find((item) => item._id === patientId);

    if (selectedPatient) {
      setSinglePatientData(selectedPatient);
      setOpen(true);
    }
  };

  const deleteData = async (patientId) => {
    const deleteData = await deletePatient(patientId);
    console.log(deleteData);
  };

  const handleDeleteOpen = (patientId) => {
    setPatientToDelete(patientId);
    setDeleteOpen(true);
  };

  // search by date range Picker
  const handleDateRangeChange = (value) => {
    if (value && value.length === 2) {
      setStartDate(value[0].toISOString().split("T")[0]);
      setEndDate(value[1].toISOString().split("T")[0]);
    } else {
      // Handle the case when the date range is cleared
      setStartDate("");
      setEndDate("");
    }
  };

  const handleDiagnosis = (prakruti) => {
    localStorage.setItem("prakruti", prakruti);
  };

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box padding="20px">
            <Box>
              <Box>
                <Breadcrumbs>
                  {user === "admin" ? (
                    <Link style={linkStyle} to="/patientlist">
                      Patient
                    </Link>
                  ) : (
                    <Breadcrumbs>
                      <Link style={linkStyle} to="/dashboard">
                        Dashboard
                      </Link>
                      <Link style={linkStyle} to="/patientlist">
                        Patient
                      </Link>
                    </Breadcrumbs>
                  )}
                </Breadcrumbs>
                <Typography sx={mainHeading}>Patient</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex">
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search by Name"
                      inputProps={{ "aria-label": "Search by Name" }}
                      value={searchByName}
                      onChange={handleNameInputChange}
                    />
                  </Search>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search by Mobile No "
                      inputProps={{
                        "aria-label": "Search by Mobile Number ",
                      }}
                    />
                  </Search>
                  <Box sx={datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ paddingTop: "0px" }}
                        components={["DateRangePicker"]}
                      >
                        <DateRangePicker
                          size="lg"
                          placeholder="Select Date Range"
                          onChange={handleDateRangeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box display="flex">
                  <Link to="/addpatient" style={listStyle}>
                    <Button variant="contained" color="success">
                      <AddCircleOutlineIcon sx={addIcon} />
                      <Typography sx={patientButton}>Patient</Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ height: 1, bgcolor: "black", m: "20px" }} />
            <Box marginTop="40px">
              <TableContainer sx={{ maxHeight: 480 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Mobile No</TableCell>
                      <TableCell align="center">Age</TableCell>
                      <TableCell align="center">Weight</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      height="330px"
                      top="35%"
                      left="50%"
                    >
                      <ClipLoader color="#2F7539" />
                    </Box>
                  ) : (
                    <TableBody>
                      {patientData?.map((item) => {
                        return (
                          <>
                            <TableRow
                              key={item._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {item.firstName}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {item.mobileNumber}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {item.ageYear}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {item.weight}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {user === "admin" ? null : (
                                  <Link
                                    to={`/diagnosis?patientId=${item._id}`}
                                    style={listStyle}
                                  >
                                    <Button
                                      sx={hoverEffect}
                                      disableRipple
                                      onClick={() =>
                                        handleDiagnosis(item.prakruti)
                                      }
                                    >
                                      <VaccinesIcon />
                                    </Button>
                                  </Link>
                                )}
                                <Button
                                  disableRipple
                                  key={item._id}
                                  onClick={() => viewAllData(item._id)}
                                  sx={hoverEffect}
                                >
                                  <VisibilityIcon />
                                </Button>
                                <Link
                                  to={`/addpatient/${item._id}`}
                                  style={listStyle}
                                >
                                  <Button disableRipple sx={hoverEffect}>
                                    <EditIcon sx={editButton} />
                                  </Button>
                                </Link>
                                <Button
                                  onClick={() => handleDeleteOpen(item._id)}
                                  disableRipple
                                  sx={hoverEffect}
                                >
                                  <DeleteIcon sx={deleteButton} />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* single patient data  */}
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography sx={dialogHeading}>Patient Info</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ marginBottom: "10px" }} />
        <DialogContent sx={{ pt: 2 }}>
          <Box>
            <Typography sx={{ fontWeight: "600" }}>Personal Details</Typography>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>First Name</Typography>

                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.firstName}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Middle Name</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.middleName}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Last Name</Typography>
                  <TextField
                    size="small"
                    sx={patientName}
                    value={singlePatientData.lastName}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt={2}>
              <Typography sx={{ pb: 1 }}>Address</Typography>
              <TextField
                size="small"
                sx={patientName}
                value={singlePatientData.address}
              />
            </Box>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={6}>
                  <Box>
                    <Typography sx={{ pb: 1 }}>Mobile Number</Typography>
                    <TextField
                      size="small"
                      sx={{
                        bgcolor: "#F6F7FA",
                        border: "none",
                        width: "98%",
                        pointerEvents: "none",
                        cursor: "default",
                      }}
                      value={singlePatientData.mobileNumber}
                    />
                  </Box>
                </Grid>
                <Grid md={6}>
                  <Box>
                    <Typography sx={{ pb: 1, ml: 1 }}>Email</Typography>
                    <TextField
                      size="small"
                      sx={{
                        bgcolor: "#F6F7FA",
                        border: "none",
                        width: "98%",
                        ml: "9px",
                        pointerEvents: "none",
                        cursor: "default",
                      }}
                      value={singlePatientData.email}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box pt={2}>
              <Grid container>
                <Grid md={4}>
                  <Typography pb={1}>Id Number</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.idNumber}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography pb={1}>Gender</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.gender}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography pb={1}>Weight</Typography>
                  <TextField
                    size="small"
                    sx={patientName}
                    value={singlePatientData.weight}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box pt={2}>
            <Typography sx={{ fontWeight: "600" }}>General Details</Typography>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Birth Date</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={
                      singlePatientData.birthDate
                        ? moment(singlePatientData.birthDate).format(
                            "DD-MM-YYYY"
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Birth Time</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={
                      singlePatientData.birthTime
                        ? new Date(
                            singlePatientData.birthTime
                          ).toLocaleTimeString()
                        : ""
                    }
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Birth Place</Typography>
                  <TextField
                    size="small"
                    sx={patientName}
                    value={singlePatientData.birthPlace}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Age Year</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.ageYear}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Age Month</Typography>
                  <TextField
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.ageMonth}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Age Group</Typography>
                  <TextField
                    size="small"
                    sx={patientName}
                    value={singlePatientData.ageGroup}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box pt={2}>
            <Typography sx={{ fontWeight: "600" }}>
              Reference Details
            </Typography>
            <Grid container pt={2}>
              <Grid md={6}>
                <Typography pb={1}>Reference Name</Typography>
                <TextField
                  size="small"
                  sx={patientInfo}
                  value={singlePatientData.refName}
                />
              </Grid>
              <Grid md={6}>
                <Typography pb={1}>Reference Mo.Number</Typography>
                <TextField
                  size="small"
                  sx={patientName}
                  value={singlePatientData.refMobileNo}
                />
              </Grid>
            </Grid>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={6}>
                  <Typography pb={1}>Reference Media</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    sx={patientInfo}
                    value={singlePatientData.refMedia}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* delete patient data */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => deleteData(patientToDelete)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Patient;
