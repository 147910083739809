export function handleCheckBox(item, checkedData, setCheckedData) {
  const { type, name, youtubeVideoLink } = item;

  setCheckedData((prevCheckedData) => {
    const updatedCheckedData = { ...prevCheckedData };

    if (updatedCheckedData.hasOwnProperty(type)) {
      const typeArray = [...updatedCheckedData[type]];

      // Check if the item is already in the array
      const itemIndex = typeArray.findIndex(
        (checkedItem) => checkedItem.name === name
      );

      if (itemIndex !== -1) {
        // Item is already in the array, remove it
        typeArray.splice(itemIndex, 1);
      } else {
        // Item is not in the array, add it
        typeArray.push({
          name: name,
          youtubeVideoLink: youtubeVideoLink,
        });
      }

      updatedCheckedData[type] = typeArray;
    }
    return updatedCheckedData;
  });
}

export function isItemChecked(item, checkedItemData) {
  // Check if the item is in the checked data array based on the name
  return checkedItemData.some((checkedItem) => checkedItem.name === item.name);
}
