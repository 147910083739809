import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

function VitalCriteriaPage({
  openVitalCriteria,
  setOpenVitalCriteria,
  saveButton,
  cancelButton,
  setPatientData,
  setEditPatient,
  patientId,
  vitData,
  setVitData,
}) {
  const [data, setData] = useState({
    bodyTemperature: "",
    pulseRate: "",
    bloodPressure: "",
    respirationRate: "",
    spO2: "",
    bloodSugarCount: "",
  });

  const handleInputChange = (fieldName, value) => {
    if (patientId) {
      setVitData((prevData) => ({
        ...prevData,
        data: {
          ...prevData.data,
          formData: {
            ...prevData?.data?.formData || {},
            [fieldName]: value,
          },
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const handleSave = () => {
    const vitalCriteriaFormData = {
      type: "VITAL_CRITERIA",
      formData: {
        bodyTemperature: data.bodyTemperature,
        pulseRate: data.pulseRate,
        bloodPressure: data.bloodPressure,
        respirationRate: data.respirationRate,
        spO2: data.spO2,
        bloodSugarCount: data.bloodSugarCount,
      },
    };

    setPatientData((prevPatientData) => ({
      ...prevPatientData,
      formData: [...prevPatientData.formData, vitalCriteriaFormData],
    }));

    setOpenVitalCriteria(false);
  };

  const handleEditDialog = () => {
    const updatedVitalCriteriaFormData = {
      type: "VITAL_CRITERIA",
      formData: {
        bodyTemperature: vitData?.data.formData.bodyTemperature,
        pulseRate: vitData?.data.formData.pulseRate,
        bloodPressure: vitData?.data.formData.bloodPressure,
        respirationRate: vitData?.data.formData.respirationRate,
        spO2: vitData?.data.formData.spO2,
        bloodSugarCount: vitData?.data.formData.bloodSugarCount,
      },
    };
  
    setEditPatient((prevPatientData) => {
      let newFormData;
  
      if (Array.isArray(prevPatientData.formData)) {
        newFormData = [...prevPatientData.formData, updatedVitalCriteriaFormData];
      } else {
        newFormData = [updatedVitalCriteriaFormData];
      }
  
      return {
        ...prevPatientData,
        formData: newFormData,
      };
    });
  
    setOpenVitalCriteria(false);
  };
  

  return (
    <>
      <Dialog
        open={openVitalCriteria}
        maxWidth="xs"
        fullWidth={true}
        scroll={"paper"}
        onClose={() => setOpenVitalCriteria(false)}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: "26px", fontWeight: 600 }}>
            {patientId ? "Edit Vital Criteria" : "Vital Criteria"}
          </Typography>
        </DialogTitle>
        <Divider sx={{ height: 2 }} />
        <DialogContent>
          <Grid container>
            <Grid md={12} mt={1}>
              <TextField
                fullWidth
                label="Body Temperature (Optional)"
                variant="outlined"
                value={
                  patientId
                    ? vitData?.data?.formData?.bodyTemperature
                    : data.bodyTemperature
                }
                onChange={(e) =>
                  handleInputChange("bodyTemperature", e.target.value)
                }
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                fullWidth
                label="Plus Rate (Optional)"
                variant="outlined"
                value={
                  patientId ? vitData?.data?.formData?.pulseRate : data.pulseRate
                }
                onChange={(e) => handleInputChange("pulseRate", e.target.value)}
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                fullWidth
                label="Blood Pressure (Optional)"
                variant="outlined"
                value={
                  patientId
                    ? vitData?.data?.formData?.bloodPressure
                    : data.bloodPressure
                }
                onChange={(e) =>
                  handleInputChange("bloodPressure", e.target.value)
                }
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                fullWidth
                label="Respiration Rate (Optional)"
                variant="outlined"
                value={
                  patientId
                    ? vitData?.data?.formData?.respirationRate
                    : data.respirationRate
                }
                onChange={(e) =>
                  handleInputChange("respirationRate", e.target.value)
                }
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                fullWidth
                label="SP 02 (Optional)"
                variant="outlined"
                value={patientId ? vitData?.data?.formData?.spO2 : data.spO2}
                onChange={(e) => handleInputChange("spO2", e.target.value)}
              />
            </Grid>
            <Grid md={12} mt={2}>
              <TextField
                fullWidth
                label="Blood Sugar Count (Optional)"
                variant="outlined"
                value={
                  patientId
                    ? vitData?.data?.formData?.bloodSugarCount
                    : data.bloodSugarCount
                }
                onChange={(e) =>
                  handleInputChange("bloodSugarCount", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-around"}>
            <Grid md={5}>
              <Button
                fullWidth
                variant="contained"
                sx={saveButton}
                // onClick={handleSave}
                onClick={patientId ? handleEditDialog : handleSave}
              >
                {patientId ? "Update" : "Save"}
              </Button>
            </Grid>
            <Grid md={5}>
              <Button
                fullWidth
                variant="outlined"
                sx={cancelButton}
                onClick={() => setOpenVitalCriteria(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default VitalCriteriaPage;
