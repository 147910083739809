import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// sale order list
export const getSaleOrderList = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder/list`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// delete sale order
export const deleteSaleOrder = async (saleListId) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder/${saleListId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
