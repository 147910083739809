import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { ClipLoader } from "react-spinners";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Component
import Header from "../common/Header/Header";
import medicinesGroup from "../Json/MedicienGroup.json";
import medicine from "../../components/Json/medicines.json";

import baseUrl from "../../constants/constant.json";
import axios from "axios";

import {
  deleteMedicine,
  getMedicineList,
  saveMedicines,
  updateMedicine,
} from "../../services/medicine/medicineServices";
import { getToken } from "../../utils/getToken";

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
  width: "300px",
}));

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  backgroundColor: "#2F7539",
  textAlign: "center",
  marginRight: 3,
  width: "120px",
  textTransform: "none",
  fontSize: "16px",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  width: "120px",
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
  fontSize: "16px",
};

const addIcon = {
  color: "white",
  fontSize: "22px",
  paddingLeft: "2px",
  paddingRight: "5px",
  textTransform: "none",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
  fontSize: "16px",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  alignItems: "center",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
  fontSize: "22px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const Medicines = () => {
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [medicineToDelete, setMedicineToDelete] = useState(null);
  const [editMedicine, setEditMedicine] = useState(null);
  const [validateError, setValidateError] = useState({});
  const [medicines, setMedicines] = useState({
    medicineId: "",
    groupName: "",
    itemName: "",
    notes: "",
    quantity: "",
    boxPack: "",
    unitRate: "",
    amount: "",
    discount: "",
    vat: "",
    adTax: "",
    cgst: "",
    sgst: "",
    igst: "",
    cess: "",
    stock: "",
    pRate: "",
    sRate: "",
    netAmount: "",
  });
  const [medicinesList, setMedicinesList] = useState([]);
  const [discountAmt, setDiscountAmt] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [adTax, setAdTax] = useState(0);
  const [cgstAmt, setCgstAmt] = useState(0);
  const [igstAmt, setIgstAmt] = useState(0);
  const [sgstAmt, setSgstAmt] = useState(0);
  const [cessAmt, setCessAmt] = useState(0);
  const [netAmt, setNetAmt] = useState(0);
  const [searchByName, setSearchByName] = useState("");

  // validating fields
  const validateFields = () => {
    const errors = {};
    if (!medicines.medicineId) {
      errors.medicineId = "Medicine Id is required";
    }
    if (!medicines.groupName) {
      errors.groupName = "Group Name is required";
    }
    if (!medicines.itemName) {
      errors.itemName = "Item Name is required";
    }
    if (!medicines.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (!medicines.boxPack) {
      errors.boxPack = "Box/Pack is required";
    }
    if (!medicines.unitRate) {
      errors.unitRate = "UnitRate is required";
    }
    if (!medicines.amount) {
      errors.amount = "Amount is required";
    }

    setValidateError(errors);
    return Object.keys(errors).length === 0;
  };

  // get medicines list
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/medicine/list?name=${searchByName}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setMedicinesList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [searchByName]);

  console.log("medicinesList", medicinesList);

  const handleSearchInput = (event) => {
    setSearchByName(event.target.value);
  };

  // create a medicines
  const handleSaveMedicine = async () => {
    setSaveButtonLoader(true);
    if (validateFields()) {
      try {
        const response = await saveMedicines(medicines);
        console.log(response);

        setOpen(false);
        setSaveButtonLoader(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // delete medicines
  const handleDeleteOpen = (medicineId) => {
    setMedicineToDelete(medicineId);
    setDeleteOpen(true);
  };

  const handleDeleteData = async (medicineId) => {
    try {
      await deleteMedicine(medicineId);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // const addMedicines = (event) => {
  //   const { name, value } = event.target;

  //   const updateMedicine = {
  //     ...medicines,
  //     [name]: Number(value),
  //   };

  //   if (
  //     [
  //       "quantity",
  //       "box",
  //       "unitRate",
  //       "amount",
  //       "discount",
  //       "vat",
  //       "adTax",
  //       "cgst",
  //       "igst",
  //       "sgst",
  //       "cess",
  //     ].includes(name)
  //   ) {
  //     const discountedAmt =
  //       (updateMedicine.amount * updateMedicine.discount) / 100;
  //     const totalDiscount = updateMedicine.amount - discountedAmt;
  //     const vatAmt = (totalDiscount * updateMedicine.vat) / 100;
  //     const adTax = (totalDiscount * updateMedicine.adTax) / 100;
  //     const cgst = (totalDiscount * updateMedicine.cgst) / 100;
  //     const igst = (totalDiscount * updateMedicine.igst) / 100;
  //     const sgst = (totalDiscount * updateMedicine.sgst) / 100;
  //     const cess = (totalDiscount * updateMedicine.cess) / 100;

  //     const netAmt = totalDiscount + vatAmt + adTax + cgst + igst + sgst + cess;
  //     updateMedicine.netAmount = netAmt;
  //     setMedicines(updateMedicine);

  //     setDiscountAmt(discountedAmt);
  //     setVatAmt(vatAmt);
  //     setAdTax(adTax);
  //     setCgstAmt(cgst);
  //     setSgstAmt(sgst);
  //     setIgstAmt(igst);
  //     setCessAmt(cess);
  //     setNetAmt(netAmt);
  //   } else {
  //     setMedicines(updateMedicine);
  //   }
  // };

  const addMedicines = (event) => {
    const { name, value } = event.target;
  
    const updateMedicine = {
      ...medicines,
      [name]: name === "itemName" ? value : Number(value),
    };
  
    if (
      [
        "quantity",
        "box",
        "unitRate",
        "amount",
        "discount",
        "vat",
        "adTax",
        "cgst",
        "igst",
        "sgst",
        "cess",
      ].includes(name)
    ) {
      const discountedAmt =
        (updateMedicine.amount * updateMedicine.discount) / 100;
      const totalDiscount = updateMedicine.amount - discountedAmt;
      const vatAmt = (totalDiscount * updateMedicine.vat) / 100;
      const adTax = (totalDiscount * updateMedicine.adTax) / 100;
      const cgst = (totalDiscount * updateMedicine.cgst) / 100;
      const igst = (totalDiscount * updateMedicine.igst) / 100;
      const sgst = (totalDiscount * updateMedicine.sgst) / 100;
      const cess = (totalDiscount * updateMedicine.cess) / 100;
  
      const netAmt =
        totalDiscount + vatAmt + adTax + cgst + igst + sgst + cess;
      updateMedicine.netAmount = netAmt;
      setMedicines(updateMedicine);
  
      setDiscountAmt(discountedAmt);
      setVatAmt(vatAmt);
      setAdTax(adTax);
      setCgstAmt(cgst);
      setSgstAmt(sgst);
      setIgstAmt(igst);
      setCessAmt(cess);
      setNetAmt(netAmt);
    } else {
      setMedicines(updateMedicine);
    }
  };
  

  const handleAutoComplete = (key) => (event, newValue) => {
    if (editMedicine) {
      setEditMedicine((prevEditMedicine) => ({
        ...prevEditMedicine,
        [key]: newValue ? newValue.name : "",
      }));
    } else {
      setMedicines((medicines) => ({
        ...medicines,
        [key]: newValue ? newValue.name : "",
      }));
    }
  };

  const handleEditClick = (ele) => {
    setOpen(true);
    const itemToEdit = medicinesList.find((medicine) => medicine._id === ele);
    console.log(itemToEdit);
    setEditMedicine(itemToEdit);

    const amount = parseFloat(itemToEdit.amount || 0);
    const discount = parseFloat(itemToEdit.discount || 0);
    const vat = parseFloat(itemToEdit.vat || 0);
    const adTax = parseFloat(itemToEdit.adTax || 0);
    const cgst = parseFloat(itemToEdit.cgst || 0);
    const igst = parseFloat(itemToEdit.igst || 0);
    const sgst = parseFloat(itemToEdit.sgst || 0);
    const cess = parseFloat(itemToEdit.cess || 0);

    const discountedAmt = (amount * discount) / 100;
    const totalDiscount = amount - discountedAmt;
    const vatAmt = (totalDiscount * vat) / 100;
    const adTaxAmt = (totalDiscount * adTax) / 100;
    const cgstAmt = (totalDiscount * cgst) / 100;
    const igstAmt = (totalDiscount * igst) / 100;
    const sgstAmt = (totalDiscount * sgst) / 100;
    const cessAmt = (totalDiscount * cess) / 100;
    const netAmt =
      totalDiscount + vatAmt + adTaxAmt + cgstAmt + igstAmt + sgstAmt + cessAmt;

    setDiscountAmt(discountedAmt);
    setVatAmt(vatAmt);
    setAdTax(adTaxAmt);
    setCgstAmt(cgstAmt);
    setSgstAmt(sgstAmt);
    setIgstAmt(igstAmt);
    setCessAmt(cessAmt);
    setNetAmt(netAmt);
    setEditMedicine(itemToEdit);
    setOpen(true);
  };

  const handleEditInput = (event) => {
    const { name, value } = event.target;

    setEditMedicine((prevEditMedicine) => {
      const updatedMedicine = {
        ...prevEditMedicine,
        [name]: name === "itemName" ? value : Number(value)
      };

      if (
        [
          "quantity",
          "box",
          "unitRate",
          "amount",
          "discount",
          "vat",
          "adTax",
          "cgst",
          "igst",
          "sgst",
          "cess",
        ].includes(name)
      ) {
        const amount = parseFloat(updatedMedicine.amount || 0);
        const discount = parseFloat(updatedMedicine.discount || 0);
        const vat = parseFloat(updatedMedicine.vat || 0);
        const adTax = parseFloat(updatedMedicine.adTax || 0);
        const cgst = parseFloat(updatedMedicine.cgst || 0);
        const igst = parseFloat(updatedMedicine.igst || 0);
        const sgst = parseFloat(updatedMedicine.sgst || 0);
        const cess = parseFloat(updatedMedicine.cess || 0);

        const totalDiscount = (amount * discount) / 100;
        const discountedAmt = amount - totalDiscount;
        const vatAmt =
          (discountedAmt + adTax + cgst + sgst + igst + cess) * (vat / 100);
        const adTaxAmt =
          (discountedAmt + vat + cgst + sgst + igst + cess) * (adTax / 100);
        const cgstAmt =
          (discountedAmt + vat + adTax + sgst + igst + cess) * (cgst / 100);
        const sgstAmt =
          (discountedAmt + vat + adTax + cgst + igst + cess) * (sgst / 100);
        const igstAmt =
          (discountedAmt + vat + adTax + cgst + sgst + cess) * (igst / 100);
        const cessAmt =
          (discountedAmt + vat + adTax + cgst + sgst + igst) * (cess / 100);

        const netAmount =
          discountedAmt +
          vatAmt +
          adTaxAmt +
          cgstAmt +
          sgstAmt +
          igstAmt +
          cessAmt;

        setDiscountAmt(totalDiscount);
        setVatAmt(vatAmt);
        setAdTax(adTaxAmt);
        setCgstAmt(cgstAmt);
        setSgstAmt(sgstAmt);
        setIgstAmt(igstAmt);
        setCessAmt(cessAmt);
        setNetAmt(netAmount);

        updatedMedicine.netAmount = netAmount;
      }

      return updatedMedicine;
    });
  };
  console.log("editMedicine", editMedicine);
  // edit medicines
  const handleEditMedicine = async () => {
    setSaveButtonLoader(true);
    try {
      const updateData = await updateMedicine(editMedicine);
      console.log("updateData", updateData);
      if (updateData) {
        window.location.reload();
        setOpen(false);
        setSaveButtonLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setMedicines({
      medicineId: "",
      groupName: "",
      itemName: "",
      notes: "",
      quantity: "",
      boxPack: "",
      unitRate: "",
      amount: "",
      discount: "",
      vat: "",
      adTax: "",
      cgst: "",
      sgst: "",
      igst: "",
      cess: "",
      stock: "",
      pRate: "",
      sRate: "",
      netAmount: "",
    });
    setDiscountAmt(0);
    setVatAmt(0);
    setAdTax(0);
    setCgstAmt(0);
    setSgstAmt(0);
    setIgstAmt(0);
    setCessAmt(0);
    setNetAmt(0);
  };

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box padding={3}>
            <Breadcrumbs>
              <Link style={linkstyle} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linkstyle} to="/medicines">
                Medicines
              </Link>
            </Breadcrumbs>
            <Box marginBottom={2}>
              <Typography sx={typographyContent}>Medicines</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                <Search sx={{ mr: 2 }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search by Name"
                    inputProps={{ "aria-label": "Search by Name" }}
                    value={searchByName}
                    onChange={handleSearchInput}
                  />
                </Search>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search by Id "
                    inputProps={{
                      "aria-label": "Search by Id ",
                    }}
                  />
                </Search>
              </Box>
              <Box display="flex">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="success"
                >
                  <AddCircleOutlineIcon sx={addIcon} />
                  <Typography sx={patientButton}>Add Medicine</Typography>
                </Button>
              </Box>
            </Box>
            <Divider
              sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
            />
            <Box mt="30px" mb="20px">
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Medicine Id</TableCell>
                      <TableCell align="center">Medicine Name</TableCell>
                      <TableCell align="center">Group Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Box/Pack</TableCell>
                      <TableCell align="center">Unit Rate</TableCell>
                      <TableCell align="center">Discount</TableCell>
                      <TableCell align="center">Stock</TableCell>
                      <TableCell align="center">Total Price</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      height="330px"
                      top="35%"
                      left="50%"
                    >
                      <ClipLoader color="#2F7539" />
                    </Box>
                  ) : (
                    <TableBody>
                      {medicinesList.map((item) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {item.medicineId || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {item.itemName}
                            </TableCell>
                            <TableCell align="center">
                              {item.groupName}
                            </TableCell>
                            <TableCell align="center">
                              {item.quantity}
                            </TableCell>
                            <TableCell align="center">{item.boxPack}</TableCell>
                            <TableCell align="center">
                              {item.discount}
                            </TableCell>
                            <TableCell align="center">
                              {item.unitRate}
                            </TableCell>
                            <TableCell align="center">{item.stock}</TableCell>
                            <TableCell align="center">{item.amount}</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                onClick={() => handleEditClick(item._id)}
                                disableRipple
                                sx={hoverEffect}
                              >
                                <EditIcon sx={editButton} />
                              </Button>
                              <Button
                                onClick={() => handleDeleteOpen(item._id)}
                                disableRipple
                                sx={hoverEffect}
                              >
                                <DeleteIcon sx={deleteButton} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open || editMedicine !== null}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ p: "10px", alignItems: "center" }}
        >
          <Typography
            sx={{ fontSize: "18px", paddingLeft: "25px", fontWeight: "700" }}
          >
            {/* Add Medicines */}
            {editMedicine ? "Edit Medicines" : "Add Medicines"}
          </Typography>
          <IconButton
            onClick={() => {
              setEditMedicine(null);
              setOpen(false);
            }}
          >
            <ClearIcon sx={clearButton} />
          </IconButton>
        </Box>
        <Divider sx={{ marginY: "2px", color: "black" }} />
        <DialogContent>
          <Box>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  sx={{ width: "100%" }}
                  name="medicineId"
                  placeholder="Medicines Id"
                  label="Medicines Id"
                  size="small"
                  value={editMedicine?.medicineId || medicines.medicineId}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.medicineId}
                  helperText={validateError.medicineId}
                />
              </Grid>
              <Grid item md={12} mt={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={medicinesGroup}
                  size="small"
                  onChange={handleAutoComplete("groupName")}
                  defaultValue={{ name: editMedicine?.groupName || "" }}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="groupName"
                      label="Group Name"
                      error={!!validateError.groupName}
                      helperText={validateError.groupName || ""}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} mt={2}>
                <TextField
                  sx={{ width: "100%" }}
                  name="itemName"
                  placeholder="Medicines Name"
                  label="Medicines Name"
                  size="small"
                  value={editMedicine?.itemName || medicines.itemName}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.itemName}
                  helperText={validateError.itemName}
                />
              </Grid>
              {/* <Grid item md={12} mt={2}>
                <TextField
                  sx={{ width: "100%" }}
                  name="notes"
                  placeholder="Notes"
                  label="Notes"
                  size="small"
                  value={editMedicine?.notes || medicines.notes}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
              </Grid> */}
            </Grid>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Quantity"
                  name="quantity"
                  label="Quantity"
                  size="small"
                  value={editMedicine?.quantity || medicines.quantity}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.quantity}
                  helperText={validateError.quantity}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Box / Pack"
                  label="Box / Pack"
                  name="boxPack"
                  size="small"
                  value={editMedicine?.boxPack || medicines.boxPack}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.boxPack}
                  helperText={validateError.boxPack}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Unit Rate"
                  label="Unit Rate"
                  name="unitRate"
                  size="small"
                  value={editMedicine?.unitRate || medicines.unitRate}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.unitRate}
                  helperText={validateError.unitRate}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Amount"
                  name="amount"
                  label="Amount"
                  size="small"
                  value={editMedicine?.amount || medicines.amount}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                  error={!!validateError.amount}
                  helperText={validateError.amount}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Discount %"
                  label="Discount %"
                  size="small"
                  name="discount"
                  value={editMedicine?.discount || medicines.discount}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Disc. Amt."
                  label="Disc. Amt."
                  size="small"
                  defaultValue="0.00"
                  value={discountAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="VAT %"
                  label="VAT %"
                  size="small"
                  name="vat"
                  value={editMedicine?.vat || medicines.vat}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="VAT Amt."
                  label="VAT Amt."
                  size="small"
                  defaultValue="0.00"
                  value={vatAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Ad. Tax %"
                  label="Ad. Tax %"
                  name="adTax"
                  size="small"
                  value={editMedicine?.adTax || medicines.adTax}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Ad. Tax Amt."
                  label="Ad. Tax Amt."
                  size="small"
                  defaultValue="0.00"
                  value={adTax.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="CGST %"
                  label="CGST %"
                  name="cgst"
                  size="small"
                  value={editMedicine?.cgst || medicines.cgst}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="CGST Amt."
                  label="CGST Amt."
                  size="small"
                  defaultValue="0.00"
                  value={cgstAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="SGST %"
                  label="SGST %"
                  size="small"
                  name="sgst"
                  value={editMedicine?.sgst || medicines.sgst}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="SGST Amt."
                  label="SGST Amt."
                  size="small"
                  defaultValue="0.00"
                  value={sgstAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="IGST %"
                  label="IGST %"
                  size="small"
                  name="igst"
                  value={editMedicine?.igst || medicines.igst}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="IGST Amt."
                  label="IGST Amt."
                  size="small"
                  defaultValue="0.00"
                  value={igstAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Cess %"
                  label="Cess %"
                  name="cess"
                  size="small"
                  value={editMedicine?.cess || medicines.cess}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Cess Amt."
                  label="Cess Amt."
                  size="small"
                  defaultValue="0.00"
                  value={cessAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Stock Qty."
                  label="Stock Qty."
                  name="stock"
                  size="small"
                  value={editMedicine?.stock || medicines.stock}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="Net Amount"
                  label="Net Amount"
                  size="small"
                  defaultValue="0.00"
                  value={netAmt.toFixed(2)}
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="P. Rate"
                  label="P. Rate"
                  size="small"
                  name="pRate"
                  value={editMedicine?.pRate || medicines.pRate}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
                <TextField
                  sx={{ mt: "16px" }}
                  placeholder="S. Rate"
                  label="S. Rate"
                  size="small"
                  name="sRate"
                  value={editMedicine?.sRate || medicines.sRate}
                  onChange={editMedicine ? handleEditInput : addMedicines}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack pt={2} direction="row">
            <Button
              onClick={editMedicine ? handleEditMedicine : handleSaveMedicine}
              sx={saveButton}
              variant="contained"
            >
              {saveButtonLoader ? (
                <CircularProgress size={24} color="inherit" />
              ) : editMedicine ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={() => {
                setEditMedicine(null);
                setOpen(false);
              }}
              sx={cancelButton}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* delete medicine data */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => handleDeleteData(medicineToDelete)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Medicines;
