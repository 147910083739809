import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Card,
  Button,
  FormControlLabel,
  TextField,
  Typography,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import HttpsIcon from "@mui/icons-material/Https";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import backgroundImage from "../../assets/Images/AdobeStock_245776292_0-scaled.jpeg";
import { ClipLoader } from "react-spinners";

// json file
import baseUrl from "../../constants/constant.json";
import { useNavigate } from "react-router-dom";

const mainCard = {
  position: "absolute",
  color: "white",
  width: "18%",
  left: "15%",
  top: "25%",
  padding: "20px",
  borderRadius: "15px",
};

const loginCard = {
  fontWeight: 500,
  color: "green",
};

const loginField = {
  marginTop: "20px",
  width: "100%",
};

const signIn = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  backgroundColor: "#2F7539",
  width: "250px",
  height: "40px",
  borderRadius: 1,
  textAlign: "center",
  fontSize: 18,
  marginTop: "10px",
  alignItems: "center",
};

const spanSignIn = {
  fontSize: "18px",
  fontWeight: "500",
};

const Login = () => {
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [userName, setUserName] = useState();
  const [userPassword, setUserPassword] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  if (token) {
    if (user === "admin") {
      navigate("/patientlist");
    } else {
      navigate("/dashboard");
    }
    localStorage.setItem("token", token);
    localStorage.setItem("user", user);
  }


  const handleSubmitData = (ele) => {
    ele.preventDefault();
    setLoading(true);
    let Data = JSON.stringify({
      username: userName,
      password: userPassword,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };
    axios
      .request(config)
      .then((response) => {
        setToken(response.data.data);
        setUser(response.data.user);
        setSuccessSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.status === false) {
          setSnackbarOpen(true);
        }
        setLoading(false);
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          please Enter Valid Username and Password
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSuccessSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Log In Success
        </Alert>
      </Snackbar>
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          height: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Card sx={mainCard}>
        <Typography sx={loginCard} variant="h3">
          LOGIN
        </Typography>
        <Typography sx={loginCard}>Sign in to start your session</Typography>
        <form onSubmit={handleSubmitData}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              sx={loginField}
              placeholder="Username"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon sx={{ color: "black", opacity: "0.7" }} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              onChange={(value) => setUserName(value.target.value)}
            />
          </FormControl>
          <FormControl sx={loginField} variant="standard">
            <Input
              id="standard-adornment-password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              startAdornment={
                <HttpsIcon
                  position="start"
                  sx={{ color: "black", marginRight: 1, opacity: "0.7" }}
                />
              }
              endAdornment={
                <>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </>
              }
              onChange={(value) => setUserPassword(value.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              label="Remember Me"
              control={<Checkbox />}
              sx={{ color: "black", paddingTop: "15px" }}
            />
          </FormControl>
          <FormControl sx={{ display: "flex", alignItems: "center" }}>
            <Button sx={signIn} type="submit" variant="contained">
              {loading ? (
                <ClipLoader color="#FFFFFF" />
              ) : (
                <span style={spanSignIn}>Sign in</span>
              )}
            </Button>
          </FormControl>
        </form>
      </Card>
    </Box>
  );
};

export default Login;
