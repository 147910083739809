import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Breadcrumbs,
  DialogActions,
  Stack,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ClipLoader } from "react-spinners";

// Components
import Header from "../common/Header/Header";
import dayjs from "dayjs";
import {
  deleteAppointment,
  editAppointmentData,
  getAppointmentsList,
  saveAppointment,
} from "../../services/appointment/appointmentServices";

// css
const mainHeading = {
  color: "#424242",
  fontSize: "30px",
  fontWeight: "700",
  marginBottom: "10px",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const addIcon = {
  color: "white",
  fontSize: "22px",
  paddingLeft: "2px",
  paddingRight: "5px",
  textTransform: "none",
};

const patientButton = {
  color: "white",
  textDecoration: "none",
  listStyle: "none",
  textTransform: "none",
};

const listStyle = {
  textDecoration: "none",
  textTransform: "none",
};

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const dialogHeading = {
  color: "#424242",
  fontSize: "20px",
  fontWeight: "700",
};

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
};

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  width: "120px",
  marginRight: "20px",
  backgroundColor: "#2F7539",
  textTransform: "none",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  alignItems: "center",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const CancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  width: "120px",
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const Appointment = (props) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [listAppointment, setListAppointment] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [editAppointment, setEditAppointment] = useState(null);
  const [appointment, setAppointment] = useState({
    name: "",
    mobileNumber: "",
    time: "",
    selectDate: "",
    reason: "",
  });

  const handleAppointment = (ele) => {
    setAppointment({ ...appointment, [ele.target.name]: ele.target.value });
    console.log(appointment);
  };

  const handleDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("YYYY-MM-DD");

    setAppointment((prevState) => ({
      ...prevState,
      [type]: formattedDate,
    }));
  };

  const handleTimePicker = (time) => {
    const currentTime = new Date(time.$d);

    setAppointment((prevState) => ({
      ...prevState,
      time: currentTime,
    }));
  };

  const handleSave = () => {
    saveAppointment(appointment)
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(false);
  };

  useEffect(() => {
    getAppointmentsList()
      .then((appointments) => {
        setListAppointment(appointments);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleDeleteOpen = (itemId) => {
    setAppointmentToDelete(itemId);
    setDeleteOpen(true);
  };

  const handleDelete = (appointmentId) => {
    if (appointmentToDelete) {
      deleteAppointment(appointmentId)
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleEditClick = (item) => {
    setEditAppointment(item);
    setOpen(true);
  };

  const handleEditInput = (event) => {
    const { name, value } = event.target;
    setEditAppointment((prevEditItem) => ({
      ...prevEditItem,
      [name]: value,
    }));
  };

  const handleEditAppointmentData = () => {
    if (editAppointment) {
      editAppointmentData(editAppointment)
        .then((response) => {
          console.log(response.data);
          window.location.reload();
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box padding="20px">
            <Box>
              <Box>
                <Breadcrumbs>
                  <Link style={linkstyle} to="/dashboard">
                    Dashboard
                  </Link>
                  <Link style={linkstyle} to="/appointment">
                    Appointment
                  </Link>
                </Breadcrumbs>
                <Typography sx={mainHeading}>Appointment</Typography>
              </Box>
              <Divider
                sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
              />
              <Box display="flex">
                <Grid container>
                  <Grid md={3} mr={2}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search by Name"
                        inputProps={{ "aria-label": "Search by Name" }}
                      />
                    </Search>
                  </Grid>
                  <Grid md={3}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search by ID"
                        inputProps={{ "aria-label": "Search by ID" }}
                      />
                    </Search>
                  </Grid>
                </Grid>
                <Link style={listStyle}>
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setOpen(true)}
                    >
                      <AddCircleOutlineIcon sx={addIcon} />
                      <Typography sx={patientButton}>Appointment</Typography>
                    </Button>
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box marginTop="40px">
              <TableContainer sx={{ maxHeight: 380 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Mobile No</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Reason</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      height="330px"
                      top="35%"
                      left="50%"
                    >
                      <ClipLoader color="#2F7539" />
                    </Box>
                  ) : (
                    <TableBody>
                      {listAppointment.map((item) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.name}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {item.mobileNumber}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {item.time
                              ? new Date(item.time).toLocaleTimeString()
                              : ""}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {moment(item.selectDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {item.reason}
                          </TableCell>
                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              onClick={() => handleEditClick(item)}
                              disableRipple
                              sx={hoverEffect}
                            >
                              <EditIcon sx={editButton} />
                            </Button>
                            <Button
                              onClick={() => handleDeleteOpen(item._id)}
                              disableRipple
                              sx={hoverEffect}
                            >
                              <DeleteIcon sx={deleteButton} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open || editAppointment !== null}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={dialogHeading}>
              {editAppointment ? "Edit Appointment" : "Add Appointment"}
            </Typography>
            <IconButton
              onClick={() => {
                setEditAppointment(null);
                setOpen(false);
              }}
            >
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "10px" }}
          />
          <Box>
            <Box>
              <Grid container>
                <Grid md={12} mt="8px">
                  <TextField
                    sx={{ width: "100%" }}
                    placeholder="Enter Name"
                    name="name"
                    label="Enter Name"
                    value={editAppointment?.name || appointment.name}
                    onChange={
                      editAppointment ? handleEditInput : handleAppointment
                    }
                  />
                </Grid>
                <Grid md={6} mt={2}>
                  <TextField
                    sx={{ width: "96%" }}
                    placeholder="Enter Mobile Number"
                    name="mobileNumber"
                    label="Enter Mobile Number"
                    value={
                      editAppointment?.mobileNumber || appointment.mobileNumber
                    }
                    onChange={
                      editAppointment ? handleEditInput : handleAppointment
                    }
                  />
                </Grid>
                <Grid md={6} mt={2}>
                  <TextField
                    sx={{ width: "100%" }}
                    placeholder="Enter Reason"
                    name="reason"
                    label="Enter Reason"
                    value={editAppointment?.reason || appointment.reason}
                    onChange={
                      editAppointment ? handleEditInput : handleAppointment
                    }
                  />
                </Grid>
                <Grid md={6} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <TimePicker
                        defaultValue={
                          editAppointment ? dayjs(editAppointment.time) : null
                        }
                        onChange={(newTime) => {
                          const formattedTime = newTime ? newTime : null;
                          if (editAppointment) {
                            setEditAppointment((prevState) => ({
                              ...prevState,
                              time: formattedTime
                                ? formattedTime.toDate()
                                : null,
                            }));
                          } else {
                            handleTimePicker(formattedTime);
                          }
                        }}
                        sx={{ width: "96%", mt: 2 }}
                        label="Select Time"
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid md={6} mt={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={
                          editAppointment
                            ? dayjs(
                                moment(editAppointment?.selectDate).format(
                                  "YYYY-MM-DD"
                                )
                              )
                            : null
                        }
                        onChange={
                          editAppointment
                            ? (newDate) =>
                                handleEditInput({
                                  target: {
                                    name: "selectDate",
                                    value: newDate
                                      ? newDate.format("YYYY-MM-DD")
                                      : "",
                                  },
                                })
                            : (newDate) =>
                                handleDatePicker("selectDate", newDate)
                        }
                        sx={{ width: "100%" }}
                        label="Select Date"
                        format="DD-MM-YYYY"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <DialogActions>
                <Stack pt={2} direction="row">
                  <Button
                    variant="contained"
                    sx={saveButton}
                    onClick={
                      editAppointment ? handleEditAppointmentData : handleSave
                    }
                  >
                    {editAppointment ? "Update" : "Save"}
                  </Button>
                  <Button
                    sx={CancelButton}
                    onClick={() => {
                      setEditAppointment(null);
                      setOpen(false);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Stack>
              </DialogActions>
            </Box>
          </Box>
        </DialogTitle>
      </Dialog>

      {/* delete Appointment data */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => handleDelete(appointmentToDelete)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Appointment;
