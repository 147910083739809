import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import prakrutiData from "./findPrakruti.json";

const initialDataJson = Array.from({ length: prakrutiData.length }, () => ({
  questionId: -1,
  type: 'vata'
}));

function PatientPrakrutiPage({
  open,
  dialogHeading,
  setOpen,
  clearButton,
  dialogBoxStyle,
  vataCount,
  pittaCount,
  kaphaCount,
  setVataCount,
  setPittaCount,
  setKaphaCount,
  prakrutiData,
  boxStyle,
  boxHeading,
  labelStyle,
  handleRadioChange,
  boxContent,
  boxContentMain,
  saveButton,
  handlePrakruti,
  cancelButton,
  patientPrakrutiData,
  setPatientPrakrutiData,
  patientId,
  setEditPatient,
  editPatient,
}) {
  const [prakrutiFormData, setPrakrutiFormData] = useState([]);
  useEffect(() => {

    if (patientPrakrutiData?.data?.formData) {
      setPrakrutiFormData(patientPrakrutiData?.data?.formData);
    }
  }, [patientPrakrutiData]);

  const editHandleRadioChange = (id, type, check) => {
    
    setPrakrutiFormData((prevFormData) => {
      if(check){
      if (prevFormData.length !== 0) {
        // Check if questionId already exists
        const index = prevFormData.findIndex((ele) => ele.questionId === id);
        if (index !== -1) {
          // If questionId exists, update its type
          return prevFormData.map((ele, idx) =>
            idx === index ? { ...ele, type: type } : ele
          );
        } else {
          // If questionId doesn't exist, add new entry
          return [...prevFormData, { questionId: id, type: type }];
        }
      } else {
        // If prevFormData is empty, initialize it with new entry
        return [{ questionId: id, type: type }];
      }
    } else {
      return prevFormData.filter((ele) => ele.questionId !== id);
    }
    });
  };

  useEffect(() => {
    // Calculate the total counts for each type
    let vataTotal = 0;
    let pittaTotal = 0;
    let kaphaTotal = 0;

    prakrutiFormData.forEach((item) => {
      if (item.type === 'vata')
        vataTotal += 1
      if (item.type === 'pitta')
        pittaTotal += 1;
      if (item.type === 'kapha')
        kaphaTotal += 1;
    });

    // Set the counts in the state
    setVataCount(vataTotal);
    setPittaCount(pittaTotal);
    setKaphaCount(kaphaTotal);
  }, [prakrutiFormData]);

  const editPrakruti = () => {
    let maxField = "vata";
      let maxCount = vataCount;

      if (pittaCount > maxCount) {
        maxField = "pitta";
        maxCount = pittaCount;
      }

      if (kaphaCount > maxCount) {
        maxField = "kapha";
        maxCount = kaphaCount;
      }

      localStorage.setItem("maxPrakrutiField", maxField);

    setEditPatient((prevData) => {
      const existingFormData = prevData.formData || [];

      const prakrutiIndex = existingFormData.findIndex(
        (ele) => ele.type === "PATIENT_PRAKRUTI"
      );

      if (prakrutiIndex !== -1) {
        existingFormData[prakrutiIndex] = {
          type: "PATIENT_PRAKRUTI",
          formData: prakrutiFormData,
        };
      } else {
        existingFormData.push({
          type: "PATIENT_PRAKRUTI",
          formData: prakrutiFormData,
        });
      }

      return {
        ...prevData,
        formData: existingFormData,
      };
    });

    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xl" fullWidth={true} scroll={"paper"}>
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography sx={dialogHeading}>
              {patientId ? "Edit Find Prakruti" : "Find Prakruti"}
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
          <Box display="flex">
            <Box display="flex" marginY="30px" marginRight="10px">
              <Typography sx={dialogBoxStyle} borderRight="1px solid black">
                {vataCount}:VATA
              </Typography>
            </Box>
            <Box display="flex" marginY="30px" marginRight="10px">
              <Typography sx={dialogBoxStyle} borderRight="1px solid black">
                {pittaCount}:PITTA
              </Typography>
            </Box>
            <Box display="flex" marginY="30px" marginRight="10px">
              <Typography sx={dialogBoxStyle} borderRight="1px solid black">
                {kaphaCount}:KAPHA
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />
        </DialogTitle>
        <DialogContent>
          <Box>
            {prakrutiData.map((ele) => {
              return (
                <>
                  <Box sx={boxStyle}>
                    <Typography sx={boxHeading}>{ele.name}</Typography>
                    <RadioGroup>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          sx={labelStyle}
                          value="vata"
                          control={<Radio />}
                          onChange={(e) => {
                            if (patientId) {
                              editHandleRadioChange(
                                ele.id,
                                "vata",
                                e.target.checked
                              );
                            } else {
                              handleRadioChange(
                                ele.id,
                                "vata",
                                e.target.checked
                              );
                            }
                          }}
                          checked={
                            patientId &&
                            prakrutiFormData.find(
                              (e) =>
                                e.questionId === ele.id && e.type === "vata"
                            )
                          }
                        />
                        <Typography sx={boxContent}>Vata </Typography>
                        <Typography sx={boxContentMain}>{ele.vata}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          sx={labelStyle}
                          value="Pitta"
                          control={<Radio />}
                          onChange={(e) => {
                            if (patientId) {
                              editHandleRadioChange(
                                ele.id,
                                "pitta",
                                e.target.checked
                              );
                            } else {
                              handleRadioChange(
                                ele.id,
                                "pitta",
                                e.target.checked
                              );
                            }
                          }}
                          checked={
                            patientId &&
                            prakrutiFormData.find(
                              (e) =>
                                e.questionId === ele.id && e.type === "pitta"
                            )
                          }
                        />
                        <Typography sx={boxContent}>Pitta</Typography>
                        <Typography sx={boxContentMain}>{ele.pitta}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          sx={labelStyle}
                          value="kapha"
                          control={<Radio />}
                          onChange={(e) => {
                            if (patientId) {
                              editHandleRadioChange(
                                ele.id,
                                "kapha",
                                e.target.checked
                              );
                            } else {
                              handleRadioChange(
                                ele.id,
                                "kapha",
                                e.target.checked
                              );
                            }
                          }}
                          checked={
                            patientId &&
                            prakrutiFormData.find(
                              (e) =>
                                e.questionId === ele.id && e.type === "kapha"
                            )
                          }
                        />
                        <Typography sx={boxContent}>Kapha </Typography>
                        <Typography sx={boxContentMain}>{ele.kapha}</Typography>
                      </Box>
                    </RadioGroup>
                  </Box>
                </>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" margin="0px">
            <Button
              sx={saveButton}
              variant="contained"
              // onClick={handlePrakruti}
              onClick={patientId ? editPrakruti : handlePrakruti}
            >
              {patientId ? "Update" : "Save"}
            </Button>
            <Button
              sx={cancelButton}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PatientPrakrutiPage;
