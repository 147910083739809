import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// get diagnosis
export const fetchDiagnosisDetails = async (diagnosisId) => {
  try {
    if (diagnosisId) {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/diagnosis/${diagnosisId}`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const response = await axios.request(config);
      return response.data.data;
    }
    return null;
  } catch (error) {
    if (error.response && error.response.data === "Invalid Token") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

// create a new diagnosis
export const saveDiagnosis = async (diagnosisData, medicineList) => {
  try {
    const dataToSend = {
      ...diagnosisData,
      medicine: medicineList,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/diagnosis`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// edit diagnosis
export const updateDiagnosis = async (diagnosisId, updatedData) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/diagnosis/${diagnosisId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: updatedData,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
