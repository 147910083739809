import React from "react";
import { Box, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";

// Components
import Sidebar from "../Sidebar/Sidebar";

const drawerTop = {
  position: "fixed",
  height: "790px",
  width: "100%",
  backgroundColor: "#2F7539",
};

const drawerBottom = {
  position: "fixed",
  top: 292,
  height: "100%",
  width: "100%",
  backgroundColor: "#30373E",
};

const drawerIcon = {
  color: "#FFF",
  position: "absolute",
  left: "91%",
  top: "13px",
};

const drawerExit = {
  color: "#FFF",
  position: "absolute",
  left: "93%",
  top: "13px",
};

function Header() {
  function checkAutoLogout() {
    const twelveHours = 12 * 60 * 60 * 1000; // 3 hours in milliseconds
    const loginTime = parseInt(localStorage.getItem("loginTimestamp"));
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - loginTime;

    if (elapsedTime >= twelveHours) {
      // Auto logout the user here.
      logoutUser();
    }
  }
  setInterval(checkAutoLogout, 60000);

  function logoutUser() {
    // Clear the login timestamp from localStorage.
    localStorage.clear();

    // Redirect the user to the login page.
    window.location.href = "/";
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <Box sx={drawerTop}>
        <Button sx={drawerIcon}>
          <NotificationsIcon />
        </Button>
        <Button sx={drawerExit} onClick={handleLogout}>
          <LogoutIcon />
        </Button>
      </Box>
      <Box sx={drawerBottom}></Box>
      <Sidebar />
    </>
  );
}

export default Header;
