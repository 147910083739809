import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider, Stack } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import MedicationIcon from "@mui/icons-material/Medication";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Line } from "react-chartjs-2";

// Component
import Header from "../common/Header/Header";

// Json
import ChartData from "./chartData.json";
import baseUrl from "../../constants/constant.json";

import {
  Chart as chartJs,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CountUp from 'react-countup';
import { getToken } from "../../utils/getToken";

chartJs.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement
);

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "auto",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const typographyApp = {
  display: "flex",
  color: "#6C757D",
  fontSize: "16px",
  paddingLeft: 1,
  fontWeight: "400",
};

const gridButton = {
  background: "#FFF",
  justifyContent: "space-between",
  display: "flex",
  padding: "20px",
  marginTop: "10px",
  boxShadow: "2",
  marginBottom: "10px",
};

const gridPatient = {
  color: "#424242",
  fontSize: "16px",
  fontWeight: "500",
};

const gridTypography = {
  color: "#000",
  fontSize: "20px",
  fontWeight: "400",
};

const gridTable = {
  display: "flex",
  alignItems: "center",
};

const gridIcon = {
  color: "#0085FF",
  fontSize: 40,
};

const feesBottom = {
  marginBottom: "5px",
  color: "green",
};

const feesTypography = {
  fontSize: "22px",
  color: "#424242",
  fontWeight: "500",
};

const feesBox = {
  border: "1px solid",
  padding: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "33.33%",
};

const chart = {
  fontSize: "22px",
  marginBottom: 2,
  fontWeight: "700",
  color: "#424242",
};

const Dashboard = () => {
  const navigate = useNavigate();
  
  const salesData = ChartData.sales;
  const ordersData = ChartData.orders;
  const [dashboardData, setDashboardData] = useState("");
  console.log("dashboardData", dashboardData);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/total`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDashboardData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
      });
  }, []);

  const data = {
    labels: salesData.map((item) => item.dataRange),
    datasets: [
      {
        label: "sales",
        data: salesData.map((item) => item.value),
      },
      {
        label: "orders",
        data: ordersData.map((item) => item.value),
      },
    ],
  };

  useEffect(() => {
    if (getToken() === "") {
      localStorage.clear();
      navigate(0);
    }
  }, []);

  return (
    <Box>
      <Header />
      <Box sx={dashboard}>
        <Box padding={3}>
          <Box marginBottom={2}>
            <Typography sx={typographyContent}>Dashboard</Typography>
            <Typography sx={typographyApp}>Welcome to Application</Typography>
          </Box>
          <Box marginBottom={5}>
            <Grid container>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>Today Patient</Typography>
                    <Typography sx={gridTypography}>
                      <CountUp start={0} end={dashboardData?.todayPatient} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <PersonIcon sx={gridIcon} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>Today New Patient</Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.todayPatient} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <FiberNewIcon sx={{ color: "#0FA958", fontSize: 40 }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton}>
                  <Box>
                    <Typography sx={gridPatient}>Today Old Patient</Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.todayOldPatient} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <DirectionsRunIcon sx={gridIcon} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>
                      Today Medicines Collection
                    </Typography>
                    <Typography sx={gridTypography}>00</Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <MedicationIcon sx={{ color: "#0FA958", fontSize: 40 }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>
                      Today Consultation Collection
                    </Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.todayConsultationCollection} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <Diversity2Icon sx={gridIcon} />
                  </Box>
                </Box>
              </Grid>
              <Grid md={4}>
                <Box sx={gridButton}>
                  <Box>
                    <Typography sx={gridPatient}>
                      Current Month Patient
                    </Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.currentMonthPatients} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <ManageAccountsIcon
                      sx={{ color: "#0FA958", fontSize: 40 }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>
                      Current Month Medicines Collection
                    </Typography>
                    <Typography sx={gridTypography}>00</Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <MedicationIcon sx={gridIcon} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton} marginRight={2}>
                  <Box>
                    <Typography sx={gridPatient}>
                      Current Month Consultation Collection
                    </Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.currentMonthConsultationCollection} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <PersonAddAltIcon sx={{ color: "#0FA958", fontSize: 40 }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box sx={gridButton}>
                  <Box>
                    <Typography sx={gridPatient}>Total Patient </Typography>
                    <Typography sx={gridTypography}>
                    <CountUp start={0} end={dashboardData?.totalPatient} duration={2}/>
                    </Typography>
                  </Box>
                  <Box sx={gridTable}>
                    <LocalHospitalIcon sx={gridIcon} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography sx={typographyContent}>Monthly Patient</Typography>
            <Box padding={8}>
              <Typography sx={chart}>Line Chart</Typography>
              <Divider
                sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
              />
              <Box paddingTop={5} sx={{ width: "100%" }}>
                <Line data={data}></Line>
              </Box>
              <Stack marginTop={10} direction="row" spacing={2}>
                <Box sx={feesBox}>
                  <AccountBalanceIcon sx={feesBottom} />
                  <Typography sx={feesTypography}>
                    Consultation Fees for May
                  </Typography>
                </Box>
                <Box sx={feesBox}>
                  <AccountBalanceIcon sx={feesBottom} />
                  <Typography sx={feesTypography}>
                    Medicines Fees for May
                  </Typography>
                </Box>
                <Box sx={feesBox}>
                  <AccountBalanceIcon sx={feesBottom} />
                  <Typography sx={feesTypography}>
                    Total Fees for May
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
