import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";

function GynecPage({ openGynecDialog, saveButton , cancelButton , setOpenGynecDialog }) {
  return (
    <>
      <Dialog
        open={openGynecDialog}
        maxWidth="md"
        fullWidth={true}
        scroll={"paper"}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: "26px", fontWeight: 600 }}>
            Gynec & Obstetrics History
          </Typography>
        </DialogTitle>
        <Divider sx={{ height: 2 }} />
        <DialogContent>
          <Grid container>
            <Grid md={6}>
              <Typography sx={{ fontWeight: 600 }}>Cheif Complaint</Typography>
              <TextField
                multiline
                fullWidth
                sx={{ width: "100%", marginTop: "8px" }}
                name="Cheif Complaint"
                label={"Cheif Complaint"}
                placeholder="Cheif Complaint"
              />
            </Grid>
            <Grid md={4} ml={2}>
              <Typography sx={{ fontWeight: 600 }}>
                First Menstrual Period (FMP)
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    fullWidth
                    label="First Menstrual Period"
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Typography mt={2} sx={{ fontWeight: 600 }}>
            Menstrual Cycle History
          </Typography>
          <Grid container>
            <Grid md={3}>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Regular"
                    control={<Radio />}
                    label="Regular"
                  />
                  <FormControlLabel
                    value="Irregular"
                    control={<Radio />}
                    label="Irregular"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Painful"
                    control={<Radio />}
                    label="Painful"
                  />
                  <FormControlLabel
                    value="Painless"
                    control={<Radio />}
                    label="Painless"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Scanty"
                    control={<Radio />}
                    label="Scanty"
                  />
                  <FormControlLabel
                    value="Moderate"
                    control={<Radio />}
                    label="Moderate"
                  />
                  <FormControlLabel
                    value="Excessive"
                    control={<Radio />}
                    label="Excessive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <Typography sx={{ fontWeight: 600 }}>
                Pads used per day
              </Typography>
              <TextField></TextField>
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={5.8} mr={1}>
              <Typography sx={{ fontWeight: 600 }}>P/S</Typography>
              <TextField
                multiline
                fullWidth
                sx={{ marginTop: "8px" }}
                name="Cheif Complaint"
                label={"P/S"}
                placeholder="Cheif Complaint"
              />
            </Grid>
            <Grid md={6}>
              <Typography sx={{ fontWeight: 600 }}>P/V</Typography>
              <TextField
                multiline
                fullWidth
                sx={{ marginTop: "8px" }}
                name="Cheif Complaint"
                label={"P/V"}
                placeholder="Cheif Complaint"
              />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid md={12}>
              <Typography sx={{ fontWeight: 600 }}>
                Coital History(Co/H)
              </Typography>
              <TextField
                sx={{ marginTop: "8px" }}
                fullWidth
                label={"Coital History(Co/H)"}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid md={12}>
              <Typography sx={{ fontWeight: 600 }}>
                Contraceptive History
              </Typography>
              <TextField
                sx={{ marginTop: "8px" }}
                label={"Contraceptive History"}
                fullWidth
              ></TextField>
            </Grid>
          </Grid>
          <Typography mt={2} sx={{ fontWeight: 600 }}>
            General History
          </Typography>
          <Grid container>
            <Grid md={3}>
              <Typography sx={{ fontSize: "18px" }}>Stool</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Regular"
                    control={<Radio />}
                    label="Regular"
                  />
                  <FormControlLabel
                    value="Irregular"
                    control={<Radio />}
                    label="Irregular"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <Typography sx={{ fontSize: "18px" }}>Urine</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Normal"
                    control={<Radio />}
                    label="Normal"
                  />
                  <FormControlLabel
                    value="Abnormal"
                    control={<Radio />}
                    label="Abnormal"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <Typography sx={{ fontSize: "18px" }}>Appetizer</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Regular"
                    control={<Radio />}
                    label="Regular"
                  />
                  <FormControlLabel
                    value="Irregular"
                    control={<Radio />}
                    label="Irregular"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <Typography sx={{ fontSize: "18px" }}>Sleep</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="Regular"
                    control={<Radio />}
                    label="Regular"
                  />
                  <FormControlLabel
                    value="Irregular"
                    control={<Radio />}
                    label="Irregular"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Typography mt={2} sx={{ fontSize: "22px", fontWeight: 600 }}>
            Obstetrics History
          </Typography>
          <Grid container>
            <Grid md={4} mt={2}>
              <Typography sx={{ fontWeight: 600 }}>
                Last Menstrual Period (LMP)
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    fullWidth
                    label="First Menstrual Period"
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid md={4} mt={2}>
              <Typography sx={{ fontWeight: 600 }}>Last Delivery</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    fullWidth
                    label="First Menstrual Period"
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid md={4} mt={2}>
              <Typography sx={{ fontWeight: 600 }}>
                Expected Date Of Delivery(EDD)
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    fullWidth
                    label="First Menstrual Period"
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid md={4} mt={1}>
              <Typography sx={{ fontWeight: 600 }}>BP</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"BP"}></TextField>
            </Grid>
            <Grid md={4} mt={1}>
              <Typography sx={{ fontWeight: 600 }}>Pulse</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"Pulse"}></TextField>
            </Grid>
            <Grid md={4} mt={1}>
              <Typography sx={{ fontWeight: 600 }}>Weight</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"Weight"}></TextField>
            </Grid>
          </Grid>
          <Typography mt={2} sx={{ fontSize: "24px", fontWeight: 600 }}>
            GPALD
          </Typography>
          <Grid container mt={2}>
            <Grid md={2} mr={3}>
              <Typography sx={{ fontWeight: 600 }}>Gravida</Typography>
              <TextField
                sx={{ marginTop: "8px" }}
                label={"Gravida"}
              ></TextField>
            </Grid>
            <Grid md={2} mr={3}>
              <Typography sx={{ fontWeight: 600 }}>Parity</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"Parity"}></TextField>
            </Grid>
            <Grid md={2} mr={3}>
              <Typography sx={{ fontWeight: 600 }}>Abortion</Typography>
              <TextField
                sx={{ marginTop: "8px" }}
                label={"Abortion"}
              ></TextField>
            </Grid>
            <Grid md={2} mr={3}>
              <Typography sx={{ fontWeight: 600 }}>Live</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"Live"}></TextField>
            </Grid>
            <Grid md={2}>
              <Typography sx={{ fontWeight: 600 }}>Dead</Typography>
              <TextField sx={{ marginTop: "8px" }} label={"Dead"}></TextField>
            </Grid>
          </Grid>
          <Typography mt={2} sx={{ fontSize: "24px", fontWeight: 600 }}>
            Nature Of Delivery
          </Typography>
          <Grid container mt={2}>
            <Grid md={2.4}>
              <Typography sx={{ fontWeight: 600 }}>
                Full Term Normal Delivery
              </Typography>
              <RadioGroup>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid md={2.4}>
              <Typography sx={{ fontWeight: 600 }}>
                Pre-Term Normal Delivery
              </Typography>
              <RadioGroup>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid md={2.4}>
              <Typography sx={{ fontWeight: 600 }}>
                Lower Segment Caesarean Section
              </Typography>
              <RadioGroup>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid md={2.4}>
              <Typography sx={{ fontWeight: 600 }}>Forcep's</Typography>
              <RadioGroup>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid md={2.4}>
              <Typography sx={{ fontWeight: 600 }}>Vaccum</Typography>
              <RadioGroup>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid md={12}>
              <Typography sx={{ fontWeight: 600 }}>
                Investigation/Other
              </Typography>
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                label="Investigation/Other"
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"end"}>
            <Grid>
              <Button fullWidth variant="contained" sx={saveButton}>
                Save
              </Button>
            </Grid>
            <Grid>
              <Button
                fullWidth
                variant="outlined"
                sx={cancelButton}
                onClick={() => setOpenGynecDialog(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GynecPage;
