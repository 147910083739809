import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// create a medicines
export const saveMedicines = async (medicinesData) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/medicine`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: medicinesData,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// delete medicines
export const deleteMedicine = async (medicineId) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/medicine/${medicineId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// edit medicines
export const updateMedicine = async (editedMedicine) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/medicine/${editedMedicine._id}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: editedMedicine,
    };

    const response = await axios.request(config);
    console.log("response", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
