import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// delete patient
export const deletePatient = async (patientId) => {
  const config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl.url}/api/patient/${patientId}`,
    headers: {
      token: getToken(),
    },
  };

  try {
    const response = await axios.request(config);
    window.location.reload();
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
// get patient
// export const getPatient = (searchByName, startDate, endDate) => {
  
// };
// add new patient
export const addPatientData = async (patientData) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/patient`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: patientData,
    };
    const response = await axios.request(config);
    console.log("response: ", response);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    if (error.response.data === "Invalid Token") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error("Error:", error);
    }
  }
};
// get single patient Data
export const getSinglePatientData = async (patientId) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/patient/${patientId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.log("Error:", error);
  }
};
// update patient data
export const updatePatientData = async (patientId, editPatient) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/patient/${patientId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: editPatient,
    };

    const response = await axios.request(config);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// image upload
export const uploadImage = async (formData) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/upload`,
      headers: {
        token: getToken(),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log("Error:", error);
  }
};

// image ideo
export const uploadVideo = async (formData) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/upload`,
      headers: {
        token: getToken(),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log("Error:", error);
  }
};
