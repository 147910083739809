import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

// component
import Header from "../../common/Header/Header";
import SaleMedicines from "./SaleMedicines";
import {
  fetchSaleOrderDetails,
  saveSaleOrder,
  updateSaleOrderData,
} from "../../../services/saleOrder/saleOrderServices";

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "auto",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  backgroundColor: "#2F7539",
  textAlign: "center",
  marginRight: 3,
  width: "120px",
  textTransform: "none",
  fontSize: "16px",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  width: "120px",
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
  fontSize: "16px",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
  padding: "5px",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  alignItems: "center",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const medicinesName = [{ label: "alis" }, { label: "yash" }, { label: "raj " }];

const SaleOrder = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validateError, setValidateError] = useState({});
  const [saleMedicine, setSaleMedicine] = useState({
    invoiceNo: "",
    mobileNumber: "",
    invoiceDate: new Date(),
    partyName: "",
    address: "",
    medicines: [],
  });

  const addSaleMedicines = (ele) => {
    setSaleMedicine({ ...saleMedicine, [ele.target.name]: ele.target.value });
  };

  const handleDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MM-YYYY");

    setSaleMedicine((prevState) => ({
      ...prevState,
      [type]: formattedDate,
    }));
  };

  console.log(saleMedicine.invoiceDate);

  const validateFields = () => {
    const errors = {};
    if (!saleMedicine.invoiceNo) {
      errors.invoiceNo = "Invoice Number is required";
    }
    if (!saleMedicine.invoiceDate) {
      errors.invoiceDate = "Invoice Date is required";
    }
    if (!/^\d{10}$/.test(saleMedicine.mobileNumber)) {
      errors.mobileNumber = "Mobile Number should be 10 digits";
    }
    if (!saleMedicine.partyName) {
      errors.partyName = "Party Name is required";
    }

    setValidateError(errors);
    return Object.keys(errors).length === 0;
  };

  // sale order challan , p.o , credit number & date
  const [challan, setChallan] = useState({
    number: "",
    date: new Date(),
  });

  const handleChallanChange = (ele) => {
    const { name, value } = ele.target;
    setChallan((prevChallan) => ({
      ...prevChallan,
      [name]: value,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      challan: {
        ...prevOrderData.challan,
        [name]: value,
      },
    }));
  };
  const handleChallanDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setChallan((prevChallan) => ({
      ...prevChallan,
      date: formattedDate,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      challan: {
        ...prevOrderData.challan,
        number: challan.number,
        date: formattedDate,
      },
    }));
  };

  const [poNumber, setPoDetails] = useState({
    number: "",
    date: new Date(),
  });

  const handlePoChange = (ele) => {
    const { name, value } = ele.target;
    setPoDetails((poNumber) => ({
      ...poNumber,
      [name]: value,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      poNumber: {
        ...prevOrderData.poNumber,
        [name]: value,
      },
    }));
  };
  const handlePoDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setPoDetails((prevpoDetails) => ({
      ...prevpoDetails,
      date: formattedDate,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      poNumber: {
        ...prevOrderData.poNumber,
        number: poNumber.number,
        date: formattedDate,
      },
    }));
  };

  const [credit, setCreditNumber] = useState({
    creditAmount: "",
    date: new Date(),
  });

  const handleCreditChange = (ele) => {
    const { name, value } = ele.target;
    setCreditNumber((credit) => ({
      ...credit,
      [name]: value,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      credit: {
        ...prevOrderData.credit,
        [name]: value,
      },
    }));
  };
  const handleCreditDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setCreditNumber((prevpoDetails) => ({
      ...prevpoDetails,
      date: formattedDate,
    }));
    setSaleMedicine((prevOrderData) => ({
      ...prevOrderData,
      credit: {
        ...prevOrderData.credit,
        creditAmount: credit.creditAmount,
        date: formattedDate,
      },
    }));
  };

  // Medicines
  const handleAddMedicinesClick = () => {
    setSelectedMedicineDetails("");
    setEditedMedicine("");
    setEditedMedicineCalculations("");
    setOpen(true);
  };

  const [netAmt, setNetAmt] = useState(0);
  const [medicineList, setMedicineList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [addDeleteOpen, setAddDeleteOpen] = useState(false);
  const [deleteMedicineIndex, setDeleteMedicineIndex] = useState(null);
  const [updateMedicineIndex, setUpdateMedicineIndex] = useState("");
  const [selectedMedicineDetails, setSelectedMedicineDetails] = useState({});
  const [calculate, setCalculate] = useState({
    discountedAmt: "",
    vatAmt: "",
    adTaxAmt: "",
    cgstAmt: "",
    igstAmt: "",
    sgstAmt: "",
    cessAmt: "",
    netAmt: "",
  });

  const calculateMedicineValues = (updateMedicine) => {
    const { amount, discount, vat, adTax, cgst, igst, sgst, cess } =
      updateMedicine;

    const discountedAmt = (amount * (discount || 0)) / 100;
    const totalDiscount = amount - discountedAmt;
    const vatAmt = (totalDiscount * (vat || 0)) / 100;
    const adTaxAmt = (totalDiscount * (adTax || 0)) / 100;
    const cgstAmt = (totalDiscount * (cgst || 0)) / 100;
    const igstAmt = (totalDiscount * (igst || 0)) / 100;
    const sgstAmt = (totalDiscount * (sgst || 0)) / 100;
    const cessAmt = (totalDiscount * (cess || 0)) / 100;

    const netAmt =
      totalDiscount + vatAmt + adTaxAmt + cgstAmt + igstAmt + sgstAmt + cessAmt;

    return {
      netAmount: netAmt,
      amt: totalDiscount,
      discountedAmt,
      vatAmt,
      adTaxAmt,
      cgstAmt,
      igstAmt,
      sgstAmt,
      cessAmt,
    };
  };

  const selectedMedicinesChange = (name, value) => {
    const updatedMedicine = { ...selectedMedicineDetails, [name]: value };

    if (
      [
        "quantity",
        "box",
        "unitRate",
        "amount",
        "discount",
        "vat",
        "adTax",
        "cgst",
        "igst",
        "sgst",
        "cess",
      ].includes(name)
    ) {
      const {
        netAmount,
        amt,
        discountedAmt,
        vatAmt,
        adTaxAmt,
        cgstAmt,
        igstAmt,
        sgstAmt,
        cessAmt,
      } = calculateMedicineValues(updatedMedicine);

      updatedMedicine.netAmount = netAmount;
      console.log("updatedMedicine.netAmount", updatedMedicine.netAmount);
      updatedMedicine.amt = amt;

      setSelectedMedicineDetails(updatedMedicine);

      setNetAmt(netAmount);

      setCalculate({
        discountedAmt,
        vatAmt,
        adTaxAmt,
        cgstAmt,
        igstAmt,
        sgstAmt,
        cessAmt,
      });
    } else {
      setSelectedMedicineDetails(updatedMedicine);
    }
  };

  useEffect(() => {
    const { amount, discount, vat, adTax, cgst, igst, sgst, cess } =
      selectedMedicineDetails || {};

    const discountedAmt = (amount * (discount || 0)) / 100;
    const totalDiscount = amount - discountedAmt;
    const vatAmt = (totalDiscount * (vat || 0)) / 100;
    const adTaxAmt = (totalDiscount * (adTax || 0)) / 100;
    const cgstAmt = (totalDiscount * (cgst || 0)) / 100;
    const igstAmt = (totalDiscount * (igst || 0)) / 100;
    const sgstAmt = (totalDiscount * (sgst || 0)) / 100;
    const cessAmt = (totalDiscount * (cess || 0)) / 100;

    const netAmt =
      totalDiscount + vatAmt + adTaxAmt + cgstAmt + igstAmt + sgstAmt + cessAmt;

    setCalculate({
      discountedAmt,
      vatAmt,
      adTaxAmt,
      cgstAmt,
      igstAmt,
      sgstAmt,
      cessAmt,
    });
    setNetAmt(netAmt);
  }, [selectedMedicineDetails]);

  // dialog data save
  const handleSaveClick = () => {
    const newMedicine = { ...selectedMedicineDetails, netAmount: netAmt };
    console.log(newMedicine);

    if (isEditMode) {
      const updatedMedicineList = medicineList.map((med, index) => {
        return index === updateMedicineIndex ? newMedicine : med;
      });

      setMedicineList(updatedMedicineList);

      setSaleMedicine((prevOrderData) => ({
        ...prevOrderData,
        [saleMedicine.medicines]: updatedMedicineList,
      }));
    } else {
      setSaleMedicine((prevOrderData) => ({
        ...prevOrderData,
        medicines: [...prevOrderData.medicines, newMedicine],
      }));
      setMedicineList((prevMedicineList) => [...prevMedicineList, newMedicine]);
    }

    setIsEditMode(false);
    setOpen(false);
  };

  // Edit Medicines In Add Sale Medicines
  const addSaleOrderMedicineEdit = (index) => {
    console.log("Edit button clicked for index:", index);
    setUpdateMedicineIndex(index);
    if (index >= 0 && index < medicineList.length) {
      const selectedMedicine = medicineList[index];
      setSelectedMedicineDetails({ ...selectedMedicine });
      setIsEditMode(true);
      setOpen(true);
    }
  };

  // Delete Medicines In Add Sale Medicines
  const handleAddMedicineDelete = (index) => {
    setDeleteMedicineIndex(index);
    setAddDeleteOpen(true);
    return index;
  };

  const addMedicineDelete = (indexToDelete) => {
    if (indexToDelete >= 0 && indexToDelete < medicineList.length) {
      const updatedMedicineList = medicineList.filter(
        (_, index) => index !== indexToDelete
      );

      setMedicineList(updatedMedicineList);
      setDeleteMedicineIndex(null);
    }
  };

  // sale order data save
  const handleOrderSave = async () => {
    if (validateFields()) {
      setLoading(true);
      try {
        const response = await saveSaleOrder(saleMedicine, medicineList);
        console.log(response.data);
        navigate("/saleorderlist");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    navigate("/saleorderlist");
  };

  const total = medicineList.reduce(
    (accumulator, medicine) => {
      return {
        quantity: Number(accumulator.quantity) + Number(medicine.quantity),
        amount: Number(accumulator.amount) + Number(medicine.amount),
        netAmount: Number(accumulator.netAmount) + Number(medicine.netAmount),
        box: Number(accumulator.box) + Number(medicine.box),
        discount: Number(accumulator.discount) + Number(medicine.discount),
      };
    },
    {
      quantity: 0,
      amount: 0,
      netAmount: 0,
      box: 0,
      discount: 0,
    }
  );

  // edit sale order page
  const [selectedMedicineData, setSelectedMedicineData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editedMedicine, setEditedMedicine] = useState(null);
  const [editedMedicineCalculations, setEditedMedicineCalculations] = useState({
    discountedAmt: 0,
    vatAmt: 0,
    adTaxAmt: 0,
    cgstAmt: 0,
    igstAmt: 0,
    sgstAmt: 0,
    cessAmt: 0,
    netAmt: 0,
  });

  const handleEditButtonClick = (ele) => {
    const medicine = selectedMedicineData.medicines;
    const itemToEdit = medicine.find((medicine) => medicine._id === ele);

    console.log("itemToEdit", itemToEdit);

    const calculatedValues = calculateMedicineValues(itemToEdit);
    setEditedMedicine(itemToEdit);
    setEditedMedicineCalculations(calculatedValues);

    setNetAmt(calculatedValues.netAmount);

    setOpen(true);
  };

  const handleEditDialogChange = (event) => {
    const { name, value } = event.target;

    setEditedMedicine((prevEditMedicine) => {
      const updatedMedicine = {
        ...prevEditMedicine,
        [name]: Number(value),
      };

      // Assuming you also need to recalculate and update the net amount
      const calculatedValues = calculateMedicineValues(updatedMedicine);
      setEditedMedicineCalculations(calculatedValues);
      setNetAmt(calculatedValues.netAmount);

      return updatedMedicine;
    });
  };

  const { editSaleId } = useParams();
  useEffect(() => {
    if (editSaleId) {
      const fetchData = async () => {
        try {
          const data = await fetchSaleOrderDetails(editSaleId);
          setSelectedMedicineData(data.selectedMedicineData);
          setChallan(data.challan);
          setPoDetails(data.poDetails);
          setCreditNumber(data.creditNumber);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [editSaleId]);

  // edit sale order page input value change
  const handleEditChange = (ele) => {
    setSelectedMedicineData({
      ...selectedMedicineData,
      [ele.target.name]: ele.target.value,
    });
  };

  console.log(
    "selectedMedicineData",
    selectedMedicineData?.invoiceDate
      ? new Date(selectedMedicineData.invoiceDate).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "Invoice date not available"
  );

  // Edit sale order page Medicines delete
  const handleDeleteOpen = (index) => {
    setItemToDelete(index);
    setDeleteOpen(true);
  };

  const handleDeleteMedicine = (itemId) => {
    console.log("Deleting medicine with ID:", itemId);

    const updatedMedicines = selectedMedicineData.medicines.filter(
      (medicine) => medicine._id !== itemId
    );

    setSelectedMedicineData({
      ...selectedMedicineData,
      medicines: updatedMedicines,
    });
  };

  const handleEditClick = () => {
    if (editedMedicine) {
      const calculatedValues = calculateMedicineValues(editedMedicine);

      const updatedMedicine = {
        ...editedMedicine,
        netAmount: calculatedValues.netAmount,
      };

      const updatedMedicines = selectedMedicineData.medicines.map((item) =>
        item._id === updatedMedicine._id ? updatedMedicine : item
      );

      setSelectedMedicineData({
        ...selectedMedicineData,
        medicines: updatedMedicines,
      });
    } else {
      const newMedicine = { ...selectedMedicineDetails, netAmount: netAmt };
      setSelectedMedicineData({
        ...selectedMedicineData,
        medicines: [...selectedMedicineData.medicines, newMedicine],
      });
    }
    setOpen(false);
  };

  const handleUpdateOrder = async () => {
    setLoading(true);
    try {
      await updateSaleOrderData(
        editSaleId,
        selectedMedicineData,
        challan,
        poNumber,
        credit
      );
      console.log("Data updated successfully");
      navigate("/saleorderlist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editTotal = selectedMedicineData?.medicines?.reduce(
    (accumulator, medicine) => {
      return {
        quantity: Number(accumulator.quantity) + Number(medicine.quantity),
        amount: Number(accumulator.amount) + Number(medicine.amount),
        netAmount: Number(accumulator.netAmount) + Number(medicine.netAmount),
        box: Number(accumulator.box) + Number(medicine.box),
        discount: Number(accumulator.discount) + Number(medicine.discount),
      };
    },
    {
      quantity: 0,
      amount: 0,
      netAmount: 0,
      box: 0,
      discount: 0,
    }
  ) || {
    quantity: 0,
    amount: 0,
    netAmount: 0,
    box: 0,
    discount: 0,
  };

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box p={3}>
            <Box>
              <Breadcrumbs>
                <Link style={linkstyle} to="/dashboard">
                  Dashboard
                </Link>
                <Link style={linkstyle} to="/medicines">
                  Medicines
                </Link>
                <Link style={linkstyle} to="/saleorderlist">
                  Sale Order
                </Link>
              </Breadcrumbs>
            </Box>
            <Typography sx={typographyContent}>
              {editSaleId ? "Edit Sale Order" : "Sale Order"}
            </Typography>
            <Box mt={2} display="flex">
              <Box mr={2}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item md={4}>
                    <TextField
                      sx={{ mt: "8px", width: "96%" }}
                      placeholder="Invoice Number"
                      variant="outlined"
                      name="invoiceNo"
                      label={editSaleId ? "" : "Invoice Number"}
                      onChange={
                        editSaleId ? handleEditChange : addSaleMedicines
                      }
                      value={
                        selectedMedicineData
                          ? selectedMedicineData.invoiceNo
                          : saleMedicine.invoiceNo
                      }
                      error={!!validateError.invoiceNo}
                      helperText={validateError.invoiceNo}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{ width: "100" }}
                          label="Invoice Date"
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              helperText: validateError.invoiceDate,
                              error: validateError.invoiceDate,
                            },
                          }}
                          value={
                            editSaleId
                              ? dayjs(
                                  moment(
                                    selectedMedicineData?.invoiceDate
                                  ).format("YYYY-MM-DD")
                                )
                              : dayjs(moment().format("YYYY-MM-DD"))
                          }
                          onChange={
                            editSaleId
                              ? (newDate) =>
                                  handleEditChange({
                                    target: {
                                      name: "invoiceDate",
                                      value: newDate
                                        ? newDate.format("YYYY-MM-DD")
                                        : "",
                                    },
                                  })
                              : (newDate) =>
                                  handleDatePicker("invoiceDate", newDate)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      sx={{ mt: "8px", width: "100%" }}
                      placeholder="Mobile Number"
                      name="mobileNumber"
                      variant="outlined"
                      label={editSaleId ? "" : "Mobile Number"}
                      onChange={
                        editSaleId ? handleEditChange : addSaleMedicines
                      }
                      value={
                        selectedMedicineData
                          ? selectedMedicineData.mobileNumber
                          : saleMedicine.mobileNumber
                      }
                      error={!!validateError.mobileNumber}
                      helperText={validateError.mobileNumber}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      sx={{ mt: 2, width: "100%" }}
                      placeholder="Party Name"
                      name="partyName"
                      variant="outlined"
                      onChange={
                        editSaleId ? handleEditChange : addSaleMedicines
                      }
                      label={editSaleId ? "" : "Party Name"}
                      value={
                        selectedMedicineData
                          ? selectedMedicineData.partyName
                          : saleMedicine.partyName
                      }
                      error={!!validateError.partyName}
                      helperText={validateError.partyName}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      rows={4}
                      sx={{ width: "100%", mt: "15px" }}
                      name="address"
                      placeholder="Address"
                      onChange={
                        editSaleId ? handleEditChange : addSaleMedicines
                      }
                      label={editSaleId ? "" : "Address"}
                      value={
                        selectedMedicineData
                          ? selectedMedicineData.address
                          : saleMedicine.address
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box display="flex">
                  <TextField
                    sx={{ mt: "8px", mr: "10px" }}
                    placeholder="Challan Number"
                    variant="outlined"
                    label="Challan Number"
                    name="number"
                    onChange={handleChallanChange}
                    value={challan.number}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "230px", mt: "8px" }}
                      label="Challan Date"
                      format="DD-MM-YYYY"
                      value={
                        challan.date
                          ? dayjs(challan.date)
                          : dayjs(moment().format("YYYY-MM-DD"))
                      }
                      onChange={(newDate) =>
                        handleChallanDatePicker("challanDate", newDate)
                      }
                    />
                  </LocalizationProvider>
                </Box>
                <Box display="flex">
                  <TextField
                    sx={{ mt: "16px", mr: "10px", width: "190px" }}
                    placeholder="P.O. Number"
                    variant="outlined"
                    label="P.O. Number"
                    name="number"
                    onChange={handlePoChange}
                    value={poNumber.number}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "200px", mt: "16px" }}
                      label="P.O. Date"
                      format="DD-MM-YYYY"
                      value={
                        poNumber.date
                          ? dayjs(poNumber.date)
                          : dayjs(moment().format("YYYY-MM-DD"))
                      }
                      onChange={(newDate) =>
                        handlePoDatePicker("poNumber", newDate)
                      }
                    />
                  </LocalizationProvider>
                </Box>
                <Box display="flex">
                  <TextField
                    sx={{ mt: "16px", mr: "10px", width: "190px" }}
                    placeholder="Credit Number"
                    variant="outlined"
                    label="Credit Number"
                    name="creditAmount"
                    onChange={handleCreditChange}
                    value={credit.creditAmount}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "200px", mt: "16px" }}
                      label="Credit Date"
                      format="DD-MM-YYYY"
                      value={
                        credit.date
                          ? dayjs(credit.date)
                          : dayjs(moment().format("YYYY-MM-DD"))
                      }
                      onChange={(newDate) =>
                        handleCreditDatePicker("credit", newDate)
                      }
                    />
                  </LocalizationProvider>
                </Box>
                <Grid sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    sx={{ mt: "15px" }}
                    variant="contained"
                    color="success"
                    onClick={handleAddMedicinesClick}
                  >
                    <AddCircleOutlineIcon sx={{ fontSize: "18px" }} />
                    <Typography sx={patientButton}>Add Medicines</Typography>
                  </Button>
                </Grid>
              </Box>
            </Box>
            <Divider sx={{ height: 1, bgcolor: "black", m: "20px" }} />
            <Box mt="30px" mb="40px">
              <TableContainer sx={{ maxHeight: 340 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Group Name</TableCell>
                      <TableCell align="center">Medicine Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Unit</TableCell>
                      <TableCell align="center">Discount</TableCell>
                      <TableCell align="center">Box/Pack</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Net Amount</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editSaleId && selectedMedicineData.medicines
                      ? selectedMedicineData.medicines.map((item, index) => (
                          <TableRow key={item._id}>
                            <TableCell align="center">
                              {item.groupName}
                            </TableCell>
                            <TableCell align="center">
                              {item.itemName}
                            </TableCell>
                            <TableCell align="center">
                              {item.quantity}
                            </TableCell>
                            <TableCell align="center">
                              {item.unitRate}
                            </TableCell>
                            <TableCell align="center">
                              {item.discount}
                            </TableCell>
                            <TableCell align="center">{item.box}</TableCell>
                            <TableCell align="center">{item.amount}</TableCell>
                            <TableCell align="center">
                              {item.netAmount &&
                              typeof item.netAmount === "number"
                                ? item.netAmount.toFixed(2)
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disableRipple
                                sx={hoverEffect}
                                onClick={() => handleEditButtonClick(item._id)}
                              >
                                <EditIcon sx={editButton} />
                              </Button>
                              <Button
                                disableRipple
                                sx={hoverEffect}
                                onClick={() => handleDeleteOpen(item._id)}
                              >
                                <DeleteIcon sx={deleteButton} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : medicineList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {item.groupName}
                            </TableCell>
                            <TableCell align="center">
                              {item.itemName}
                            </TableCell>
                            <TableCell align="center">
                              {item.quantity}
                            </TableCell>
                            <TableCell align="center">
                              {item.unitRate}
                            </TableCell>
                            <TableCell align="center">
                              {item.discount}
                            </TableCell>
                            <TableCell align="center">{item.box}</TableCell>
                            <TableCell align="center">{item.amount}</TableCell>
                            <TableCell align="center">
                              {item.netAmount &&
                              typeof item.netAmount === "number"
                                ? item.netAmount.toFixed(2)
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disableRipple
                                sx={hoverEffect}
                                onClick={() => addSaleOrderMedicineEdit(index)}
                              >
                                <EditIcon sx={editButton} />
                              </Button>
                              <Button
                                disableRipple
                                sx={hoverEffect}
                                onClick={() => handleAddMedicineDelete(index)}
                              >
                                <DeleteIcon sx={deleteButton} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Divider sx={{ height: 1, bgcolor: "black", m: "20px" }} />
            <Box marginTop="40px">
              <Box>
                <Grid container>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      placeholder="Item Amount"
                      variant="outlined"
                      label="Item Amount"
                      defaultValue="0.00"
                      value={editTotal?.amount || total.amount}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Quantity"
                      variant="outlined"
                      label="Total Quantity"
                      defaultValue="0.00"
                      value={editTotal?.quantity || total.quantity}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Box"
                      variant="outlined"
                      label="Total Box"
                      defaultValue="0.00"
                      value={editTotal?.box || total.box}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Disc"
                      variant="outlined"
                      label="Total Disc"
                      defaultValue="0.00"
                      value={editTotal?.discount || total.discount}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      sx={{ marginTop: "10px" }}
                      placeholder="Total Tax"
                      variant="outlined"
                      label="Total Tax"
                      defaultValue="0.00"
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={medicinesName}
                      sx={{ mt: "10px", mr: "50px" }}
                      renderInput={(params) => (
                        <TextField {...params} label="CASH A/C" />
                      )}
                    />
                    {/* */}
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      type="text"
                      sx={{ marginTop: "10px" }}
                      placeholder="Net Amount"
                      variant="outlined"
                      label="Net Amount"
                      defaultValue="0.00"
                      value={editTotal?.netAmount || total.netAmount}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Stack mt={5} direction="row">
              <Button
                onClick={editSaleId ? handleUpdateOrder : handleOrderSave}
                sx={saveButton}
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : editSaleId ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                sx={cancelButton}
                onClick={handleCancel}
                variant="outlined"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>

      {/* start sale details dialog box */}
      <SaleMedicines
        open={open}
        setOpen={setOpen}
        handleEditDialogChange={handleEditDialogChange}
        discountAmt={calculate.discountedAmt}
        vatAmt={calculate.vatAmt}
        adTax={calculate.adTaxAmt}
        cgstAmt={calculate.cgstAmt}
        sgstAmt={calculate.sgstAmt}
        igstAmt={calculate.igstAmt}
        cessAmt={calculate.cessAmt}
        netAmt={netAmt}
        saveButton={saveButton}
        editSaleId={editSaleId}
        handleEditClick={handleEditClick}
        handleSaveClick={handleSaveClick}
        cancelButton={cancelButton}
        selectedMedicineDetails={selectedMedicineDetails}
        setSelectedMedicineDetails={setSelectedMedicineDetails}
        selectedMedicinesChange={selectedMedicinesChange}
        editedMedicine={editedMedicine}
        editedMedicineCalculations={editedMedicineCalculations}
        calculateMedicineValues={calculateMedicineValues}
      />

      {/* delete addMedicine data */}
      <Dialog open={addDeleteOpen} onClose={() => setAddDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setAddDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              addMedicineDelete(deleteMedicineIndex);
              setAddDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              handleDeleteMedicine(itemToDelete);
              setDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SaleOrder;
