import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Medicines from "./components/Medicines/Medicines";
import Appointment from "./components/Appointment/Appointment";
import AddPatient from "./components/Patient/AddPatient";
import Patient from "./components/Patient/PatientPage";
import PrivateRoutes from "./modules/routes/PrivateRoutes";
import SaleOrder from "./components/Medicines/SaleOrder/SaleOrder";
import SaleOrderList from "./components/Medicines/SaleOrder/SaleOrderList";
import StockReport from "./components/Medicines/Repots/StockReport";
import MarginReport from "./components/Medicines/Repots/MarginReport";
import PurchaseOrder from "./components/Medicines/PurchaseOrder/PurchaseOrder";
import PurchaseOrderList from "./components/Medicines/PurchaseOrder/PurchaseOrderList";
import Diagnosis from "./components/Diagnosis/Diagnosis";
import DiagnosisList from "./components/Diagnosis/DiagnosisList";
import { getToken } from "./utils/getToken";

function App() {
  return (
    <Routes>
      <Route path="/" element={getToken() ? <Dashboard /> : <Login />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        {/* patient */}
        <Route path="/patientlist" element={<Patient />} />
        <Route path="/addpatient" element={<AddPatient />} />
        <Route path="/addpatient/:patientId" element={<AddPatient />} />
        {/* appointment */}
        <Route path="/appointment" element={<Appointment />} /> 
        {/* medicine */}
        <Route path="/medicines" element={<Medicines />} />
        {/* purchase Order */}
        <Route path="/purchaseorder" element={<PurchaseOrder />} />
        <Route path="/purchaseorderlist" element={<PurchaseOrderList />} />
        <Route path="/purchaseorder/:id" element={<PurchaseOrder />} />
        {/* sale Order */}
        <Route path="/saleorder" element={<SaleOrder />} />
        <Route path="/saleorderlist" element={<SaleOrderList />} />
        <Route path="/saleorder/:editSaleId" element={<SaleOrder />} />
        {/* report */}
        <Route path="/stockreport" element={<StockReport />} />
        <Route path="/marginreport" element={<MarginReport />} />
        <Route path="/stockreport/" element={<StockReport />} />
        <Route path="/marginreport" element={<MarginReport />} />
        {/* diagnosis */}
        <Route path="/diagnosis" element={<Diagnosis />} />
        <Route path="/diagnosis/:patientId" element={<Diagnosis />} />
        <Route path="/diagnosislist" element={<DiagnosisList />} />
      </Route>
    </Routes>
  );
}

export default App;
