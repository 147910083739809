import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// Save Appointment
export const saveAppointment = async (newAppointment) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/appointment`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: newAppointment,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// List Appointment
export const getAppointmentsList = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/appointment/list`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Delete Appointment
export const deleteAppointment = async (appointmentId) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/appointment/${appointmentId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Edit Appointment
export const editAppointmentData = async (editedAppointment) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/appointment/${editedAppointment._id}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: editedAppointment,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
