import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import {
  addPurchaseOrderData,
  updatePurchaseOrderData,
} from "../../../services/purchaseOrder/PurchaseOrderService";

// files & json import
import Header from "../../common/Header/Header";
import baseUrl from "../../../constants/constant.json";
import medicinesGroup from "../../Json/MedicienGroup.json";
import medicine from "../../Json/medicines.json";
import { availableMedicine } from "../../../services/saleOrder/saleOrderServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
import { getToken } from "../../../utils/getToken";

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "auto",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  margin: "10px",
  width: "150px",
  marginLeft: "0px",
  backgroundColor: "#2F7539",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  margin: "10px",
  width: "150px",
  border: "1px solid #2F7539",
  color: "#2F7539",
};

const clearButton = {
  backgroundColor: "#202300",
  color: "#ebebeb",
  borderRadius: "5px",
  fontSize: "20px",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
  padding: "5px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const deletebutton = {
  backgroundColor: "#D11A2A",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const dialogHeading = {
  padding: "10px",
  alignItems: "center",
};

const MT = {
  marginTop: "20px",
};

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const medicineNames = [
  { label: "Yashesh" },
  { label: "Alis" },
  { label: "Abhishek" },
];

const PurchaseOrder = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDataUpdatedData, setOrderDataUpdatedData] = useState([]);
  const [discount, setDiscount] = useState("");
  const [validateError, setValidateError] = useState({});
  const [dialogValidateError, setDialogValidateError] = useState({});
  // Add New Purchase Order
  const [orderData, setOrderData] = useState({
    invoiceNo: "",
    invoiceDate: new Date(),
    partyName: "",
    address: "",
    medicines: [],
  });

  const handleValueChange = (ele) => {
    setOrderData({ ...orderData, [ele.target.name]: ele.target.value });
  };

  const handleDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MM-YYYY");
    setOrderData((prevState) => ({
      ...prevState,
      [type]: formattedDate,
    }));
  };
  // Add Challan Data
  const [challan, setChallan] = useState({
    number: "",
    date: new Date(),
  });
  const handleChallanChange = (ele) => {
    const { name, value } = ele.target;
    setChallan((prevChallan) => ({
      ...prevChallan,
      [name]: value,
    }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      challan: {
        ...prevOrderData.challan,
        [name]: value,
      },
    }));
  };
  const handleChallanDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setChallan((prevChallan) => ({ ...prevChallan, date: formattedDate }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      challan: {
        ...prevOrderData.challan,
        number: challan.number,
        date: formattedDate,
      },
    }));
  };

  // add  poNumber data
  const [poNumber, setPoDetails] = useState({
    number: "",
    date: new Date(),
  });
  // start add poNumber data
  const handlePOChange = (ele) => {
    const { name, value } = ele.target;
    setPoDetails((prevpoDetails) => ({ ...prevpoDetails, [name]: value }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      poNumber: {
        ...prevOrderData.poNumber,
        [name]: value,
      },
    }));
  };
  const handlePODatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setPoDetails((prevpoDetails) => ({
      ...prevpoDetails,
      date: formattedDate,
    }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      poNumber: {
        ...prevOrderData.poNumber,
        number: poNumber.number,
        date: formattedDate,
      },
    }));
  };

  // add credit data
  const [credit, setcredit] = useState({
    creditAmount: "",
    date: new Date(),
  });
  const handlecreditChange = (ele) => {
    const { name, value } = ele.target;
    setcredit((prevpoDetails) => ({ ...prevpoDetails, [name]: value }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      credit: {
        ...prevOrderData.credit,
        [name]: value,
      },
    }));
  };
  const handlecreditDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("DD-MMM-YYYY");
    setcredit((prevCreditDetails) => ({
      ...prevCreditDetails,
      date: formattedDate,
    }));
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      credit: {
        ...prevOrderData.credit,
        creditAmount: credit.creditAmount,
        date: formattedDate,
      },
    }));
  };

  // add purchase order data Stats
  const [medicineDetails, setMedicineDetails] = useState({
    groupName: "",
    itemName: "",
    note: "",
    box: "",
    quantity: "",
    unitRate: "",
    vat: "",
    adTax: "",
    cgst: "",
    igst: "",
    sgst: "",
    cess: "",
    stockQty: "",
    pRate: "",
    sRate: "",
    discount: "",
    amount: "",
    netAmount: "",
  });

  // Add new Medicine state
  const [medicineData, setMedicineData] = useState([]);
  const [medicineUpdateData, setMedicineUpdateData] = useState([]);
  const [discountAmt, setDiscountAmt] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [adTaxAmt, setAdTaxAmt] = useState(0);
  const [cgstAmt, setCgstAmt] = useState(0);
  const [igstAmt, setIgstAmt] = useState(0);
  const [sgstAmt, setSgstAmt] = useState(0);
  const [cessAmt, setCessAmt] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleMedicineChange = (ele) => {
    const { name, value } = ele.target;
    const updatedMedicineDetails = {
      ...medicineDetails,
      [name]: Number(value),
    };
    if (
      [
        "quantity",
        "box",
        "unitRate",
        "amount",
        "discount",
        "vat",
        "adTax",
        "cgst",
        "igst",
        "sgst",
        "cess",
      ].includes(name)
    ) {
      const discountedAmt =
        (updatedMedicineDetails.amount * updatedMedicineDetails.discount) / 100;
      const totalDiscount = updatedMedicineDetails.amount - discountedAmt;
      const vatAmt = (totalDiscount * updatedMedicineDetails.vat) / 100;
      const AdTax = (totalDiscount * updatedMedicineDetails.adTax) / 100;
      const cgst = (totalDiscount * updatedMedicineDetails.cgst) / 100;
      const igst = (totalDiscount * updatedMedicineDetails.igst) / 100;
      const sgst = (totalDiscount * updatedMedicineDetails.sgst) / 100;
      const cess = (totalDiscount * updatedMedicineDetails.cess) / 100;
      const netAmt =
        totalDiscount + vatAmt + AdTax + cgstAmt + igstAmt + sgstAmt + cessAmt;
      updatedMedicineDetails.netAmount = netAmt;
      setMedicineDetails(updatedMedicineDetails);

      setDiscountAmt(discountedAmt);
      setVatAmt(vatAmt);
      setAdTaxAmt(AdTax);
      setCgstAmt(cgst);
      setSgstAmt(sgst);
      setIgstAmt(igst);
      setCessAmt(cess);
      setNetAmount(netAmt);
    } else {
      setMedicineDetails(updatedMedicineDetails);
    }
  };
  // clear the dialog on add medicine button click
  const handleAddMedicineButton = () => {
    setMedicineDetails({
      groupName: "",
      itemName: "",
      note: "",
      box: "",
      quantity: "",
      unitRate: "",
      vat: "",
      adTax: "",
      cgst: "",
      igst: "",
      sgst: "",
      cess: "",
      stockQty: "",
      pRate: "",
      sRate: "",
      discount: "",
      amount: "",
      netAmount: "",
    });
    setDiscountAmt(0);
    setVatAmt(0);
    setAdTaxAmt(0);
    setCgstAmt(0);
    setSgstAmt(0);
    setIgstAmt(0);
    setCessAmt(0);
    setNetAmount(0);
    setOpen(true);
  };

  // auto complete add medicine Dialog
  const handleAutoComplete = (key) => (event, newValue) => {
    if (editMedicine) {
      setEditMedicine((prevEditMedicine) => ({
        ...prevEditMedicine,
        [key]: newValue ? newValue.itemName : "",
        groupName: newValue ? newValue.groupName : "",
      }));
    } else {
      setMedicineDetails((prevValues) => ({
        ...prevValues,
        [key]: newValue ? newValue.itemName : "",
        groupName: newValue ? newValue.groupName : "",
      }));
    }
  };

  const dialogValidateFields = () => {
    const errors = {};
    if (!medicineDetails.itemName) {
      errors.itemName = "Medicine Name is required";
    }
    if (!medicineDetails.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (!medicineDetails.amount) {
      errors.amount = "Amount is required";
    }
    if (!medicineDetails.unitRate) {
      errors.unitRate = "Unit Rate is required";
    }
    setDialogValidateError(errors);
    return Object.keys(errors).length === 0;
  };
  // handel Dialog Data
  const handleDialogData = () => {
    if (dialogValidateFields()) {
      const newMedicine = { ...medicineDetails };

      if (isEditMode) {
        const updatedMedicineList = medicineData.map((med, index) => {
          return index === updateMedicineIndex ? newMedicine : med;
        });

        setMedicineData(updatedMedicineList);
        setOrderData((prevOrderData) => ({
          ...prevOrderData,
          [orderData.medicines]: updatedMedicineList,
        }));
      } else {
        setOrderData((prevOrderData) => ({
          ...prevOrderData,
          medicines: [...prevOrderData.medicines, newMedicine],
        }));
        setMedicineData((prevMedicineData) => [
          ...prevMedicineData,
          newMedicine,
        ]);
      }
      setIsEditMode(false);
      setOpen(false);
    }
  };

  const validateFields = () => {
    const errors = {};
    if (!orderData.invoiceNo) {
      errors.invoiceNo = "invoice Number is required";
    }
    if (!orderData.invoiceDate) {
      errors.invoiceDate = "invoice Date is required";
    }
    if (!orderData.partyName) {
      errors.partyName = "party Name is required";
    }
    setValidateError(errors);
    return Object.keys(errors).length === 0;
  };

  // submit purchase order data to db
  const handleSubmit = () => {
    if (validateFields()) {
      setIsLoading(true);
      const addData = async () => {
        const data = await addPurchaseOrderData(orderData);
        console.log("data", data);
        if (data) {
          navigate("/purchaseorderlist");
          setIsLoading(false);
        } else {
          console.log("Purchase order");
        }
      };
      addData();
    }
  };

  // delete medicine data while editing
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteMedicine = (index) => {
    setItemToDelete(index);
    setDeleteOpen(true);
  };

  const handleDeleteMedicineData = () => {
    const updatedData = orderDataUpdatedData.medicines.filter(
      (item) => item._id !== itemToDelete
    );
    setOrderDataUpdatedData((prevData) => ({
      ...prevData,
      medicines: updatedData,
    }));
  };

  // delete medicine data while add the data
  const [addDeleteOpen, setAddDeleteOpen] = useState(false);
  const [deleteMedicineIndex, setDeleteMedicineIndex] = useState(null);
  const handleAddMedicineDelete = (index) => {
    setDeleteMedicineIndex(index);
    setAddDeleteOpen(true);
    return index;
  };
  const addMedicineDelete = (indexToDelete) => {
    if (indexToDelete >= 0 && indexToDelete < medicineData.length) {
      const updatedMedicineList = medicineData.filter(
        (_, index) => index !== indexToDelete
      );

      setMedicineData(updatedMedicineList);
      setDeleteMedicineIndex(null);
    }
  };

  // find total of values
  const total = medicineData.reduce(
    (accumulator, medicine) => {
      return {
        quantity: Number(accumulator.quantity) + Number(medicine.quantity),
        amount: Number(accumulator.amount) + Number(medicine.amount),
        netAmount: Number(accumulator.netAmount) + Number(medicine.netAmount),
        box: Number(accumulator.box) + Number(medicine.box),
        discount: Number(accumulator.discount) + Number(medicine.discount),
      };
    },
    {
      quantity: 0,
      amount: 0,
      netAmount: 0,
      box: 0,
      discount: 0,
    }
  );

  // Update Purchase Order
  const [editMedicine, setEditMedicine] = useState(null);
  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/purchaseorder/${id}`,
        headers: {
          token: getToken(),
        },
      };
      axios.request(config).then((response) => {
        setOrderDataUpdatedData(response.data.data);
        if (
          response.data.data.medicines &&
          response.data.data.medicines.length > 0
        ) {
          const firstMedicineDiscount =
            response.data.data.medicines[0].discount;
          setDiscount(firstMedicineDiscount);
        }
        setChallan({
          number: response.data.data.challan.number,
          date: response.data.data.challan.date,
        });
        setPoDetails({
          number: response.data.data.poNumber.number,
          date: response.data.data.poNumber.date,
        });
        setcredit({
          creditAmount: response.data.data.credit.creditAmount,
          date: response.data.data.credit.date,
        });
      });
    }
  }, []);

  const [updateMedicineIndex, setUpdateMedicineIndex] = useState("");
  const handleAddMedicineUpdate = (index) => {
    setUpdateMedicineIndex(index);
    setOpen(true);
    if (index >= 0 && index < medicineData.length) {
      const selectedMedicine = medicineData[index];
      setMedicineDetails({ ...selectedMedicine });
      setIsEditMode(true);
      setOpen(true);
    }
  };

  // handle medicine data to update
  const handleUpdateMedicine = (ele) => {
    setOpen(true);

    const medicine = orderDataUpdatedData.medicines;
    const medicineData = medicine.find((medicine) => medicine._id === ele);
    setMedicineUpdateData(medicineData);
    console.log("medicineData", medicineData);

    const amount = parseFloat(medicineData.amount || 0);
    const discount = parseFloat(medicineData.discount || 0);
    const vat = parseFloat(medicineData.vat || 0);
    const adTax = parseFloat(medicineData.adTax || 0);
    const cgst = parseFloat(medicineData.cgst || 0);
    const igst = parseFloat(medicineData.igst || 0);
    const sgst = parseFloat(medicineData.sgst || 0);
    const cess = parseFloat(medicineData.cess || 0);

    const discountedAmt = (amount * discount) / 100;
    const totalDiscount = amount - discountedAmt;
    const vatAmt = (totalDiscount * vat) / 100;
    const adTaxAmt = (totalDiscount * adTax) / 100;
    const cgstAmt = (totalDiscount * cgst) / 100;
    const igstAmt = (totalDiscount * igst) / 100;
    const sgstAmt = (totalDiscount * sgst) / 100;
    const cessAmt = (totalDiscount * cess) / 100;
    const netAmt =
      totalDiscount + vatAmt + adTaxAmt + cgstAmt + igstAmt + sgstAmt + cessAmt;

    setDiscountAmt(discountedAmt);
    setVatAmt(vatAmt);
    setAdTaxAmt(adTaxAmt);
    setCgstAmt(cgstAmt);
    setSgstAmt(sgstAmt);
    setIgstAmt(igstAmt);
    setCessAmt(cessAmt);
    setNetAmount(netAmt);
    setEditMedicine(medicineData);
    setOpen(true);
  };

  console.log("MedicineUpdateData", medicineUpdateData);

  const handleEditChange = (ele) => {
    setOrderDataUpdatedData({
      ...orderDataUpdatedData,
      [ele.target.name]: ele.target.value,
    });
  };

  const handleMedicineUpdate = (event) => {
    const { name, value } = event.target;
    setEditMedicine((prevEditMedicine) => {
      const updatedMedicine = {
        ...prevEditMedicine,
        [name]: Number(value),
      };

      if (
        [
          "quantity",
          "box",
          "unitRate",
          "amount",
          "discount",
          "vat",
          "adTax",
          "cgst",
          "igst",
          "sgst",
          "cess",
        ].includes(name)
      ) {
        const amount = parseFloat(updatedMedicine.amount || 0);
        const discount = parseFloat(updatedMedicine.discount || 0);
        const vat = parseFloat(updatedMedicine.vat || 0);
        const adTax = parseFloat(updatedMedicine.adTax || 0);
        const cgst = parseFloat(updatedMedicine.cgst || 0);
        const igst = parseFloat(updatedMedicine.igst || 0);
        const sgst = parseFloat(updatedMedicine.sgst || 0);
        const cess = parseFloat(updatedMedicine.cess || 0);

        const totalDiscount = (amount * discount) / 100;
        const discountedAmt = amount - totalDiscount;
        const vatAmt =
          (discountedAmt + adTax + cgst + sgst + igst + cess) * (vat / 100);
        const adTaxAmt =
          (discountedAmt + vat + cgst + sgst + igst + cess) * (adTax / 100);
        const cgstAmt =
          (discountedAmt + vat + adTax + sgst + igst + cess) * (cgst / 100);
        const sgstAmt =
          (discountedAmt + vat + adTax + cgst + igst + cess) * (sgst / 100);
        const igstAmt =
          (discountedAmt + vat + adTax + cgst + sgst + cess) * (igst / 100);
        const cessAmt =
          (discountedAmt + vat + adTax + cgst + sgst + igst) * (cess / 100);

        const netAmount =
          discountedAmt +
          vatAmt +
          adTaxAmt +
          cgstAmt +
          sgstAmt +
          igstAmt +
          cessAmt;

        setDiscountAmt(totalDiscount);
        setVatAmt(vatAmt);
        setAdTaxAmt(adTaxAmt);
        setCgstAmt(cgstAmt);
        setSgstAmt(sgstAmt);
        setIgstAmt(igstAmt);
        setCessAmt(cessAmt);
        setNetAmount(netAmount);

        updatedMedicine.netAmount = netAmount;
      }

      return updatedMedicine;
    });
  };

  const handleUpdateMedicineData = () => {
    if (editMedicine) {
      const updatedMedicines = orderDataUpdatedData.medicines.map((item) =>
        item._id === editMedicine._id ? editMedicine : item
      );

      setOrderDataUpdatedData({
        ...orderDataUpdatedData,
        medicines: updatedMedicines,
      });
      setOpen(false);
    }
  };

  const handleUpdateData = () => {
    setIsLoading(true);
    const updateOrderData = async () => {
      const updateData = await updatePurchaseOrderData(
        orderDataUpdatedData,
        challan,
        poNumber,
        credit,
        id
      );
      if (updateData.success === true) {
        navigate("/purchaseorderlist");
        setIsLoading(false);
      }
    };

    updateOrderData();
  };

  const handleClearButton = () => {
    navigate("/purchaseorderlist");
  };

  const [medicine, setMedicine] = useState([]);

  useEffect(() => {
    const getAvailableMedicine = async () => {
      try {
        const data = await availableMedicine();
        console.log(data);
        setMedicine(data);
      } catch (error) {
        console.error("Error fetching available medicine:", error);
      }
    };

    getAvailableMedicine();
  }, [availableMedicine]);

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box padding={3}>
            <Box marginBottom={2}>
              <Breadcrumbs>
                <Link style={linkstyle} to="/dashboard">
                  Dashboard
                </Link>
                <Link style={linkstyle} to="/medicines">
                  Medicines
                </Link>
                <Link style={linkstyle} to="/purchaseorderlist">
                  Purchase Order
                </Link>
              </Breadcrumbs>
              <Typography sx={typographyContent}>
                {id ? "Edit Purchase Order" : "Purchase Order"}
              </Typography>
            </Box>
            {/* start Input Filed */}
            <Box display={"flex"}>
              <Box>
                <Grid container>
                  <Grid md={5}>
                    <TextField
                      sx={{ width: "100%" }}
                      type="text"
                      placeholder="Invoice Number"
                      variant="outlined"
                      name="invoiceNo"
                      label={id ? "" : "Invoice Number"}
                      onChange={id ? handleEditChange : handleValueChange}
                      value={orderDataUpdatedData.invoiceNo}
                      error={!!validateError.invoiceNo}
                      helperText={validateError.invoiceNo}
                    />
                  </Grid>
                  <Grid md={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: "100%", marginLeft: "85px" }}
                        label="Invoice Date"
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            helperText: validateError.invoiceDate,
                            error: validateError.invoiceDate,
                          },
                        }}
                        value={
                          id
                            ? dayjs(
                                moment(
                                  orderDataUpdatedData?.invoiceDate
                                ).format("YYYY-MM-DD")
                              )
                            : dayjs(moment().format("YYYY-MM-DD"))
                        }
                        onChange={
                          id
                            ? (newDate) =>
                                handleEditChange({
                                  target: {
                                    name: "invoiceDate",
                                    value: newDate
                                      ? newDate.format("YYYY-MM-DD")
                                      : "",
                                  },
                                })
                            : (newDate) =>
                                handleDatePicker("invoiceDate", newDate)
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid md={11.5}>
                    <TextField
                      multiline
                      rows={1}
                      sx={{ width: "100%", marginTop: "20px" }}
                      name="partyName"
                      placeholder="Party Name"
                      variant="outlined"
                      label={id ? "" : "Party Name"}
                      onChange={id ? handleEditChange : handleValueChange}
                      value={orderDataUpdatedData.partyName}
                      error={!!validateError.partyName}
                      helperText={validateError.partyName}
                    />
                  </Grid>
                  <Grid md={11.5}>
                    <TextField
                      multiline
                      rows={1}
                      sx={{ width: "100%", marginTop: "20px" }}
                      name="address"
                      placeholder="Address"
                      variant="outlined"
                      label={id ? "" : "Address"}
                      onChange={id ? handleEditChange : handleValueChange}
                      value={orderDataUpdatedData.address}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box display={"flex"}>
                  <TextField
                    sx={{ width: "200px", marginRight: "10px" }}
                    name="number"
                    onChange={handleChallanChange}
                    label="Challan Number"
                    value={challan.number}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "200px" }}
                      label="Challan Date"
                      format="DD-MM-YYYY"
                      value={challan.date ? dayjs(challan.date) : dayjs(moment().format("YYYY-MM-DD"))}
                      onChange={(newDate) =>
                        handleChallanDatePicker("challanDate", newDate)
                      }
                    />
                  </LocalizationProvider>
                </Box>
                <Box display={"flex"}>
                  <TextField
                    sx={{
                      width: "200px",
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    label="P.O Number"
                    name="number"
                    value={poNumber.number}
                    onChange={handlePOChange}
                  />
                  <Box sx={{ marginTop: "12px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{ width: "200px" }}
                          label="P.O Date"
                          format="DD-MM-YYYY"
                          value={poNumber.date ? dayjs(poNumber.date) : dayjs(moment().format("YYYY-MM-DD"))}
                          onChange={(newDate) =>
                            handlePODatePicker("poDate", newDate)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <TextField
                    sx={{
                      width: "200px",
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    label="Credit Days"
                    name="creditAmount"
                    value={credit.creditAmount}
                    onChange={handlecreditChange}
                  />
                  <Box sx={{ marginTop: "12px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{ width: "200px", marginTop: "20px" }}
                          label="Credit Date"
                          format="DD-MM-YYYY"
                          value={credit.date ? dayjs(credit.date) : dayjs(moment().format("YYYY-MM-DD"))}
                          onChange={(newDate) =>
                            handlecreditDatePicker("credit", newDate)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* End Input Filed */}
            {/* start Add medicine Button */}
            <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
              {id ? null : (
                <Button
                  variant="contained"
                  sx={{ mt: "30px" }}
                  color="success"
                  onClick={handleAddMedicineButton}
                >
                  <AddCircleOutlineIcon sx={{ fontSize: "18px" }} />
                  <Typography sx={patientButton}> Add Medicine</Typography>
                </Button>
              )}
            </Box>
            {/* End Add medicine Button */}
            <Divider
              sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
            />
            {/* table start */}
            <Box marginTop="40px">
              <TableContainer sx={{ maxHeight: 340 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Group Name</TableCell>
                      <TableCell align="center">Item Name</TableCell>
                      <TableCell align="center">Quantity/unit</TableCell>
                      <TableCell align="center">Unit/Rate</TableCell>
                      <TableCell align="center">Discount</TableCell>
                      <TableCell align="center">Box/Pack</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Net Amount</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {id && orderDataUpdatedData.medicines ? (
                      orderDataUpdatedData.medicines.map((item, index) => (
                        <TableRow key={item._id}>
                          <TableCell align="center">{item.groupName}</TableCell>
                          <TableCell align="center">{item.itemName}</TableCell>
                          <TableCell align="center">{item.quantity}</TableCell>
                          <TableCell align="center">{item.unitRate}</TableCell>
                          <TableCell align="center">{item.discount}</TableCell>
                          <TableCell align="center">{item.box}</TableCell>
                          <TableCell align="center">{item.amount}</TableCell>
                          <TableCell align="center">
                            {item.netAmount && item.netAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              disableRipple
                              sx={hoverEffect}
                              onClick={() => handleUpdateMedicine(item._id)}
                            >
                              <EditIcon sx={editButton} />
                            </Button>
                            <Button
                              disableRipple
                              sx={hoverEffect}
                              onClick={() => handleDeleteMedicine(item._id)}
                            >
                              <DeleteIcon sx={deletebutton} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : medicineData ? (
                      medicineData &&
                      medicineData.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{item.groupName}</TableCell>
                          <TableCell align="center">{item.itemName}</TableCell>
                          <TableCell align="center">{item.quantity}</TableCell>
                          <TableCell align="center">{item.unitRate}</TableCell>
                          <TableCell align="center">{item.discount}</TableCell>
                          <TableCell align="center">{item.box}</TableCell>
                          <TableCell align="center">{item.amount}</TableCell>
                          <TableCell align="center">
                            {item.netAmount && item.netAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              disableRipple
                              sx={hoverEffect}
                              onClick={() => handleAddMedicineUpdate(index)}
                            >
                              <EditIcon sx={editButton} />
                            </Button>
                            <Button
                              disableRipple
                              sx={hoverEffect}
                              onClick={() => handleAddMedicineDelete(index)}
                            >
                              <DeleteIcon sx={deletebutton} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {/* table end */}
            <Divider
              sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
            />
            {/* Start Second input filed */}
            <Box marginTop="40px">
              <Box>
                <Grid container>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      placeholder="Item Amount"
                      variant="outlined"
                      label="Item Amount"
                      sx={{ fontWeight: "700" }}
                      value={total.amount}
                    />
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Quantity"
                      variant="outlined"
                      label="Total Quantity"
                      value={total.quantity}
                    />
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Box"
                      variant="outlined"
                      label="Total Box"
                      value={total.box}
                    />
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      placeholder="Total Disc"
                      variant="outlined"
                      label="Total Disc"
                      value={total.discount}
                    />
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      sx={MT}
                      placeholder="Total Tax"
                      variant="outlined"
                      label="Total Tax"
                      value={total.quantity}
                    />
                  </Grid>
                  <Grid md={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={medicineNames}
                      sx={{ mt: "20px", mr: "50px" }}
                      renderInput={(params) => (
                        <TextField {...params} label="CASH A/C" />
                      )}
                    />
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      type="text"
                      sx={MT}
                      placeholder="Net Amount"
                      variant="outlined"
                      label="Net Amount"
                      value={total.netAmount.toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* End Second input filed & Table  */}
            {/*Start save & cancel button  */}
            <Box sx={{ marginTop: "20px" }} display="flex" margin="0px">
              <Button
                sx={saveButton}
                variant="contained"
                onClick={id ? handleUpdateData : handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <> {id ? "Update" : "Save"}</>
                )}
              </Button>
              <Button
                sx={cancelButton}
                onClick={handleClearButton}
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
            {/*End save & cancel button  */}
          </Box>
        </Box>
      </Box>
      {/* Start Dialog add medicine */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <Box display="flex" justifyContent="space-between" sx={dialogHeading}>
          <Typography
            sx={{ fontSize: "18px", paddingLeft: "25px", fontWeight: "700" }}
          >
            Add Medicine Details
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <ClearIcon sx={clearButton} />
          </IconButton>
        </Box>

        <Divider sx={{ height: 1, backgroundColor: "Black" }} />
        <DialogContent>
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={medicinesGroup}
            size="small"
            onChange={handleAutoComplete("groupName")}
            defaultValue={{
              name: editMedicine?.groupName || medicineDetails.groupName,
            }}
            getOptionLabel={(option) => option.name}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} name="group Name" label="Group Name" />
            )}
          /> */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={medicine}
            size="small"
            onChange={handleAutoComplete("itemName")}
            getOptionLabel={(option) => option.itemName}
            sx={{ width: "100%", marginTop: "10px" }}
            defaultValue={{
              itemName: editMedicine?.itemName || medicineDetails.itemName,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Medicine Name"
                label="Medicine Name"
                error={!!dialogValidateError.itemName}
                helperText={dialogValidateError.itemName || ""}
              />
            )}
          />
          <Grid item md={12}>
            <TextField
              fullWidth={true}
              sx={{ mt: "16px" }}
              placeholder="groupName"
              name="groupName"
              type="text"
              label="groupName"
              size="small"
              value={editMedicine?.groupName || medicineDetails.groupName}
              readOnly
            />
          </Grid>
          {/* <TextField
            sx={{ mt: "10px", width: "100%" }}
            type="text"
            label="Note"
            size="small"
            name="note"
            onChange={handleMedicineChange}
          /> */}
          <Box display={"flex"}>
            <Box sx={{ marginTop: "8px", marginRight: "12px" }}>
              <TextField
                sx={MT}
                type="text"
                label="Quantity/UNIT"
                size="small"
                name="quantity"
                value={editMedicine?.quantity || medicineDetails.quantity}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
                error={!!dialogValidateError.quantity}
                helperText={dialogValidateError.quantity}
              />
              <TextField
                sx={MT}
                type="text"
                label="Unit Rate"
                size="small"
                name="unitRate"
                value={editMedicine?.unitRate || medicineDetails.unitRate}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
                error={!!dialogValidateError.unitRate}
                helperText={dialogValidateError.unitRate}
              />
              <TextField
                sx={MT}
                type="text"
                label="Discount %"
                size="small"
                name="discount"
                value={editMedicine?.discount || medicineDetails.discount}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="VAT %"
                name="vat"
                size="small"
                value={editMedicine?.vat || medicineDetails.vat}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="Ad.TAX %"
                size="small"
                name="adTax"
                value={editMedicine?.adTax || medicineDetails.adTax}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="CGST %"
                size="small"
                name="cgst"
                value={editMedicine?.cgst || medicineDetails.cgst}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="SGST %"
                size="small"
                name="sgst"
                value={editMedicine?.sgst || medicineDetails.sgst}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="IGST %"
                size="small"
                name="igst"
                value={editMedicine?.igst || medicineDetails.igst}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="Cess %"
                size="small"
                name="cess"
                value={editMedicine?.cess || medicineDetails.cess}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="Stock Qty. %"
                size="small"
                name="stockQty"
                value={editMedicine?.stockQty || medicineDetails.stockQty}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="P. Rate %"
                size="small"
                name="pRate"
                value={editMedicine?.pRate || medicineDetails.pRate}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
            </Box>
            <Box sx={{ marginTop: "8px" }}>
              <TextField
                sx={MT}
                type="text"
                label="Box/Pack"
                size="small"
                name="box"
                value={editMedicine?.box || medicineDetails.box}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
              <TextField
                sx={MT}
                type="text"
                label="Amount"
                size="small"
                name="amount"
                value={editMedicine?.amount || medicineDetails.amount}
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
                error={!!dialogValidateError.amount}
                helperText={dialogValidateError.amount}
              />
              <TextField
                sx={MT}
                type="text"
                label="Discount Amt."
                size="small"
                value={discountAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="VAT Amt."
                size="small"
                value={vatAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="Ad.TAX Amt."
                size="small"
                value={adTaxAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="CGST Amt."
                size="small"
                value={cgstAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="SGST Amt."
                size="small"
                value={sgstAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="IGST Amt."
                size="small"
                value={igstAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="Cess Amt."
                size="small"
                value={cessAmt.toFixed(2)}
              />
              <TextField
                sx={MT}
                type="text"
                label="Net Amount"
                size="small"
                name="netAmount"
                value={
                  typeof netAmount === "number" ? netAmount.toFixed(2) : ""
                }
              />
              <TextField
                sx={MT}
                type="text"
                label="S.Rate"
                size="small"
                name="sRate"
                onChange={id ? handleMedicineUpdate : handleMedicineChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={saveButton}
            variant="contained"
            onClick={id ? handleUpdateMedicineData : handleDialogData}
          >
            {id ? "Update" : "Save"}
          </Button>
          <Button
            sx={cancelButton}
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialog add medicine */}
      {/* start delete medicine dialog box while update the data*/}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              handleDeleteMedicineData();
              setDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* end delete medicine dialog box while update the data*/}
      {/* start delete medicine dialog box while add new data */}
      <Dialog open={addDeleteOpen} onClose={() => setAddDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setAddDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => {
              addMedicineDelete(deleteMedicineIndex);
              setAddDeleteOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* end delete medicine dialog box while add new data */}
    </>
  );
};

export default PurchaseOrder;
