import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

export const addPurchaseOrderData = async (orderData) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/purchaseorder`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: orderData,
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    if (error.response.data === "Invalid Token") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error("Error:", error);
    }
  }
};

export const updatePurchaseOrderData = async (
  orderDataUpdatedData,
  challan,
  poNumber,
  credit,
  id
) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/purchaseorder/${id}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: {
        ...orderDataUpdatedData,
        challan: challan,
        poNumber: poNumber,
        credit: credit,
      },
    };
    const response = await axios.request(config);
    return response.data
  } catch (error) {
    console.log(error.response.data);
  }
};
