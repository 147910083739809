import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Divider,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ClipLoader } from "react-spinners";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Components
import Header from "../../common/Header/Header";

import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { deleteSaleOrder } from "../../../services/saleOrder/saleOrderListServices";

import axios from "axios";
import baseUrl from "../../.././constants/constant.json";
import { getToken } from "../../../utils/getToken";

// css
const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  padding: "20px",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const listStyle = {
  textTransform: "none",
  textDecoration: "none",
};

const patientButton = {
  color: "white",
  textTransform: "none",
  listStyle: "none",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const dialogHeading = {
  color: "#424242",
  fontSize: "26px",
  fontWeight: "700",
};

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
};

const orderInfo = {
  bgcolor: "#F6F7FA",
  width: "96%",
  border: "none",
  pointerEvents: "none",
  cursor: "default",
};

const orderDetails = {
  bgcolor: "#F6F7FA",
  width: "100%",
  border: "none",
  pointerEvents: "none",
  cursor: "default",
};

function SaleOrderList(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [saleToDelete, setSaleToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saleOrderList, setSaleOrderList] = useState([]);
  const [singlePatientData, setSinglePatientData] = useState([]);

  // sale order list
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder/list`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.data);
        setSaleOrderList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          navigate("/");
        } else {
          console.error("Error:", error);
        }
      });
  }, []);

  const handleDeleteData = async (saleListId) => {
    try {
      await deleteSaleOrder(saleListId);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteOpen = (saleListId) => {
    setSaleToDelete(saleListId);
    setDeleteOpen(true);
  };

  const viewAllData = (orderId) => {
    const selectedOrder = saleOrderList.find((item) => item._id === orderId);

    if (selectedOrder) {
      setSinglePatientData(selectedOrder);
      setOpen(true);
    }
  };

  console.log(singlePatientData);

  return (
    <>
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box marginBottom={2}>
            <Breadcrumbs>
              <Link style={linkstyle} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linkstyle} to="/medicines">
                Medicines
              </Link>
              <Link style={linkstyle} to="/saleorderlist">
                Sale Order
              </Link>
            </Breadcrumbs>
            <Typography sx={typographyContent}>Sale Order</Typography>
          </Box>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid md={4}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search by Name"
                    inputProps={{ "aria-label": "Search by Name" }}
                  />
                </Search>
              </Grid>
              <Grid md={6}>
                <Box display="flex" justifyContent="end">
                  <Link to="/saleorder" style={listStyle}>
                    <Button variant="contained" color="success">
                      <AddCircleOutlineIcon
                        sx={{ fontSize: "16px", mr: "4px" }}
                      />
                      <Typography sx={patientButton}>Add Sale Order</Typography>
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box paddingTop={3}>
            <TableContainer sx={{ maxHeight: 480 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Party Name</TableCell>
                    <TableCell align="center">Order Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    height="330px"
                    top="35%"
                    left="50%"
                  >
                    <ClipLoader color="#2F7539" />
                  </Box>
                ) : saleOrderList.length === 0 ? (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Typography variant="body1" align="center">
                            No data available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    {saleOrderList.map((item, key) => {
                      return (
                        <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">
                              {item.partyName}
                            </TableCell>
                            <TableCell align="center">
                              {moment(item.invoiceDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {item.totalAmount}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <Button
                                disableRipple
                                sx={hoverEffect}
                                onClick={() => viewAllData(item._id)}
                              >
                                <VisibilityIcon />
                              </Button>
                              <Link
                                to={`/saleorder/${item._id}`}
                                style={listStyle}
                              >
                                <Button disableRipple sx={hoverEffect}>
                                  <EditIcon sx={editButton} />
                                </Button>
                              </Link>
                              <Button
                                onClick={() => handleDeleteOpen(item._id)}
                                disableRipple
                                sx={hoverEffect}
                              >
                                <DeleteIcon sx={deleteButton} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>

      {/* view all data */}
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography sx={dialogHeading}>Sale Order Info</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ marginBottom: "10px" }} />
        <DialogContent sx={{ pt: 2 }}>
          <Box>
            <Typography sx={{ fontWeight: "600" }}>Personal Details</Typography>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Grid container>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Invoice Number</Typography>

                  <TextField
                    size="small"
                    sx={orderInfo}
                    value={singlePatientData.invoiceNo}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Invoice Date</Typography>
                  <TextField
                    size="small"
                    sx={orderInfo}
                    value={moment(singlePatientData.invoiceDate).format(
                      "DD-MM-YYYY"
                    )}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={{ pb: 1 }}>Mobile Number</Typography>
                  <TextField
                    size="small"
                    sx={orderDetails}
                    value={singlePatientData.mobileNumber}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pt={2}>
              <Grid container>
                <Grid md={5}>
                  <Typography sx={{ pb: 1 }}>Party Name</Typography>
                  <TextField
                    size="small"
                    sx={orderInfo}
                    value={singlePatientData.partyName}
                  />
                </Grid>
                <Grid md={7}>
                  <Typography sx={{ pb: 1 }}>Address</Typography>
                  <TextField
                    size="small"
                    sx={orderDetails}
                    value={singlePatientData.address}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginY: "20px" }} />
            <Box>
              <Grid container>
                <Grid md={4}>
                  <Typography sx={{ fontWeight: "600" }}>
                    Challan Details
                  </Typography>
                  <Grid>
                    <Typography variant="subtitle1">
                      Challan Number: {singlePatientData.challan?.number}
                    </Typography>
                    <Typography variant="subtitle1">
                      Challan Date:{" "}
                      {moment(singlePatientData.challan?.date).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid md={4}>
                  <Typography sx={{ fontWeight: "600" }}>
                    P.O. Details
                  </Typography>
                  <Grid>
                    <Typography variant="subtitle1">
                      P.O. Number: {singlePatientData.poNumber?.number}
                    </Typography>
                    <Typography variant="subtitle1">
                      P.O. Date:{" "}
                      {moment(singlePatientData.poNumber?.date).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid md={4}>
                  <Typography sx={{ fontWeight: "600" }}>
                    Credit Details
                  </Typography>
                  <Grid>
                    <Typography variant="subtitle1">
                      Credit Number: {singlePatientData.credit?.creditAmount}
                    </Typography>
                    <Typography variant="subtitle1">
                      Credit Date:{" "}
                      {moment(singlePatientData.credit?.date).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginY: "20px" }} />
            <Typography sx={{ fontWeight: "600" }}>
              Medicines Details
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Medicine Name</TableCell>
                    <TableCell align="center">Medicine Group</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Box/Pack</TableCell>
                    <TableCell align="center">Discount</TableCell>
                    <TableCell align="center">Unit</TableCell>
                    <TableCell align="center">Net Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singlePatientData.medicines?.map((item) => (
                    <TableRow
                      key={item.itemName}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{item.itemName}</TableCell>
                      <TableCell align="center">{item.groupName}</TableCell>
                      <TableCell align="center">{item.amount}</TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">{item.box}</TableCell>
                      <TableCell align="center">{item.discount}</TableCell>
                      <TableCell align="center">{item.unitRate}</TableCell>
                      <TableCell align="center">
                        {item.netAmount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "16px" }}>
          <Button
            variant="outlined"
            style={CancelDialog}
            onClick={() => setDeleteOpen(false)}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            disableElevation
            style={deleteDialog}
            onClick={() => handleDeleteData(saleToDelete)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaleOrderList;
