import {
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  ListItemText,
  Drawer,
} from "@mui/material";
import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import MedicationIcon from "@mui/icons-material/Medication";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link, useLocation } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { makeStyles } from "@mui/styles";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

const report = {
  width: "60px",
  height: "40px",
  backgroundColor: "#FFF",
  borderRadius: "3px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const typographyContent = {
  color: "#424242",
  fontSize: "14px",
  fontWeight: "700",
  marginTop: "2px",
};

const typographyReport = {
  color: "#757575",
  fontSize: "12px",
  fontWeight: "500",
};

const bgColorBottom = {
  backgroundColor: "#30373E",
  height: "100%",
};

const drawerIcons = {
  color: "none",
};

const listTypography = {
  fontSize: "16px",
  fontWeight: "700",
};

const listItem = {
  display: "flex",
  justifyContent: "center",
  color: "#969DA2",
};

const listUnderline = {
  textDecoration: "none",
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  const handleClick = () => {
    setNavOpen(!navOpen);
  };

  const drawerStyles = makeStyles(() => ({
    customDrawer: {
      "&::-webkit-scrollbar": {
        width: "8px",
      },
    },
  }));
  const classes = drawerStyles();

  const user = localStorage.getItem("user");

  return (
    <Box position="fixed">
      <Box sx={{ p: "20px" }}>
        <Box>
          <Box p="5px" display="flex" justifyContent="center">
            <img
              alt="Logo"
              width="150px"
              height="120px"
              src={require("../../../assets/13.png")}
            ></img>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <AccountCircleIcon
                sx={{ width: "72px", height: "72px", color: "white" }}
              />
            </Box>
            <Box marginLeft="5px">
              <Typography fontSize="12px" color="#FFF" fontWeight="700">
                Welcome
              </Typography>
              <Typography fontSize="16px" color="#FFF" fontWeight="700">
                Vaidya Chandrakant
              </Typography>
            </Box>
          </Box>
          <Stack marginTop={2} direction="row" spacing={2}>
            <Box sx={report}>
              <Typography sx={typographyContent}>10</Typography>
              <Typography sx={typographyReport}>Patient</Typography>
            </Box>
            <Box sx={report}>
              <Typography sx={typographyContent}>08</Typography>
              <Typography sx={typographyReport}>Pending</Typography>
            </Box>
            <Box sx={report}>
              <Typography sx={typographyContent}>02</Typography>
              <Typography sx={typographyReport}>Visit</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={bgColorBottom}>
        <Drawer
          classes={{
            paper: classes.customDrawer,
          }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "absolute",
              top: "auto",
              backgroundColor: "transparent",
              height: "500px",
              overflowY: "auto",
              width: "100%",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {user === "admin" ? (
            <List sx={{ paddingTop: 5, mb: 5 }}>
              <Link
                to="/patientlist"
                underline="none"
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  style={{
                    color: pathname === "/patientlist" ? "green" : "#969DA2",
                  }}
                >
                  <ListItemIcon sx={listItem}>
                    <VaccinesIcon
                      sx={drawerIcons}
                      style={{
                        color: pathname === "/patientlist" ? "green" : "#969DA2",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={listTypography}>Patient</Typography>
                </ListItem>
              </Link>
            </List>
          ) : (
            <List sx={{ paddingTop: 5, mb: 5 }}>
              <Link to="/dashboard" style={listUnderline}>
                <ListItem
                  style={{
                    color: pathname === "/dashboard" ? "green" : "#969DA2",
                  }}
                >
                  <ListItemIcon sx={listItem}>
                    <DashboardIcon
                      sx={drawerIcons}
                      style={{
                        color: pathname === "/dashboard" ? "green" : "#969DA2",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={listTypography}>Dashboard</Typography>
                </ListItem>
              </Link>
              <Link
                to="/patientlist"
                underline="none"
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  style={{
                    color: pathname === "/patientlist" ? "green" : "#969DA2",
                  }}
                >
                  <ListItemIcon sx={listItem}>
                    <VaccinesIcon
                      sx={drawerIcons}
                      style={{
                        color: pathname === "/patientlist" ? "green" : "#969DA2",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={listTypography}>Patient</Typography>
                </ListItem>
              </Link>
              <Link
                to="/diagnosislist"
                underline="none"
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  style={{
                    color: pathname === "/diagnosislist" ? "green" : "#969DA2",
                  }}
                >
                  <ListItemIcon sx={listItem}>
                    <MonitorHeartIcon
                      sx={drawerIcons}
                      style={{
                        color:
                          pathname === "/diagnosislist" ? "green" : "#969DA2",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={listTypography}>Diagnosis</Typography>
                </ListItem>
              </Link>
              <List sx={{ display: "flex" }}>
                <Link to="/medicines" style={listUnderline} button>
                  <ListItem
                    style={{
                      color: pathname === "/medicines" ? "green" : "#969DA2",
                    }}
                  >
                    <ListItemIcon sx={listItem}>
                      <MedicationIcon
                        sx={drawerIcons}
                        style={{
                          color:
                            pathname === "/medicines" ? "green" : "#969DA2",
                        }}
                      />
                    </ListItemIcon>
                    <Typography sx={listTypography}>Medicines</Typography>
                  </ListItem>
                </Link>
                <ListItem onClick={handleClick}>
                  {navOpen ? (
                    <ExpandLessIcon
                      sx={{ ml: "30px", color: "#969DA2", cursor: "pointer" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{ ml: "30px", color: "#969DA2", cursor: "pointer" }}
                    />
                  )}
                </ListItem>
              </List>
              <Collapse sx={{ color: "#969DA2" }} in={navOpen}>
                <Link to="/stockreport" style={listUnderline}>
                  <List component="div" disablePadding>
                    <ListItem
                      style={{
                        color:
                          pathname === "/stockreport" ? "green" : "#969DA2",
                      }}
                      sx={{ pl: 6, pt: "0", color: "#969DA2" }}
                    >
                      <ArrowRightIcon color="#969DA2" />
                      <ListItemText primary="Stock Report" />
                    </ListItem>
                  </List>
                </Link>
                <Link to="/purchaseorderlist" style={listUnderline}>
                  <List component="div" disablePadding>
                    <ListItem
                      style={{
                        color:
                          pathname === "/purchaseorderlist"
                            ? "green"
                            : "#969DA2",
                      }}
                      sx={{ pl: 6, pt: "0", color: "#969DA2" }}
                    >
                      <ArrowRightIcon color="#969DA2" />
                      <ListItemText primary="Purchase Order" />
                    </ListItem>
                  </List>
                </Link>
                <Link to="/saleorderlist" style={listUnderline}>
                  <List component="div" disablePadding>
                    <ListItem
                      style={{
                        color:
                          pathname === "/saleorderlist" ? "green" : "#969DA2",
                      }}
                      sx={{ pl: 6, pt: "0", color: "#969DA2" }}
                    >
                      <ArrowRightIcon color="#969DA2" />
                      <ListItemText primary="Sale Order" />
                    </ListItem>
                  </List>
                </Link>
                <Link to="/marginreport" style={listUnderline}>
                  <List component="div" disablePadding>
                    <ListItem
                      style={{
                        color:
                          pathname === "/marginreport" ? "green" : "#969DA2",
                      }}
                      sx={{ pl: 6, pt: "0", color: "#969DA2" }}
                    >
                      <ArrowRightIcon color="#969DA2" />
                      <ListItemText primary="Margin Report" />
                    </ListItem>
                  </List>
                </Link>
              </Collapse>
              <Link to="/appointment" style={listUnderline}>
                <ListItem
                  style={{
                    color: pathname === "/appointment" ? "green" : "#969DA2",
                  }}
                >
                  <ListItemIcon sx={listItem}>
                    <CalendarMonthIcon
                      sx={drawerIcons}
                      style={{
                        color:
                          pathname === "/appointment" ? "green" : "#969DA2",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={listTypography}>Appointment</Typography>
                </ListItem>
              </Link>
            </List>
          )}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
