import avoidLessMoreData from "./json/avoidLessMore.json";
import avoidMore from "./json/avoidMore.json";

const filterData = (prakrutiType) => {
  return avoidLessMoreData.filter((item) => {
    return item.type.toLowerCase() === prakrutiType.toLowerCase();
  });
};

const getPrakrutiData = (checkedPrakriti) => {

  let result = [];

  switch (checkedPrakriti[checkedPrakriti.length - 1]) {
    case "vatta":
      const vataPlusData = filterData("vattaPlus");
      const vataMinusData = filterData("vattaMinus");
      result = [...vataPlusData, ...vataMinusData];
      break;
    case "pitta":
      const pittaPlusData = filterData("pittaPlus");
      const pittaMinusData = filterData("pittaMinus");
      result = [...pittaPlusData, ...pittaMinusData];
      break;
    case "kapha":
      const kaphaPlusData = filterData("kaphaPlus");
      const kaphaMinusData = filterData("kaphaMinus");
      result = [...kaphaPlusData, ...kaphaMinusData];
      break;
    default:
      break;
  }
  return result;
};

const getDietData = () => {
  // const data = JSON.parse(avoidMore);
  // console.log(data)
  const data = avoidMore.map(item => {
    return {
      name: item.name,
      eName: item.eName,
      eProperty: Object.keys(item.property).filter(item => item.startsWith("e"))
        .map(value => [...item.property[value].split(",")])
        .flat()
        .map(item => item.toLowerCase().trim()).filter(item => item !== ""),
      property: Object.keys(item.property).filter(item => !item.startsWith("e"))
        .map(value => [...item.property[value].split(",")])
        .flat()
        .map(item => item.toLowerCase().trim()).filter(item => item !== "")
    }
  });
  const groupedData = data.reduce((acc, item) => {
    item.property.forEach((prop, index) => {
      // console.log(prop);
      const existingIndex = acc.findIndex(el => el.property === prop && el.eProperty === item.eProperty[index]);
      if (existingIndex !== -1) {
        acc[existingIndex].values.push({ name: item.name, eName: item.eName, default: true });
      } else {
        acc.push({
          property: prop,
          eProperty: item.eProperty[index],
          values: [{ name: item.name, eName: item.eName, default: true }]
        });
      }
    });
    return acc;
  }, []).sort((a, b) => {
    if (a.eProperty < b.eProperty) {
      return -1;
    }
    if (a.eProperty > b.eProperty) {
      return 1;
    }
    return 0;
  });
  // console.log(groupedData)
  return groupedData;
}

export { getPrakrutiData, getDietData };
