import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// delete diagnosis
export const deleteDiagnosisList = async (diagnosisId) => {
  console.log("diagnosisId", diagnosisId);
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl.url}/api/diagnosis/${diagnosisId}`,
    headers: {
      token: getToken(),
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log(response);
    window.location.reload();
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get diagnosis list
export const fetchDiagnosisList = async (searchByName, startDate, endDate) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/diagnosis/list?name=${searchByName}&startDate=${startDate}&endDate=${endDate}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.data === "Invalid Token") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};
