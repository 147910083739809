import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../../utils/getToken";

function PrivateRoutes() {

  return <div>{getToken() ? <Outlet /> : <Navigate to={"/"} />}</div>;
}

export default PrivateRoutes;
