import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ClipLoader } from "react-spinners";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintProvider, { Print, NoPrint } from "react-easy-print";

// Component
import Header from "../common/Header/Header";
import { DateRangePicker } from "rsuite";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Prescription from "./Prescription";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import {
  deleteDiagnosisList,
  fetchDiagnosisList,
} from "../../services/diagnosis/diagnosisListService";

// axios url
import baseUrl from "../../constants/constant.json";
import axios from "axios";
import { getToken } from "../../utils/getToken";

// css
const mainHeading = {
  color: "#424242",
  fontSize: "30px",
  fontWeight: "700",
  marginBottom: "10px",
};

const listStyle = {
  textDecoration: "none",
  textTransform: "none",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const deleteDialog = {
  backgroundColor: "#D11A2A",
  color: "#FFFFFF",
  padding: "6px 15px",
};

const CancelDialog = {
  border: "1px solid #2F7539",
  color: "#2F7539",
  textTransform: "none",
};

const PrintButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  margin: "10px",
  width: "150px",
  backgroundColor: "#2F7539",
};

const deleteButton = {
  backgroundColor: "#D11A2A",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const editButton = {
  backgroundColor: "green",
  color: "white",
  padding: "2px",
  borderRadius: "5px",
};

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const hoverEffect = {
  "&:hover": {
    backgroundColor: "transparent",
  },
  padding: "0px",
  minWidth: "35px",
};

const linkstyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  margin: "10px",
  width: "150px",
  border: "1px solid #2F7539",
  color: "#2F7539",
};

const datePicker = {
  width: "250px",
  marginLeft: "10px",
};

function DiagnosisList() {

  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [DiagnosisDataByID, setDiagnosisDataById] = useState([]);
  const [searchByName, setSearchByName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [avoidEatNames, setAvoidEatNames] = useState("");
  const [moreEatNames, setMoreEatNames] = useState("");
  const [medicineDataList, setMedicinesDataList] = useState([]);

  useEffect(() => {
    // Your data extraction code here
    const avoidEatArray = DiagnosisDataByID.avoidEat;
    const moreEatArray = DiagnosisDataByID.moreEat;

    if (Array.isArray(avoidEatArray)) {
      const filteredNames = avoidEatArray
        .filter((name) => name);

      setAvoidEatNames(filteredNames.join(", "));
    } else {
      console.log("No data in avoidEat");
    }

    if (Array.isArray(moreEatArray)) {
      const filteredNames = moreEatArray
        .filter((name) => name);

      setMoreEatNames(filteredNames.join(", "));
    } else {
      console.log("No data in avoidEat");
    }
  }, [DiagnosisDataByID]);

  const handleNameInputChange = (event) => {
    setSearchByName(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [diagnosisData, setDiagnosisData] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/diagnosis/list?name=${searchByName}&startDate=${startDate}&endDate=${endDate}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setDiagnosisData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
        setIsLoading(false);
      });
  }, [searchByName, startDate, endDate]);
  console.log("diagnosisData", diagnosisData);

  const handleOpenDialog = (DiagnosisId) => {
    const singleData = diagnosisData.find((item) => item._id === DiagnosisId);
    if (singleData) {
      setDiagnosisDataById(singleData);
      setMedicinesDataList(singleData.medicine);
      setOpen(true);
    }
  };

  const [diagnosisId, setDiagnosisId] = useState("");
  const handleDeleteDialog = (DiagnosisId) => {
    setOpenDeleteDialog(true);
    setDiagnosisId(DiagnosisId);
  };
  const handleDeleteData = async () => {
    const deleteDiagnosis = await deleteDiagnosisList(diagnosisId);
    console.log(deleteDiagnosis);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: getDocumentTitle(DiagnosisDataByID),
  });

  function getDocumentTitle(data) {
    if (data && data.patientId) {
      return `${data.patientId.firstName}-${data.patientId.lastName}-${moment(
        data.createdAt
      ).format("DD-MM-YYYY")}`;
    }
    return "DefaultFileName"; // Set a default file name if data is missing
  }

  // search by date range Picker
  const handleDateRangeChange = (value) => {
    if (value && value.length === 2) {
      setStartDate(value[0].toISOString().split("T")[0]);
      setEndDate(value[1].toISOString().split("T")[0]);
    } else {
      // Handle the case when the date range is cleared
      setStartDate("");
      setEndDate("");
    }
  };

  const handleWhatsappMessage = (number, name) => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${Number(
      "91" + number
    )}&text=Hello ${name}, Here is your prescription of your visit to Swasthya Mandir`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <PrintProvider>
        <NoPrint>
          <Box>
            <Header />
            <Box sx={dashboard}>
              <>
                <Box padding="20px">
                  <Box>
                    <Box>
                      <Breadcrumbs>
                        <Link style={linkstyle} to="/dashboard">
                          Dashboard
                        </Link>
                        <Link style={linkstyle} to="/diagnosislist">
                          Diagnosis
                        </Link>
                      </Breadcrumbs>
                      <Typography sx={mainHeading}>Diagnosis</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex">
                        <Search>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search by Name"
                            inputProps={{ "aria-label": "Search by Name" }}
                            value={searchByName}
                            onChange={handleNameInputChange}
                          />
                        </Search>
                        <Search>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search by Mobile No"
                            inputProps={{
                              "aria-label": "Search by Mobile Number ",
                            }}
                          />
                        </Search>
                        <Box sx={datePicker}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              sx={{ paddingTop: "0px" }}
                              components={["DateRangePicker"]}
                            >
                              <DateRangePicker
                                size="lg"
                                placeholder="Select Date Range"
                                onChange={handleDateRangeChange}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ height: 1, bgcolor: "black", m: "20px" }} />
                  <Box marginTop="40px">
                    <TableContainer sx={{ maxHeight: 350 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Mobile No</TableCell>
                            <TableCell align="center">Create</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {isLoading ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="absolute"
                            height="480px"
                            top="35%"
                            left="50%"
                          >
                            <ClipLoader color="#2F7539" />
                          </Box>
                        ) : (
                          <TableBody>
                            {diagnosisData.map((item) => {
                              return (
                                <>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {item.patientId?.firstName || ""}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <Typography
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {item.patientId?.mobileNumber || ""}
                                      </Typography>
                                      <IconButton>
                                        <WhatsApp
                                          element="button"
                                          onClick={() =>
                                            handleWhatsappMessage(
                                              item.patientId?.mobileNumber || "", 
                                              `${item.patientId.firstName} ${item.patientId.lastName}` || "User"
                                            )
                                          }
                                          color="success"
                                          sx={{ marginLeft: "8px" }}
                                        ></WhatsApp>
                                      </IconButton>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {moment(item.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <Button
                                        disableRipple
                                        sx={hoverEffect}
                                        onClick={() =>
                                          handleOpenDialog(item._id)
                                        }
                                      >
                                        <ReceiptIcon />
                                      </Button>
                                      <Link
                                        to={{
                                          pathname: "/diagnosis",
                                          search: `?diagnosisId=${item._id}`,
                                        }}
                                        style={listStyle}
                                      >
                                        <Button disableRipple sx={hoverEffect}>
                                          <EditIcon sx={editButton} />
                                        </Button>
                                      </Link>
                                      <Button
                                        disableRipple
                                        sx={hoverEffect}
                                        onClick={() =>
                                          handleDeleteDialog(item._id)
                                        }
                                      >
                                        <DeleteIcon sx={deleteButton} />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            </Box>
          </Box>
          {/* Medical Prescription dialog box Start*/}
          <Dialog
            open={open}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <Print single name="foo">
              <DialogTitle id="alert-dialog-title"></DialogTitle>

              <DialogContent>
                <Prescription
                  DiagnosisDataByID={DiagnosisDataByID}
                  capitalizeFirstLetter={capitalizeFirstLetter}
                  medicineDataList={medicineDataList}
                  moreEatNames={moreEatNames}
                  avoidEatNames={avoidEatNames}
                  ref={componentRef}
                />
              </DialogContent>
            </Print>
            <DialogActions>
              <Button
                sx={PrintButton}
                variant="contained"
                onClick={handlePrint}
              >
                Print
              </Button>
              <Button
                sx={cancelButton}
                variant="outlined"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* Medical Prescription dialog box End*/}
          {/* delete dialog start*/}
          <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
          >
            <DialogTitle>{"Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete ?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: "16px" }}>
              <Button
                variant="outlined"
                style={CancelDialog}
                autoFocus
                onClick={() => setOpenDeleteDialog(false)}
              >
                CANCEL
              </Button>
              <Button
                disableElevation
                style={deleteDialog}
                onClick={handleDeleteData}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          {/* delete dialog end*/}
        </NoPrint>
      </PrintProvider>
    </>
  );
}

export default DiagnosisList;
