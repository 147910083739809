import React from "react";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

const Prescription = React.forwardRef(
  (
    {
      DiagnosisDataByID,
      capitalizeFirstLetter,
      medicineDataList,
      moreEatNames,
      avoidEatNames,
    },
    ref
  ) => {
    const dialogHeaderImage = {
      height: "100px",
    };

    const dialogHeading = {
      color: "#424242",
      fontSize: "24px",
      fontWeight: "700",
    };;

    return (
      <>
        <Box ref={ref} sx={{ margin: "20px" }} documentTitle={"prescription"}>
          <Box>
            <Box alignItems="center" textAlign="center">
              <img
                style={dialogHeaderImage}
                alt="logo"
                src={require("../../assets/Logo/Sri_Sri.png")}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                Swasthya Mandir
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={dialogHeading}>Medical Prescription</Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ height: 2, bgcolor: "#2f7539", marginY: "4px" }} />
          <Box>
            <Typography fontSize="20px" fontWeight="500" align="center">
              Patient Details
            </Typography>
            <Box mt={1}>
              <TableContainer>
                <Table size="small">
                  <TableRow>
                    {DiagnosisDataByID.createdAt === "" ? null : (
                      <>
                        <TableCell
                          colSpan={2}
                          align="right"
                          sx={{ borderBottom: "none" }}
                        >
                          <Typography>
                            Check Up Date :
                            {moment(DiagnosisDataByID.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                          </Typography>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    {DiagnosisDataByID.followUpDate === null ? null : (
                      <>
                        <TableCell
                          colSpan={2}
                          align="right"
                          sx={{ borderBottom: "none" }}
                        >
                          <Typography>
                            Follow Up Date :&nbsp;
                            {moment(DiagnosisDataByID.followUpDate).format(
                              "DD-MM-YYYY"
                            )}{" "}
                          </Typography>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="right"
                      sx={{ borderBottom: "none" }}
                    >
                      {DiagnosisDataByID.followUpDate && (
                        <Typography>
                          Follow Up Days :{" "}
                          {moment(DiagnosisDataByID.followUpDate).diff(
                            DiagnosisDataByID.createdAt,
                            "days"
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>

              <Box display={"flex"}>
                <Box width={"50%"}>
                  <Typography display="flex">
                    <div style={{ marginRight: "4px", fontWeight: "500" }}>
                      Patient Name :
                    </div>
                    {DiagnosisDataByID && DiagnosisDataByID.patientId
                      ? `${capitalizeFirstLetter(
                          DiagnosisDataByID.patientId.firstName
                        )} ${capitalizeFirstLetter(
                          DiagnosisDataByID.patientId.lastName
                        )}`
                      : "Loading..."}
                  </Typography>
                  <Typography display="flex">
                    <div style={{ marginRight: "4px", fontWeight: "500" }}>
                      Patient Number :
                    </div>
                    {DiagnosisDataByID && DiagnosisDataByID.patientId
                      ? `${DiagnosisDataByID.patientId.mobileNumber} `
                      : "Loading..."}
                  </Typography>
                </Box>
                <Box width={"50%"}>
                  <Typography display="flex">
                    {DiagnosisDataByID.patientId.ageYear === "" ? null : (
                      <>
                        <div style={{ marginRight: "4px", fontWeight: "500" }}>
                          Age :
                        </div>
                        {DiagnosisDataByID && DiagnosisDataByID.patientId
                          ? `${DiagnosisDataByID.patientId.ageYear} `
                          : "Loading..."}
                      </>
                    )}
                  </Typography>
                  <Typography display="flex">
                    {DiagnosisDataByID.patientId.gender === "" ? null : (
                      <>
                        <div style={{ marginRight: "4px", fontWeight: "500" }}>
                          Gender :
                        </div>
                        {DiagnosisDataByID && DiagnosisDataByID.patientId
                          ? `${DiagnosisDataByID.patientId.gender} `
                          : "Loading..."}
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Typography display="flex">
                {DiagnosisDataByID.prakriti.length === 0 ? null : (
                  <>
                    <div style={{ marginRight: "4px", fontWeight: "500" }}>
                      Prakruti :
                    </div>
                    {DiagnosisDataByID && DiagnosisDataByID.patientId
                      ? `${DiagnosisDataByID.prakriti} `
                      : "Loading..."}
                  </>
                )}
              </Typography>
              <Typography display="flex">
                {DiagnosisDataByID &&
                  DiagnosisDataByID.vikriti &&
                  Object.keys(DiagnosisDataByID.vikriti).length !== 0 && (
                    <>
                      <div style={{ marginRight: "4px", fontWeight: "500" }}>
                        Vikruti:
                      </div>
                      <div>
                        {Object.entries(DiagnosisDataByID.vikriti).map(
                          ([key, values]) => (
                            <div key={key}>
                              {values.length > 0 && (
                                <>
                                  <div>{`${key}: ${values.join(", ")}`}</div>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
              </Typography>

              <Typography display="flex">
                {DiagnosisDataByID.diagnosis === "" ? null : (
                  <>
                    <div style={{ marginRight: "4px", fontWeight: "500" }}>
                      Diagnosis :
                    </div>
                    <div>
                      {DiagnosisDataByID && DiagnosisDataByID.patientId
                        ? `${DiagnosisDataByID.diagnosis} `
                        : "Loading..."}
                    </div>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            {medicineDataList.length > 0 ? (
              <>
                <Typography fontSize="20px" fontWeight="500" marginY={1}>
                  Medicine Details
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Medicine Name</TableCell>
                        <TableCell>Dosage</TableCell>
                        <TableCell>Anupan</TableCell>
                        <TableCell>Adminstration Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {medicineDataList?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.medicineName}</TableCell>
                          <TableCell> {item.dosage}</TableCell>
                          <TableCell> {item.anupan}</TableCell>
                          <TableCell> {item?.administrationTime}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : null}
          </Box>
          <Box>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "50%", padding: "8px" }}>
                      <Typography variant="h5" marginBottom={1}>
                        Avoid Eat
                      </Typography>
                      {avoidEatNames}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "50%", padding: "8px" }}>
                      <Typography variant="h5" marginBottom={1}>
                        More Eat
                      </Typography>
                      {moreEatNames}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            {DiagnosisDataByID.panchakarma.length > 0 ||
            DiagnosisDataByID.asana.length > 0 ||
            DiagnosisDataByID.pranayama.length > 0 ||
            DiagnosisDataByID.mudra.length > 0 ||
            DiagnosisDataByID.upkarma.length > 0 ? (
              <>
                <Typography fontSize="20px" fontWeight="500" marginY={1}>
                  Therapy
                </Typography>
                <TableContainer>
                  <Table>
                    {DiagnosisDataByID.meditation.length > 0 && (
                      <TableRow>
                        <TableCell>Meditation:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.meditation.map(
                            (meditation, index) => (
                              <Link
                                target={"_blank"}
                                to={meditation.youtubeVideoLink}
                                key={index}
                              >
                                {meditation.name}
                                {index <
                                  DiagnosisDataByID.meditation.length - 1 && (
                                  <span>, </span>
                                )}
                              </Link>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {DiagnosisDataByID.panchakarma.length > 0 && (
                      <TableRow>
                        <TableCell>Panchakarma:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.panchakarma.map(
                            (panchakarma, index) => (
                              <Link
                                target={"_blank"}
                                to={panchakarma.youtubeVideoLink}
                                key={index}
                              >
                                {panchakarma.name}
                                {index <
                                  DiagnosisDataByID.panchakarma.length - 1 && (
                                  <span>, </span>
                                )}
                              </Link>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    {DiagnosisDataByID.asana.length > 0 && (
                      <TableRow>
                        <TableCell>Asana:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.asana.map((asana, index) => (
                            <Link
                              target={"_blank"}
                              to={asana.youtubeVideoLink}
                              key={index}
                            >
                              {asana.name}
                              {index < DiagnosisDataByID.asana.length - 1 && (
                                <span>, </span>
                              )}
                            </Link>
                          ))}
                        </TableCell>
                      </TableRow>
                    )}

                    {DiagnosisDataByID.pranayama.length > 0 && (
                      <TableRow>
                        <TableCell>Pranayama:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.pranayama.map(
                            (pranayama, index) => (
                              <Link
                                target={"_blank"}
                                to={pranayama.youtubeVideoLink}
                                key={index}
                              >
                                {pranayama.name}
                                {index <
                                  DiagnosisDataByID.pranayama.length - 1 && (
                                  <span>, </span>
                                )}
                              </Link>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    {DiagnosisDataByID.mudra.length > 0 && (
                      <TableRow>
                        <TableCell>Mudra:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.mudra.map((mudra, index) => (
                            <Link
                              target={"_blank"}
                              to={mudra.youtubeVideoLink}
                              key={index}
                            >
                              {mudra.name}
                              {index < DiagnosisDataByID.mudra.length - 1 && (
                                <span>, </span>
                              )}
                            </Link>
                          ))}
                        </TableCell>
                      </TableRow>
                    )}

                    {DiagnosisDataByID.upkarma.length > 0 && (
                      <TableRow>
                        <TableCell>Upkarma:</TableCell>
                        <TableCell>
                          {DiagnosisDataByID.upkarma.map((upkarma, index) => (
                            <Link
                              target={"_blank"}
                              to={upkarma.youtubeVideoLink}
                              key={index}
                            >
                              {upkarma.name}
                              {index < DiagnosisDataByID.upkarma.length - 1 && (
                                <span>, </span>
                              )}
                            </Link>
                          ))}
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </>
            ) : null}
          </Box>
        </Box>
      </>
    );
  }
);

export default Prescription;
