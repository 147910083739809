import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem, 
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FormControlLabel
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { getDietData } from "../../utils/utils";

import { matchSorter } from 'match-sorter';

const AvoidMore = ({
  typoStyle,
  minusData,
  setMinusData,
  plusData,
  setPlusData,
  selectedDiagnosisData,
  tooltipStyle,
  diagnosisId,
  handleDeleteMoreEat,
  handleDeleteAvoidEat,
  newMinusItem,
  handleNewMinusItemChange,
  handleAddNewMinusItem,
  editButton,
  handleClearTextFiled,
  newPlusItem,
  handleNewPlusItemChange,
  handleAddNewPlusItem,
  deleteButton,
  setSelectedDiagnosisData,
  handleSnackbar,
  finalMoreDietData,
  setFinalMoreDietData,
  finalLessDietData,
  setFinalLessDietData,
  selectedMoreDietData,
  setSelectedMoreDietData,
  selectedLessDietData,
  setSelectedLessDietData,
}) => {
  const handleUpdateDataDelete = (arrayName, index) => {
    const arrayToUpdate = [...selectedDiagnosisData[arrayName]];

    arrayToUpdate.splice(index, 1);

    const updatedSelectedDiagnosisData = {
      ...selectedDiagnosisData,
      [arrayName]: arrayToUpdate,
    };

    setSelectedDiagnosisData(updatedSelectedDiagnosisData);
  };

  const [newAvoidEat, setNewAvoidEat] = useState({
    name: "",
    type: "",
    eName: ""
  });
  const [newMoreEat, setNewMoreEat] = useState({
    name: "",
    type: "",
    eName: ""
  });
  const [selectedValueMore, setSelectedValueMore] = useState(null);
  const [selectedValueLess, setSelectedValueLess] = useState(null);
  const [dietData, setDietData] = useState(getDietData());
  const handleAutocompleteChangeMore = (event, newValue) => {
    console.log(newValue)
    setSelectedValueMore(newValue);
    setSelectedMoreDietData([...newValue]);
    setMinusData([...minusData, ...newValue[0].values.map(item => item.name)])
    console.log(selectedMoreDietData);
  };

  const handleAutocompleteChangeLess = (event, newValue) => {
    console.log(newValue)
    setSelectedValueLess(newValue);
    setSelectedLessDietData([...newValue]);
    setPlusData([...plusData, ...newValue[0].values.map(item => item.name)])
    console.log(selectedLessDietData);
  };

  const updateHandleAutocompleteChangeMore = (event, newValues) => {    
    setSelectedValueMore(newValues);
    setSelectedMoreDietData([...selectedMoreDietData, ...newValues]);
  };

  const updateHandleAutocompleteChangeLess = (event, newValues) => {    
    setSelectedValueLess(newValues);
    setSelectedLessDietData([...selectedLessDietData, ...newValues]);
  };
  // const filterOptionsDiet = createFilterOptions({
  //   matchFrom: "start",
  //   stringify: (inputValue) => inputValue.eProperty || "string"
  // });

  const filterOptionsDiet = (options, { inputValue }) => matchSorter(options, inputValue, {keys: ["eProperty"]});

  const handleAddMoreEatItemChange = (event) => {
    const newName = event.target.value;
    console.log(newName);
    setNewMoreEat({
      name: newName,
      eName: newName,
      type: "newType",
    });
  };

  const handleAddAvoidEatItemChange = (event) => {
    const newName = event.target.value;
    setNewAvoidEat((prevNewItem) => ({
      ...prevNewItem,
      name: newName,
      eName: newName,
      type: "newType",
    }));
  };

  const handleAddNewMoreEatItem = () => {
    if (newMoreEat.name === "") {
      handleSnackbar("Please enter an item name !");
    } else {
      setSelectedDiagnosisData({
        ...selectedDiagnosisData,
        moreEat: [...selectedDiagnosisData.moreEat, newMoreEat],
      });
      setNewMoreEat((prevNewItem) => ({
        ...prevNewItem,
        name: "",
        eName: "",
        type: "",
      }));

      // setFinalMoreDietData([...finalMoreDietData, { name: newMoreEat.name, eName: newMoreEat.name}])
    }
  };
 
  const handleAddNewAvoidEatItem = () => {
    if (newAvoidEat.name === "") {
      handleSnackbar("Please enter an item name !");
    } else {
      setSelectedDiagnosisData({
        ...selectedDiagnosisData,
        avoidEat: [...selectedDiagnosisData.avoidEat, newAvoidEat],
      });
      setNewAvoidEat((prevNewItem) => ({
        ...prevNewItem,
        name: "",
        type: "",
      }));
    }
  };

  const handleClearAvoidMoreTextFiled = () => {
    console.log("This called")
    setNewMoreEat((prevNewItem) => ({
      ...prevNewItem,
      name: "",
      type: "",
    }));
    setNewAvoidEat((prevNewItem) => ({
      ...prevNewItem,
      name: "",
      typr: "",
    }));
  };

  // const handleCheckboxClickUpdate = (item) => {
  //   // const isSelected = checkSymptomsOptions(item);
  //   // const updatedSymptoms = selectedDiagnosisData.symptoms.map((symptom) => {
  //   //   if (symptom.title === currentItem.title) {
  //   //     symptom.options = isSelected
  //   //       ? symptom.options.filter(
  //   //           (selectedItem) => selectedItem.title !== item.title
  //   //         )
  //   //       : [...symptom.options, { title: item.title }];
  //   //   }
  //   //   return symptom;
  //   // });

  //   setFinalMoreDietData({
  //     ...finalMoreDietData, item
  //   });
  // };

  // Symptoms Checkbox item selected
  const handleCheckboxClickUpdateMore = (item) => {
    let isSelected;
    if(finalMoreDietData.length> 0){
      isSelected = finalMoreDietData.some(
        (selectedItem) => selectedItem.name === item.name
      );
    }

    if (isSelected) {
      setFinalMoreDietData(
        [...finalMoreDietData.filter(
          (selectedItem) => selectedItem.name !== item.name
        )
      ]
      );
    } else {
      setFinalMoreDietData([...finalMoreDietData, item]);
    }
  };

  const handleCheckboxClickUpdateLess = (item) => {
    let isSelected;
    if(finalLessDietData.length> 0){
      isSelected = finalLessDietData.some(
        (selectedItem) => selectedItem.name === item.name
      );
    }

    if (isSelected) {
      setFinalLessDietData(
        [...finalLessDietData.filter(
          (selectedItem) => selectedItem.name !== item.name
        )
      ]
      );
    } else {
      setFinalLessDietData([...finalLessDietData, item]);
    }
  };

  return (
    <Box mx={2} sx={{ marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="flex-start" padding={1}>
        {/* More to Eat */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            More to Eat
          </Typography>
        </Grid>
        <Grid
          item
          md={9}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flexStart",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Grid md={12}>
                    <Autocomplete
                      disablePortal
                      multiple
                      id="tags-outlined"
                      options={dietData}
                      filterOptions={filterOptionsDiet}
                      getOptionLabel={(item) => item.property}
                      defaultValue={
                        selectedValueMore
                          ? [
                            dietData
                            .find(
                              (option) => option.property === selectedValueMore
                            ),
                            ]
                          : []
                      }
                      onChange={
                        selectedDiagnosisData.symptoms !== undefined
                          ? updateHandleAutocompleteChangeMore
                          : handleAutocompleteChangeMore
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          label="Diet Type"
                          placeholder="Diet Type"
                        />
                      )}
                    />
                  </Grid>

          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {selectedMoreDietData.map((property, idx) => (
              property.values.map((item, index) => (
              <Box key={item.name+index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      defaultChecked={true}
                      checked={finalMoreDietData.some(checked => checked.name === item.name)}
                      onChange={() =>
                        handleCheckboxClickUpdateMore(
                          item
                        )
                      }
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" 
                  // onClick={() => handleDeleteAsans(item._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              ))
            ))}
            {/* <Box >
              <TextField
                size="small"
                value={newPlusItem.name}
                onChange={(e) => handleAddMoreEatItemChange(e)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} 
                onClick={handleAddNewMoreEatItem}
                 />
              </IconButton>
              
            </Box> */}
          </Grid>
          {/* {diagnosisId ? (
            selectedDiagnosisData.moreEat &&
              selectedDiagnosisData.moreEat.length > 0 ? (
              selectedDiagnosisData.moreEat.map((item, index) => (
                <Grid
                  key={index}
                  md={4}
                  display={"contents"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <List key={item.id}>
                    <ListItem className="hover-item">
                      <Tooltip
                        placement="right"
                        style={tooltipStyle}
                        title={Object.entries(item.property).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          )
                        )}
                        arrow
                      >
                        <span>
                          {item.name}
                          <IconButton
                            sx={{ marginLeft: "5px" }}
                            className="delete-button"
                            disableFocusRipple
                            disableRipple={true}
                            size="small"
                            onClick={() =>
                              handleUpdateDataDelete("moreEat", index)
                            }
                          >
                            <DeleteIcon sx={deleteButton} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </ListItem>
                  </List>
                </Grid>
              ))
            ) : (
              <p>No moreEat data available</p>
            )
          ) : (
            minusData.map((item, index) => (
              <Grid
                key={index}
                md={4}
                display={"contents"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <List key={item.id}>
                  <ListItem className="hover-item">
                    <Tooltip
                      placement="right"
                      style={tooltipStyle}
                      title={Object.entries(item.property).map(
                        ([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value}
                          </div>
                        )
                      )}
                      arrow
                    >
                      <span>
                        {item.name}
                        <IconButton
                          sx={{ marginLeft: "5px" }}
                          className="delete-button"
                          disableFocusRipple
                          disableRipple={true}
                          size="small"
                          onClick={() => handleDeleteMoreEat(index)}
                        >
                          <DeleteIcon sx={deleteButton} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItem>
                </List>
              </Grid>
            ))
          )} */}
          {/* <TextField
                size="small"
                value={diagnosisId ? newMoreEat.name : newMinusItem.values[0].name}
                onChange={
                  diagnosisId
                    ? handleAddMoreEatItemChange
                    : handleNewMinusItemChange
                }
              />
              <IconButton
                sx={{ marginLeft: "5px" }}
                size="small"
                onClick={
                  diagnosisId ? handleAddNewMoreEatItem : handleAddNewMinusItem
                }
              >
                <CheckIcon sx={editButton} />
              </IconButton>
              <IconButton
                sx={{ marginLeft: "5px" }}
                size="small"
                onClick={
                  diagnosisId
                    ? handleClearAvoidMoreTextFiled
                    : handleClearTextFiled
                }
              >
                <CloseIcon sx={deleteButton} />
              </IconButton> */}
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container spacing={2} alignItems="flex-start" padding={1}>
        {/* Avoid to Eat */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Avoid to Eat
          </Typography>
        </Grid>
        <Grid
          item
          md={9}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flexStart",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Grid md={12}>
                    <Autocomplete
                      disablePortal
                      multiple
                      id="tags-outlined"
                      options={dietData}
                      filterOptions={filterOptionsDiet}
                      getOptionLabel={(item) => item.property}
                      defaultValue={
                        selectedValueLess
                          ? [
                            dietData
                            .find(
                              (option) => option.property === selectedValueLess
                            ),
                            ]
                          : []
                      }
                      onChange={
                        selectedDiagnosisData.symptoms !== undefined
                          ? updateHandleAutocompleteChangeLess
                          : handleAutocompleteChangeLess
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          label="Diet Type"
                          placeholder="Diet Type"
                        />
                      )}
                    />
                  </Grid>

          <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", alignItems: "center", flexWrap: "wrap" }}>
            {selectedLessDietData.map((property, idx) => (
              property.values.map((item, index) => (
              <Box key={item.name+index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      defaultChecked={true}
                      checked={finalLessDietData.some(checked => checked.name === item.name)}
                      onChange={() =>
                        handleCheckboxClickUpdateLess(
                          item
                        )
                      }
                    />
                  }
                  label={item.name}
                />
                {!item.default && (
                  <IconButton size="small" 
                  // onClick={() => handleDeleteAsans(item._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              ))
            ))}
            {/* <Box >
              <TextField
                size="small"
                value={newPlusItem.name}
                onChange={(e) => handleAddMoreEatItemChange(e)}
              />
              <IconButton sx={{ marginLeft: "5px" }} size="small">
                <CheckIcon sx={editButton} 
                onClick={handleAddNewMoreEatItem}
                 />
              </IconButton>
              
            </Box> */}
          </Grid>
          {/* {diagnosisId ? (
            selectedDiagnosisData.moreEat &&
              selectedDiagnosisData.moreEat.length > 0 ? (
              selectedDiagnosisData.moreEat.map((item, index) => (
                <Grid
                  key={index}
                  md={4}
                  display={"contents"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <List key={item.id}>
                    <ListItem className="hover-item">
                      <Tooltip
                        placement="right"
                        style={tooltipStyle}
                        title={Object.entries(item.property).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          )
                        )}
                        arrow
                      >
                        <span>
                          {item.name}
                          <IconButton
                            sx={{ marginLeft: "5px" }}
                            className="delete-button"
                            disableFocusRipple
                            disableRipple={true}
                            size="small"
                            onClick={() =>
                              handleUpdateDataDelete("moreEat", index)
                            }
                          >
                            <DeleteIcon sx={deleteButton} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </ListItem>
                  </List>
                </Grid>
              ))
            ) : (
              <p>No moreEat data available</p>
            )
          ) : (
            minusData.map((item, index) => (
              <Grid
                key={index}
                md={4}
                display={"contents"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <List key={item.id}>
                  <ListItem className="hover-item">
                    <Tooltip
                      placement="right"
                      style={tooltipStyle}
                      title={Object.entries(item.property).map(
                        ([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value}
                          </div>
                        )
                      )}
                      arrow
                    >
                      <span>
                        {item.name}
                        <IconButton
                          sx={{ marginLeft: "5px" }}
                          className="delete-button"
                          disableFocusRipple
                          disableRipple={true}
                          size="small"
                          onClick={() => handleDeleteMoreEat(index)}
                        >
                          <DeleteIcon sx={deleteButton} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItem>
                </List>
              </Grid>
            ))
          )} */}
          {/* <TextField
                size="small"
                value={diagnosisId ? newMoreEat.name : newMinusItem.values[0].name}
                onChange={
                  diagnosisId
                    ? handleAddMoreEatItemChange
                    : handleNewMinusItemChange
                }
              />
              <IconButton
                sx={{ marginLeft: "5px" }}
                size="small"
                onClick={
                  diagnosisId ? handleAddNewMoreEatItem : handleAddNewMinusItem
                }
              >
                <CheckIcon sx={editButton} />
              </IconButton>
              <IconButton
                sx={{ marginLeft: "5px" }}
                size="small"
                onClick={
                  diagnosisId
                    ? handleClearAvoidMoreTextFiled
                    : handleClearTextFiled
                }
              >
                <CloseIcon sx={deleteButton} />
              </IconButton> */}
        </Grid>
      </Grid>
      <Divider></Divider>
  

      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust as needed
        onClose={handleSnackbarClose}
        // anchorOrigin={{
        //   vertical: "center",
        //   horizontal: "center",
        // }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={handleSnackbarClose}
        >
          Please enter an item name !
        </MuiAlert>
      </Snackbar> */}
    </Box>
  );
};

export default AvoidMore;
