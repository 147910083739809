import axios from "axios";
import baseUrl from "../../constants/constant.json";
import { getToken } from "../../utils/getToken";

// sale order save data
export const saveSaleOrder = async (saleMedicine, medicineList) => {
  try {
    saleMedicine.medicines = medicineList;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: saleMedicine,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// single sale order details
export const fetchSaleOrderDetails = async (editSaleId) => {
  try {
    if (editSaleId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/saleorder/${editSaleId}`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const response = await axios.request(config);

      return {
        selectedMedicineData: response.data.data,
        challan: {
          number: response.data.data.challan?.number,
          date: response.data.data.challan?.date,
        },
        poDetails: {
          number: response.data.data.poNumber?.number,
          date: response.data.data.poNumber?.date,
        },
        creditNumber: {
          creditAmount: response.data.data.credit?.creditAmount,
          date: response.data.data.credit?.date,
        },
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// edit sale order update data
export const updateSaleOrderData = async (
  editSaleId,
  selectedMedicineData,
  challan,
  poNumber,
  credit
) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder/${editSaleId}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
      data: {
        ...selectedMedicineData,
        challan: challan,
        poNumber: poNumber,
        credit: credit,
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const availableMedicine = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/saleorder/availablemedicine`,
      headers: {
        token: getToken(),
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const availableMedicineList = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.url}/api/medicine/list`,
      headers: {
        token: getToken(),
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
  }
};