import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "rsuite";
import { ClipLoader } from "react-spinners";

// Component
import Header from "../../common/Header/Header";

// Json
import baseUrl from "../../../constants/constant.json";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { getToken } from "../../../utils/getToken";

const dashboard = {
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  height: "90vh",
  width: "81%",
  borderRadius: "3px",
};

const typographyContent = {
  display: "flex",
  color: "#424242",
  lineHeight: "normal",
  fontSize: "36px",
  fontWeight: "700",
};

const datePicker = {
  width: "250px",
};

const Search = styled("div")(({ theme }) => ({
  border: "1px solid #F5F5F5",
  "&:hover": {
    border: "1px solid #3498FF",
  },
  position: "relative",
  height: "40px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  backgroundColor: "#FFFFFF",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const linkStyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const StockReport = () => {
  const [stockReportData, setStockReportData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchByName, setSearchByName] = useState("");

  const allStockReport = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        baseUrl.url
      }/api/stock?medicineName=${searchByName}&id=${""}&sdate=${startDate}&edate=${endDate}`,
      headers: {
        token: getToken(),
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setStockReportData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data === "Invalid Token") {
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
      });
  };

  useEffect(() => {
    allStockReport();
  }, [searchByName, startDate, endDate]);

  const handleSearchInput = (event) => {
    setSearchByName(event.target.value);
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      const [start, end] = dateRange.map((date) =>
        moment(date).format("MM/DD/YYYY")
      );
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <Box>
      <Header />
      <Box sx={dashboard}>
        <Box padding={3}>
          <Box marginBottom={2}>
            <Breadcrumbs>
              <Link style={linkStyle} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linkStyle} to="/medicines">
                Medicines
              </Link>
              <Link style={linkStyle} to="/stockreport">
                Stock Report
              </Link>
            </Breadcrumbs>
            <Typography sx={typographyContent}>Stock Report</Typography>
          </Box>
          <Box mt={3}>
            <Grid container>
              <Grid item md={4} mr={2}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search by Name"
                    inputProps={{ "aria-label": "Search by Name" }}
                    value={searchByName}
                    onChange={handleSearchInput}
                  />
                </Search>
              </Grid>

              <Grid item md={3}>
                <Box sx={datePicker}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{ paddingTop: "0px" }}
                      components={["DateRangePicker"]}
                    >
                      <DateRangePicker
                        size="lg"
                        placeholder="Select Date Range"
                        onChange={handleDateChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
          />
          <Box mt="30px" mb={5}>
            <TableContainer sx={{ maxHeight: 380 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Item Name</TableCell>
                    <TableCell align="center">Operation Stock</TableCell>
                    <TableCell align="center">P Quantity</TableCell>
                    <TableCell align="center">S Quantity</TableCell>
                    <TableCell align="center">Balance</TableCell>
                    <TableCell align="center">Rate</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    height="330px"
                    top="35%"
                    left="50%"
                  >
                    <ClipLoader color="#2F7539" />
                  </Box>
                ) : stockReportData.length === 0 ? (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Typography variant="body1" align="center">
                            No data available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    {stockReportData?.map((item) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{item.itemName}</TableCell>
                          <TableCell align="center">{item.opStock}</TableCell>
                          <TableCell align="center">{item.pQuantity}</TableCell>
                          <TableCell align="center">{item.sQuantity}</TableCell>
                          <TableCell align="center">{item.balance}</TableCell>
                          <TableCell align="center">{item.Rate}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StockReport;
