import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

function RogaParikshaPage({
  rogaParikshaOpen,
  dialogHeading,
  setRogaParikshaOpen,
  clearButton,
  rogaParikshaData,
  selectedValues,
  rogaParikshaRadioChange,
  selectExcercis,
  saveButton,
  cancelButton,
}) {
  return (
    <>
      <Dialog
        open={rogaParikshaOpen}
        maxWidth="xl"
        fullWidth={true}
        scroll={"paper"}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={dialogHeading}>Roga Pariksha</Typography>
            <IconButton
              onClick={() => {
                setRogaParikshaOpen(false);
              }}
            >
              <ClearIcon sx={clearButton} />
            </IconButton>
          </Box>
          <Divider
            sx={{ height: 1, backgroundColor: "Black", marginY: "10px" }}
          />
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "18px", fontWeight: "500" }}>
                    No.
                  </TableCell>
                  <TableCell
                    colSpan={5}
                    sx={{ fontSize: "18px", fontWeight: "700" }}
                  >
                    પ્રશ્ન/Questions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rogaParikshaData.map((ele) => (
                  <TableRow key={ele.id}>
                    <TableCell sx={{ fontSize: "18px", fontWeight: "500" }}>
                      {ele.id}
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px", fontWeight: "500" }}>
                      {ele.questions}
                    </TableCell>
                    <TableCell>
                      {ele.type.map((type) => {
                        if (type === "timepicker") {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem>
                                <TimePicker
                                  sx={{ ml: 2, width: "50%" }}
                                  label="Select Time"
                                />
                              </DemoItem>
                            </LocalizationProvider>
                          );
                        } else if (type === "radio") {
                          return (
                            <FormControl key={`radio_${ele.id}`}>
                              <RadioGroup
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                                name={`radio_${ele.id}`}
                                value={selectedValues[ele.id]?.radio || ""}
                                onChange={(event) =>
                                  rogaParikshaRadioChange(event, ele)
                                }
                              >
                                {ele.options1.map((check, index) => (
                                  <FormControlLabel
                                    key={index}
                                    sx={{ ml: 2 }}
                                    value={check}
                                    control={<Radio />}
                                    label={check}
                                    name={`radio_${ele.id}`}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          );
                        } else if (type === "select") {
                          return (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={selectExcercis}
                              sx={{ width: "50%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Excercis..."
                                />
                              )}
                            />
                          );
                        } else if (type === "checkbox") {
                          return (
                            <FormControl
                              sx={{
                                ml: 2,
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {ele.options1 !== null &&
                              Array.isArray(ele.options1)
                                ? ele.options1.map((check, index) => (
                                    <FormControlLabel
                                      key={index}
                                      sx={{ ml: 2 }}
                                      control={<Checkbox />}
                                      label={check}
                                    />
                                  ))
                                : ele.options2 !== null &&
                                  Array.isArray(ele.options2)
                                ? ele.options2.map((check, index) => (
                                    <FormControlLabel
                                      key={index}
                                      sx={{ ml: 2 }}
                                      control={<Checkbox />}
                                      label={check}
                                    />
                                  ))
                                : null}
                            </FormControl>
                          );
                        } else if (type === "text") {
                          return (
                            <TextField sx={{ width: "100%" }} size="small" />
                          );
                        } else if (type === "special1") {
                          return ele.options1.map((special) => {
                            if (special.type === "checkbox") {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Dhatu
                                      </div>
                                    </TableCell>
                                    <TableCell sx={{ display: "flex" }}>
                                      {special.dhatu.map((dhatu, index) => (
                                        <FormControl
                                          sx={{
                                            ml: 2,
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <FormControlLabel
                                            key={index}
                                            sx={{ ml: 2 }}
                                            control={<Checkbox />}
                                            label={dhatu}
                                          />
                                        </FormControl>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Upadhatu
                                      </div>
                                    </TableCell>
                                    <TableCell sx={{ display: "flex" }}>
                                      {special.updhatu.map((updhatu, index) => (
                                        <FormControl
                                          sx={{
                                            ml: 2,
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <FormControlLabel
                                            key={index}
                                            sx={{ ml: 2 }}
                                            control={<Checkbox />}
                                            label={updhatu}
                                          />
                                        </FormControl>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Mala
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {special.mala.map((mala, index) => (
                                        <FormControl
                                          sx={{
                                            ml: 2,
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <FormControlLabel
                                            key={index}
                                            sx={{ ml: 2 }}
                                            control={<Checkbox />}
                                            label={mala}
                                          />
                                        </FormControl>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                            return null;
                          });
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box display="flex" margin="0px">
            <Button sx={saveButton} variant="contained">
              Save
            </Button>
            <Button
              onClick={() => setRogaParikshaOpen(false)}
              sx={cancelButton}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RogaParikshaPage;
