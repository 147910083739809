import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { availableMedicineList } from "../../../services/saleOrder/saleOrderServices";
import { useEffect, useState } from "react";

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
};

function SaleMedicines({
  open,
  setOpen,
  handleEditDialogChange,
  discountAmt,
  vatAmt,
  adTax,
  cgstAmt,
  sgstAmt,
  igstAmt,
  cessAmt,
  netAmt,
  saveButton,
  editSaleId,
  handleEditClick,
  handleSaveClick,
  cancelButton,
  selectedMedicineDetails,
  setSelectedMedicineDetails,
  selectedMedicinesChange,
  editedMedicine,
  editedMedicineCalculations,
  calculateMedicineValues,
}) {
  const [medicine, setMedicine] = useState([]);

  useEffect(() => {
    const getAvailableMedicine = async () => {
      try {
        const data = await availableMedicineList();
        console.log(data);
        setMedicine(data);
      } catch (error) {
        console.error("Error fetching available medicine:", error);
      }
    };

    getAvailableMedicine();
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => setOpen(false)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ p: "10px", alignItems: "center" }}
      >
        <Typography
          sx={{ fontSize: "18px", paddingLeft: "25px", fontWeight: "700" }}
        >
          {editSaleId
            ? "Edit Sale Medicine Details"
            : "Add Sale Medicine Details"}
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <ClearIcon sx={clearButton} />
        </IconButton>
      </Box>
      <Divider sx={{ marginY: "2px", color: "black" }} />
      <DialogContent sx={{ p: "10px" }}>
        <Box p={2}>
          <Grid container>
            <Grid item md={12}>
              {editSaleId && editedMedicine?.itemName ? (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="Medicine Name"
                  name="itemName"
                  type="text"
                  label="Medicine Name"
                  value={editedMedicine?.itemName}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              ) : (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={medicine}
                  size="small"
                  getOptionLabel={(option) => option.itemName}
                  onChange={(event, newValue) => {
                    setSelectedMedicineDetails(newValue);
                    calculateMedicineValues(selectedMedicineDetails);
                  }}
                  defaultValue={{
                    itemName:
                      editedMedicine?.itemName ||
                      selectedMedicineDetails.itemName ||
                      "",
                  }}
                  sx={{ width: "100%", marginTop: "10px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="itemName"
                      label="Medicine Name"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth={true}
                sx={{ mt: "16px" }}
                placeholder="groupName"
                name="groupName"
                type="text"
                label="groupName"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  editedMedicine?.groupName ||
                  selectedMedicineDetails?.groupName
                }
                readOnly
              />
            </Grid>
          </Grid>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Quantity"
                name="quantity"
                type="text"
                label="Quantity"
                size="small"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("quantity", e.target.value);
                  }
                }}
                value={
                  editedMedicine?.quantity || selectedMedicineDetails?.quantity
                }
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                placeholder="Box / Pack"
                label="Box / Pack"
                name="box"
                size="small"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("box", e.target.value);
                  }
                }}
                value={editedMedicine?.box || selectedMedicineDetails?.box}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Unit Rate"
                label="Unit Rate"
                type="text"
                name="unitRate"
                size="small"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("unitRate", e.target.value);
                  }
                }}
                value={
                  editedMedicine?.unitRate || selectedMedicineDetails?.unitRate
                }
              />
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Amount"
                name="amount"
                label="Amount"
                size="small"
                type="text"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("amount", e.target.value);
                  }
                }}
                value={
                  editedMedicine?.amount || selectedMedicineDetails?.amount
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Discount %"
                label="Discount %"
                size="small"
                type="text"
                name="discount"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("discount", e.target.value);
                  }
                }}
                value={
                  editedMedicine?.discount || selectedMedicineDetails?.discount
                }
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="Discount Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(
                        editedMedicineCalculations.discountedAmt
                      ).toFixed(2)
                    : parseFloat(discountAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="VAT %"
                label="VAT %"
                size="small"
                name="vat"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("vat", e.target.value);
                  }
                }}
                value={editedMedicine?.vat || selectedMedicineDetails?.vat}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="VAT Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.vatAmt).toFixed(2)
                    : parseFloat(vatAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Ad. Tax %"
                label="Ad. Tax %"
                size="small"
                name="adTax"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("adTax", e.target.value);
                  }
                }}
                value={editedMedicine?.adTax || selectedMedicineDetails?.adTax}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="Ad.TAX Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.adTaxAmt).toFixed(2)
                    : parseFloat(adTax).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="CGST %"
                label="CGST %"
                size="small"
                name="cgst"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("cgst", e.target.value);
                  }
                }}
                value={editedMedicine?.cgst || selectedMedicineDetails?.cgst}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="CGST Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.cgstAmt).toFixed(2)
                    : parseFloat(cgstAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="SGST %"
                label="SGST %"
                size="small"
                name="sgst"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("sgst", e.target.value);
                  }
                }}
                value={editedMedicine?.sgst || selectedMedicineDetails?.sgst}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="SGST Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.sgstAmt).toFixed(2)
                    : parseFloat(sgstAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="IGST %"
                label="IGST %"
                size="small"
                name="igst"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("igst", e.target.value);
                  }
                }}
                value={editedMedicine?.igst || selectedMedicineDetails?.igst}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="IGST Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.igstAmt).toFixed(2)
                    : parseFloat(igstAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Cess %"
                label="Cess %"
                size="small"
                name="cess"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("cess", e.target.value);
                  }
                }}
                value={editedMedicine?.cess || selectedMedicineDetails?.cess}
              />
              <TextField
                sx={{ mt: "16px" }}
                type="text"
                label="Cess Amt."
                size="small"
                value={
                  editSaleId && editedMedicine?.itemName
                    ? parseFloat(editedMedicineCalculations.cessAmt).toFixed(2)
                    : parseFloat(cessAmt).toFixed(2)
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="Stock Qty."
                type="text"
                label="Stock Qty."
                size="small"
                name="stockQty"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("stockQty", e.target.value);
                  }
                }}
                value={
                  editedMedicine?.stockQty || selectedMedicineDetails?.stockQty
                }
              />
              <TextField
                sx={{ mt: "16px" }}
                label="Net Amount"
                size="small"
                name="netAmount"
                value={typeof netAmt === "number" ? netAmt.toFixed(2) : ""}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                sx={{ mt: "16px" }}
                placeholder="P. Rate"
                label="P. Rate"
                type="text"
                size="small"
                name="pRate"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("pRate", e.target.value);
                  }
                }}
                value={editedMedicine?.pRate || selectedMedicineDetails?.pRate}
              />
              <TextField
                sx={{ mt: "16px" }}
                placeholder="S. Rate"
                type="text"
                label="S. Rate"
                size="small"
                name="sRate"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (editedMedicine) {
                    handleEditDialogChange(e);
                  } else {
                    selectedMedicinesChange("sRate", e.target.value);
                  }
                }}
                value={editedMedicine?.sRate || selectedMedicineDetails?.sRate}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Stack pt={2} direction="row">
          <Button
            sx={saveButton}
            variant="contained"
            onClick={editSaleId ? handleEditClick : handleSaveClick}
          >
            {editSaleId ? "Update" : "Save"}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            sx={cancelButton}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default SaleMedicines;
